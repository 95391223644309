import React, { useState, useEffect, useMemo } from "react";

import { Footer, SearchHeader, ToggleSwitchRaw } from "components";
import { useParams } from "react-router-dom";
import { get } from "helpers/api";
import { Loader, Button } from "semantic-ui-react";
import { buttonStyle, inputStyle, colors } from "helpers/theme";
import usePagination from "helpers/loadPaginated";
import { useMediaQuery } from "react-responsive";
import { useSearchModule } from "components/UniSearch/searchEffects";
import Dropdown from "components/UniSearch/SearchDropdown";
import VizSensor from "react-visibility-sensor";
import TutorCard from "./TutorCard";
import useFavorites from "helpers/useFavorites";
import { connect } from "react-redux";
import { ReactComponent as Kurse } from "assets/icons8-kurse-100-2.svg";
import log from "helpers/tracking";

import CourseCard from "./CourseCard";

const FilterDropdown = ({
  standalone,
  searchFunModules,
  setModuleSearch,
  setModule,
  module,
  modules,
  loadingModules,
}) => {
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <Dropdown
      search={searchFunModules}
      className="moduleFilter"
      setSearch={setModuleSearch}
      setValue={setModule}
      value={module}
      options={modules}
      loading={loadingModules > 0}
      placeholder={
        <>
          {" "}
          <Kurse
            style={{
              width: "20px",
              height: "20px",
              marginTop: -4,
              fill: "#9fa5b0",
              marginRight: 20,
            }}
          />
          Fach filtern
        </>
      }
      compact
      fluid={false}
      style={{
        ...inputStyle,
        minWidth: "200px",
        flex: 0,
        marginRight: isTablet ? 50 : 0,
        padding: 5,
        paddingLeft: 30,
        paddingRight: 30,
        textAlign: "center",
        borderRadius: 100,
        whiteSpace: "nowrap",
        borderColor: !!module ? colors.accent : "#cacacf",
        color: !!module ? colors.accent : colors.text,
      }}
    />
  );
};

const SearchResultsScreen = ({ user }) => {
  const [availableCourses, setCourses] = useState([]);
  useEffect(() => {
    get("crashcourse")
      .then((crashcourses) => {
        setCourses(crashcourses);
      })
      .catch(() => {});
  }, []);

  const { uni, course: course_ } = useParams();
  const course = uni + "~" + course_;
  const [activeOnly, setActiveOnly] = useState(false);

  const [uniName, setUniName] = useState("...");
  const [courseName, setCourseName] = useState("...");
  useEffect(() => {
    get("universities/$1/courses/$2", [uni, course])
      .v(2)
      .then(({ university: { fullName }, name }) => {
        setUniName(fullName);
        setCourseName(name);
      });
  }, [uni, course]);

  const pageSize = 20;
  const {
    values: tutors,
    loadMore,
    loading: loadingTutors,
    allLoaded: allTutorsLoaded,
  } = usePagination(
    pageSize,
    (page) =>
      new Promise((res) =>
        get("users/tutors")
          .auth(user.auth)
          .query({
            teachesCourse: course,
            modules: 1,
            limit: pageSize,
            offset: page * pageSize,
          })
          .then(({ tutors }) => {
            log(user.uuid, "search", {
              page,
              uni,
              course,
              module,
              tutors: tutors.length,
              activeTutors: tutors.filter(({ active }) => active).length,
            });
            res(tutors);
          })
      ),
    [uni, course]
  );

  const {
    modules,
    setModuleSearch,
    module,
    setModule,
    loadingModules,
    searchFunModules,
  } = useSearchModule(uni, course, { canClear: true, clearLabel: "Alle" });
  const { text: moduleName } =
    modules.filter((m) => m.value === module)[0] || {};

  const filteredTutors = useMemo(
    () =>
      tutors &&
      tutors.filter(
        ({ active, modules }) =>
          (!activeOnly || active) &&
          (!module ||
            modules.filter(({ moduleId }) => moduleId === module).length > 0)
      ),
    [tutors, activeOnly, module]
  );

  useEffect(() => {
    if (filteredTutors && module) {
      log(user.uuid, "search", {
        uni,
        course,
        module,
        tutors: filteredTutors.length,
        activeTutors: filteredTutors.filter(({ active }) => active).length,
      });
    }
  }, [filteredTutors, uni, course, module, user.uuid]);

  const [extraTutors, setExtraTutors] = useState([]);
  useEffect(() => {
    if (allTutorsLoaded && module) {
      get("users/tutors")
        .auth(user.auth)
        .query({
          teachesModule: module,
          modules: 1,
        })
        .then(({ tutors }) => {
          setExtraTutors(
            tutors
              .map(({ modules, ...rest }) => ({
                ...rest,
                modules: modules.filter(
                  ({ courseId, moduleId }) =>
                    courseId !== course && moduleId === module
                ),
              }))
              .filter(({ modules }) => modules.length > 0)
              .filter(
                ({ id }) => !filteredTutors.find(({ id: id1 }) => id1 === id)
              )
          );
        });
    } else {
      setExtraTutors([]);
    }
  }, [allTutorsLoaded, module, course, uni, user, filteredTutors]);

  useEffect(() => {
    setExtraTutors([]);
  }, [uni, course]);

  useEffect(() => {
    if (uniName) {
      if (tutors && tutors.length >= 2) {
        document.title =
          tutors.length +
          " Nachhilfe-Tutoren für " +
          courseName +
          " | " +
          uniName;
      } else {
        document.title =
          "Nachhilfe-Tutoren für " + courseName + " | " + uniName;
      }
    }
  }, [uniName, courseName, tutors]);

  const courseCards = availableCourses.filter(
    ({ courses, modules }) =>
      courses.indexOf(course) !== -1 &&
      (!module || modules.indexOf(module) !== -1)
  );

  const setFavorite = useFavorites(user);

  const count = tutors && filteredTutors.length;
  const is1600 = useMediaQuery({ query: "(min-width: 1600px)" });
  const is1400 = useMediaQuery({ query: "(min-width: 1400px)" });
  const isWide = useMediaQuery({ query: "(min-width: 1250px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  if (count < pageSize && !allTutorsLoaded) {
    loadMore();
  }

  return (
    <>
      <SearchHeader />
      <div style={{ minHeight: "calc(100vh - 400px)" }}>
        <div
          style={{
            paddingLeft: isWide
              ? is1400
                ? is1600
                  ? 100
                  : 270
                : 200
              : isTablet
              ? 50
              : 20,
            paddingRight: isWide
              ? is1400
                ? is1600
                  ? 100
                  : 270
                : 200
              : isTablet
              ? 50
              : 20,
            paddingTop: 150,
            paddingBottom: 70,
            maxWidth: 1580,
            margin: "auto",
          }}
        >
          {tutors && (count > 0 || module) && !loadingTutors && (
            <>
              <h1 style={{ fontSize: "1em" }}>
                {`Nachhilfe > ${uniName} > ${courseName}` +
                  (moduleName ? ` > ${moduleName}` : "")}
              </h1>

              <h2>
                {tutors &&
                  (count >= 2
                    ? count + " passende Tutoren:"
                    : count === 0
                    ? !module
                      ? "Nach Unterrichtsfach filtern"
                      : "Keine passenden Tutoren gefunden"
                    : "Ein passender Tutor:")}
              </h2>

              <div
                style={{
                  display: "flex",
                  flexDirection: isTablet ? "row" : "column",
                  marginTop: 40,
                  alignItems: isTablet ? "center" : "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: 250,
                    marginBottom: isTablet ? "" : 20,
                  }}
                >
                  <FilterDropdown
                    searchFunModules={searchFunModules}
                    setModuleSearch={setModuleSearch}
                    setModule={setModule}
                    module={module}
                    modules={modules}
                    loadingModules={loadingModules}
                  />
                </div>
                <ToggleSwitchRaw
                  onChange={() => setActiveOnly(!activeOnly)}
                  value={activeOnly}
                  text={"Nur verfügbare Tutoren anzeigen"}
                />
              </div>
            </>
          )}
        </div>
        {tutors && count === 0 && !module && !loadingTutors && (
          <div
            style={{
              paddingLeft: isWide ? (is1400 ? (is1600 ? 80 : 250) : 200) : 20,
              paddingRight: isWide ? (is1400 ? (is1600 ? 80 : 250) : 180) : 20,
              paddingBottom: isWide ? 60 : 50,
              paddingTop: 40,
              width: "100%",
            }}
          >
            <div
              style={{
                margin: "auto",
                maxWidth: 1380,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{ fontSize: 30, textAlign: "center", marginBottom: 20 }}
              >
                Für welches Fach suchst du Nachhilfe?
              </div>
              <div style={{ maxWidth: 250 }}>
                <FilterDropdown
                  standalone
                  searchFunModules={searchFunModules}
                  setModuleSearch={setModuleSearch}
                  setModule={setModule}
                  module={module}
                  modules={modules}
                  loadingModules={loadingModules}
                />
              </div>{" "}
            </div>
          </div>
        )}
        {tutors && (count > 0 || module) && (
          <div
            style={{
              paddingLeft: isWide ? (is1400 ? (is1600 ? 80 : 250) : 180) : 20,
              paddingRight: isWide ? (is1400 ? (is1600 ? 80 : 250) : 180) : 20,
              paddingTop: 80,
              paddingBottom: isWide ? 60 : 50,
              width: "100%",
              backgroundColor: "#f4f6fa",
            }}
          >
            <div
              style={{
                margin: "auto",
                maxWidth: 1380,
                display: "flex",
                flexDirection: "row",
                justifyContent: isWide ? "flex-start" : "center",
                flexWrap: "wrap",
              }}
            >
              {courseCards.map((tutorCard) => (
                <CourseCard key={tutorCard.id} {...tutorCard} />
              ))}

              {count > 0 &&
                filteredTutors.map((tutor) => (
                  <TutorCard
                    key={tutor.id}
                    {...tutor}
                    setFavorite={setFavorite}
                    course={course}
                    module={moduleName}
                  />
                ))}
              {count === 0 && module && !loadingTutors && (
                <div style={{ maxWidth: 400, marginLeft: isWide ? "5%" : 0 }}>
                  <h4 style={{ marginBottom: 15 }}>
                    Wir finden den passenden Tutor für dich!
                  </h4>
                  Leider konnten wir für deine Suche keine Tutoren finden.
                  Schreib uns eine{" "}
                  <a href="mailto:hello@unitutors.de ?subject=Ich suche eine/n Tutor/in&body=Hey, ich konnte bei der Suche keinen passenden Tutor für <HIER DEINE UNI EINFÜGEN>, <HIER DEINEN STUDIENGANG EINFÜGEN>, <HIER DEIN FACH EINFÜGEN> finden. Es wäre super, wenn ihr mir helfen könntet. %0A %0A Sollen wir dir bei erfolgreichem Finden eine SMS oder WhatsApp schicken? Dann kannst du uns zusätzlich deine Telefonnummer mitgeben. %0A %0A Tel.: <HIER DEINE TELEFONNUMMER EINFÜGEN>">
                    Mail
                  </a>{" "}
                  und wir versprechen dir, dass wir jemanden{" "}
                  <b>individuell für dein Anliegen</b> suchen! Alternativ kannst
                  du es in ein paar Tagen nochmal probieren, es kommen
                  regelmäßig neue Tutoren auf unsere Plattform.
                </div>
              )}
              {extraTutors && extraTutors.length > 0 && (
                <>
                  <div
                    style={{
                      width: "100%",
                      padding: 40,
                      borderRadius: 5,
                      color: "#5b616b",
                      fontSize: "16px",
                      backgroundColor: "#dfeafc",
                      margin: is1600
                        ? "50px 2.166%"
                        : isWide
                        ? "50px 2.5%"
                        : isTablet
                        ? "50px 27.5%"
                        : "50px 3%",
                    }}
                  >
                    Keine weiteren Tutoren in diesem Studiengang übrig!
                    <br />
                    <span>
                      Tipp: probiere es mit diesen Tutoren aus anderen
                      Studiengängen mit diesem Fach.
                    </span>
                  </div>
                  {extraTutors.map((tutor) => (
                    <TutorCard
                      key={tutor.id}
                      {...tutor}
                      setFavorite={setFavorite}
                      course={tutor.modules[0].courseId}
                      module={moduleName}
                    />
                  ))}
                </>
              )}
            </div>
            <VizSensor
              partialVisibility
              scrollCheck
              onChange={(visible) => visible && loadMore()}
            >
              <div
                style={{
                  minHeight: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader active={loadingTutors || !tutors} inline />
              </div>
            </VizSensor>
          </div>
        )}
      </div>

      <div
        style={{
          paddingLeft: isWide ? (is1400 ? (is1600 ? 80 : 250) : 180) : 20,
          paddingRight: isWide ? (is1400 ? (is1600 ? 80 : 250) : 180) : 20,
          paddingTop: tutors && count === 0 ? 20 : 60,
          paddingBottom: tutors && count === 0 ? 40 : 100,
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "auto",
            maxWidth: 1380,
            display: "flex",
            flexDirection: "column",
            justifyContent: isWide ? "flex-start" : "center",
            alignItems: isWide ? "" : "center",
            flexWrap: "wrap",
          }}
        >
          {!(tutors && count === 0) && !loadingTutors && (
            <>
              <div>
                <div style={{ maxWidth: 400, marginLeft: isWide ? "5%" : 0 }}>
                  <h4 style={{ marginBottom: 15, fontSize: "28px" }}>
                    Kein passender Tutor dabei?
                  </h4>
                  Dann schreib uns einfach eine{" "}
                  <a href="mailto:hello@unitutors.de ?subject=Ich suche eine/n Tutor/in&body=Hey, ich konnte bei der Suche keinen passenden Tutor für <HIER DEINE UNI EINFÜGEN>, <HIER DEINEN STUDIENGANG EINFÜGEN>, <HIER DEIN FACH EINFÜGEN> finden. Es wäre super, wenn ihr mir helfen könntet. %0A %0A Sollen wir dir bei erfolgreichem Finden eine SMS oder WhatsApp schicken? Dann kannst du uns zusätzlich deine Telefonnummer mitgeben. %0A %0A Tel.: <HIER DEINE TELEFONNUMMER EINFÜGEN>">
                    Mail
                  </a>{" "}
                  und wir versprechen dir, dass wir jemanden{" "}
                  <b>individuell für dein Anliegen</b> suchen! Alternativ kannst
                  du es in ein paar Tagen nochmal probieren, es kommen
                  regelmäßig neue Tutoren auf unsere Plattform.
                </div>
              </div>

              <div
                style={{
                  maxWidth: 400,
                  width: "100%",
                  marginLeft: isWide ? "5%" : 0,
                }}
              >
                <a href="mailto:hello@unitutors.de ?subject=Ich suche eine/n Tutor/in&body=Hey, ich konnte bei der Suche keinen passenden Tutor für <HIER DEINE UNI EINFÜGEN>, <HIER DEINEN STUDIENGANG EINFÜGEN>, <HIER DEIN FACH EINFÜGEN> finden. Es wäre super, wenn ihr mir helfen könntet. %0A %0A Sollen wir dir bei erfolgreichem Finden eine SMS oder WhatsApp schicken? Dann kannst du uns zusätzlich deine Telefonnummer mitgeben. %0A %0A Tel.: <HIER DEINE TELEFONNUMMER EINFÜGEN>">
                  <Button
                    className="button_full_width"
                    style={{
                      ...buttonStyle,
                      marginLeft: "auto",
                      marginTop: isWide ? 20 : 30,
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    Lass uns für dich suchen
                  </Button>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
SearchResultsScreen.propTypes = {};

export default connect(({ user }) => ({ user }), {})(SearchResultsScreen);
