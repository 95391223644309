import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "helpers/navigation";
import Dropdown from "./SearchDropdown";

import { useSearchUni, useSearchCourse } from "./searchEffects";
import { useMediaQuery } from "react-responsive";

const Searchbar = ({
  color = "#fff",
  inHeader,
  maxVw = 100,
  customRunSearch,
  prefilledUniSearch,
  prefilledUniId,
}) => {
  const {
    universities,
    setUniSearch,
    uni,
    setUni,
    loadingUnis,
    searchFunUnis,
  } = useSearchUni({
    defaultSearch: prefilledUniSearch,
    defaultSelection: prefilledUniId,
  });

  const {
    courses,
    setCourseSearch,
    course,
    setCourse,
    loadingCourses,
    searchFunCourses,
  } = useSearchCourse(uni);

  const history = useHistory();
  const runSearch = () => {
    if (customRunSearch) {
      customRunSearch("nachhilfe-tutoren/$1/$2", [uni, course.split("~")[1]]);
    } else {
      history.push("nachhilfe-tutoren/$1/$2", [uni, course.split("~")[1]]);
    }
  };

  const isMedium = useMediaQuery({ query: "(min-width: 700px)" });
  const isTwoLines = useMediaQuery({ query: "(max-width: 500px)" });

  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: color,
        flex: inHeader ? "2" : "",
        marginLeft: inHeader ? 20 : isMedium ? 20 : 0,
        maxWidth: 650,
        width: inHeader
          ? ""
          : isMedium
          ? `min(650px, calc(${maxVw}vw - 100px))`
          : `min(650px, calc(${maxVw}vw - 20px))`,
        borderRadius: inHeader ? "10px" : "20px 20px 20px 0px",
        display: "flex",
        boxShadow: inHeader
          ? "0px 0px 10px 4px rgb(218, 218, 218, 0.1)"
          : "5px 10px 80px 10px rgba(36, 52, 78, 0.05)",
      }}
    >
      <div
        style={{
          border: "1px solid",
          borderColor: inHeader ? "#dadada" : "#387bf5",
          borderRight: 0,
          borderRadius: inHeader ? "10px 0px 0px 10px" : "20px 0px 0px 0px",
          backgroundColor: "white",
          maxWidth: "calc(100% - 80px)",
          flex: 1,
        }}
      >
        <div
          style={{
            border: "0px solid",
            borderRadius: inHeader ? "10px" : "20px",
            padding: inHeader ? "10px 0px" : "10px 0px",
            backgroundColor: "white",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Dropdown
            search={searchFunUnis}
            className="dropdown_searchbar"
            placeholder="Uni auswählen"
            setSearch={setUniSearch}
            setValue={setUni}
            value={uni}
            options={universities}
            loading={loadingUnis > 0}
            style={{
              whiteSpace: "nowrap",
              paddingTop: inHeader ? 2.5 : 10,
              paddingBottom: inHeader ? 2.5 : 10,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: inHeader ? 20 : 0,
              flex: 1,
              fontSize: inHeader ? "14px" : "16px",
              minWidth: 200,
              backgroundColor: "white",
              paddingLeft: 30,
            }}
          />
          <Dropdown
            search={searchFunCourses}
            className="dropdown_searchbar"
            placeholder="Studiengang auswählen"
            setSearch={setCourseSearch}
            setValue={setCourse}
            value={course}
            options={courses}
            loading={loadingCourses > 0}
            style={{
              whiteSpace: "nowrap",
              paddingTop: inHeader ? 2.5 : 10,
              paddingBottom: inHeader ? 2.5 : 10,
              flex: 1,
              minWidth: 200,
              backgroundColor: "white",
              fontSize: inHeader ? "14px" : "16px",
              borderLeft: isTwoLines ? "none" : "1px solid #dadada",
              paddingLeft: 30,
            }}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "white",
          width: "80px",
          borderRadius: inHeader ? "0px 10px 10px 0px" : "0px 20px 20px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid",
          borderColor: inHeader ? "#dadada" : "#387bf5",
          borderLeft: 0,
          zIndex: 3,
        }}
      >
        <button
          onClick={runSearch}
          disabled={!(uni && course)}
          type="button"
          className="btn btn-info searchbutton"
          style={{
            backgroundColor: "rgb(56, 123, 245)",
            borderColor: "rgb(56, 123, 245)",
            postion: "relative",
            boxShadow: inHeader
              ? "0px 0px 20px 1px rgba(56, 123, 245, 0.08)"
              : "0px 0px 30px 2px rgba(56, 123, 245, 0.175)",
            borderRadius: "5px",
            width: inHeader ? "auto" : "45px",
            height: inHeader ? "35px" : "45px",
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    </div>
  );
};

export default Searchbar;
