import React, { useState, useEffect } from "react";
import {
  ProfileSidebar,
  Wrapper,
  Section,
  CardList as ACardList,
} from "components";
import { Dropdown, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { get, put } from "helpers/api";
import { useMediaQuery } from "react-responsive";
import { buttonStyle } from "helpers/theme";
import { Phone } from "components/Profile";
import RejectionModal from "./RejectionModal";

const CardList = (props) => (
  <ACardList {...props} card={({ ...ps }) => <Request {...ps} />} />
);

const Request = ({
  loading,
  setStatus: _setStatus,
  from: { firstName, lastName, profilePicture, phone, id },
  message,
  status,
  id: rId,
}) => {
  const padding = { paddingLeft: 20, paddingRight: 20 };
  const twoLines = !useMediaQuery({ query: "(min-width: 460px)" });

  const setStatus = (status) => _setStatus(status, id, rId);

  const content = (
    <>
      {status !== "Accepted" && (
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Dropdown
            icon={null}
            trigger={
              <Button
                labelPosition="right"
                className="annhemen_button"
                icon
                style={{
                  ...buttonStyle,
                  padding: "10px 16px 10px 12px",
                  height: "auto",
                  fontWeight: 600,
                }}
                loading={loading[rId]}
              >
                {status === "Declined" ? "Abgelehnt" : "Annehmen"}
                <Icon name="chevron down" />
              </Button>
            }
          >
            <Dropdown.Menu className="roundedright accept_reject">
              <Dropdown.Item
                text="Annehmen"
                onClick={() => setStatus("Accepted")}
              />
              {status !== "Declined" && (
                <Dropdown.Item
                  text="Ablehnen"
                  onClick={() => setStatus("Declined")}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <Phone style={padding} phone={phone} />
    </>
  );

  return (
    <div
      style={{
        margin: "15px 0px",
        padding: 20,
        boxShadow: "0px 0px 24px rgba(36, 52, 78, 0.2)",
        borderRadius: 10,
        display: twoLines ? "flex" : "",
        flexDirection: "column",
        alignItems: "center",
      }}
      className={status === "Declined" ? "fadedOut" : ""}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <img
          alt={`Bild von ${firstName} `}
          src={profilePicture}
          style={{
            borderRadius: 1000,
            width: twoLines ? 100 : 70,
            height: twoLines ? 100 : 70,
          }}
        />
        <div
          style={{
            fontSize: 16,
            fontWeight: 600,
            paddingLeft: 20,
            paddingRight: 10,
            color: "#2c3139",
            flex: 1,
          }}
        >
          {firstName}
        </div>
        {!twoLines && content}
      </div>

      {twoLines && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          {content}
        </div>
      )}
      <div
        style={{
          lineHeight: "24px",
          width: "100%",
          borderTop: "1px solid",
          marginTop: 15,
          paddingTop: 15,
          paddingLeft: 4,
          borderColor: "#edf0f6",
        }}
      >
        <b>Nachricht von {firstName}: </b>
        <br />
        {message}
      </div>
    </div>
  );
};

const MyStudents = ({ user, loadUser }) => {
  const [requests, setRequests] = useState({
    accepted: false,
    declined: [],
    pending: false,
  });

  const [loading, setLoading] = useState({});
  const loadData = (user) =>
    get("users/tutors/$1/requests", [user.uuid])
      .v(2)
      .auth(user.auth)
      .then(({ requests }) => {
        setRequests({
          accepted: requests.filter((r) => r.status === "Accepted"),
          declined: requests.filter((r) => r.status === "Declined"),
          pending: requests.filter((r) => r.status === "Pending"),
        });
        setLoading((loading) => ({}));
        setRejectionModalShown(false);
      });
  useEffect(() => {
    loadData(user);
  }, [user]);

  const sendStatus = (status, userId, requestId, response = "") => {
    setLoading((loading) => ({ ...loading, [requestId]: true }));
    put("users/tutors/$1/requests/$2/response", [user.uuid, requestId])
      .v(2)
      .auth(user.auth)
      .data({ status, response })
      .then(() => {
        loadData(user);
      });
  };

  const [rejectionModalShown, setRejectionModalShown] = useState(false);
  const setStatus = (status, userId, requestId) => {
    if (status === "Declined") {
      setRejectionModalShown([status, userId, requestId]);
    } else {
      sendStatus(status, userId, requestId);
    }
  };

  return (
    <>
      <Wrapper sidebar={<ProfileSidebar current="mystudents" />}>
        <div style={{ width: "100%" }}>
          <Section heading="Meine Stundenten">
            <CardList
              cards={requests.accepted}
              none="Du hast noch keine Studenten."
            />
          </Section>
          <Section heading="Anfragen">
            <CardList
              setStatus={setStatus}
              cards={requests.pending}
              loading={loading}
              none="Du hast keine offenen Anfragen."
            />
          </Section>
          {requests.declined.length > 0 && (
            <Section heading="Abgelehnt">
              <CardList
                cards={requests.declined}
                setStatus={setStatus}
                loading={loading}
                none=""
              />
            </Section>
          )}
        </div>
      </Wrapper>
      {rejectionModalShown && (
        <RejectionModal
          loading={Object.keys(loading) > 0}
          send={(response) => sendStatus(...rejectionModalShown, response)}
          onClose={() => {
            setRejectionModalShown(false);
          }}
        />
      )}
    </>
  );
};
MyStudents.propTypes = {};

export default connect(({ user }) => ({ user }), {})(MyStudents);
