import React from "react";
import { Footer, NoSearchHeader } from "components";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { loadUser } from "redux/user";
import { buttonStyleDoubleShadow } from "helpers/theme";
import Display from "./PaymentPage/PaymentDisplay";

import tickBlue from "assets/tick_blue.svg";

const PricingScreen = ({ user }) => {
  return (
    <>
      <NoSearchHeader />
      <Display>
        {user.subscribed ? (
          <div style={{ marginTop: 80 }}>
            <img
              src={tickBlue}
              style={{ width: 30, height: 30, marginRight: 10 }}
              alt=""
            />{" "}
            <b>Dein Student Pass ist aktiv</b>
          </div>
        ) : (
          <Link to="/payment">
            <Button style={{ ...buttonStyleDoubleShadow, marginTop: 60 }}>
              Jetzt Abonnieren
            </Button>
          </Link>
        )}
      </Display>
      <Footer />
    </>
  );
};

export default connect(
  ({ user, fetching: { user: fetchingUser } }) => ({ user, fetchingUser }),
  { loadUser }
)(PricingScreen);
