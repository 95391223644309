import React from "react";
import { useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { createFingerprint } from "helpers/tracking";

const Redirector = () => {
  const { rest } = useParams();

  const knownPrefixes = {
    fba: "/tutorwerden/",
    fbg: "/tutorwerden/",
    ia: "/tutorwerden/",
  };
  const matchingPrefixes = Object.keys(knownPrefixes).filter(
    (prefix) => rest.substr(0, prefix.length) === prefix
  );
  let target;
  if (matchingPrefixes.length === 1) {
    target = knownPrefixes[matchingPrefixes[0]] + "?mid=" + rest;
  } else {
    const redirects = {
      tutor1: "/tutorwerden/?mid=ad1",
      tutor2: "/tutorwerden/?mid=ad2",
      tutor3: "/tutorwerden/?mid=ad3",
      pc20: "/tutorwerden/?mid=pc20",
      pc22: "/tutorwerden/?mid=pc22",
      pc23: "/tutorwerden/?mid=pc23",
      pc25: "/tutorwerden/?mid=pc25",
      pc19: "/tutorwerden/?mid=pc19",
      pc21: "/tutorwerden/?mid=pc21",
      pc24: "/tutorwerden/?mid=pc24",
      pc26: "/tutorwerden/?mid=pc26",
      pc27: "/tutorwerden/?mid=pc27",
      pc28: "/tutorwerden/?mid=pc28",
      pc29: "/tutorwerden/?mid=pc29",
      pc30: "/tutorwerden/?mid=pc30",
      pc31: "/tutorwerden/?mid=pc31",
      pc12: "/tutorwerden/?mid=pc12",
      pc14: "/tutorwerden/?mid=pc14",
      pc16: "/tutorwerden/?mid=pc16",
      pc18: "/tutorwerden/?mid=pc18",
      pc11: "/tutorwerden/?mid=pc11",
      pc13: "/tutorwerden/?mid=pc13",
      pc15: "/tutorwerden/?mid=pc15",
      pc17: "/tutorwerden/?mid=pc17",
      pc1: "https://gewinnspiel.unitutors.org/?mid=pc1",
      pc2: "https://gewinnspiel.unitutors.org/?mid=pc2",
      pc3: "https://gewinnspiel.unitutors.org/?mid=pc3",
      pc4: "https://gewinnspiel.unitutors.org/?mid=pc4",
      pc5: "https://gewinnspiel.unitutors.org/?mid=pc5",
      pc6: "https://gewinnspiel.unitutors.org/?mid=pc6",
      pc7: "https://gewinnspiel.unitutors.org/?mid=pc7",
      pc8: "https://gewinnspiel.unitutors.org/?mid=pc8",
      pc9: "https://gewinnspiel.unitutors.org/?mid=pc9",
      pc10: "https://gewinnspiel.unitutors.org/?mid=pc10",

      studyscript1: "https://gewinnspiel.unitutors.org/?mid=story1",
      studyscript2: "https://gewinnspiel.unitutors.org/?mid=story2",
      studyscript3: "https://gewinnspiel.unitutors.org/?mid=story3",
      studyscript4: "https://gewinnspiel.unitutors.org/?mid=post1",

      gewinnspiel1: "https://gewinnspiel.unitutors.org/?mid=ad4",
      gewinnspiel: "https://gewinnspiel.unitutors.org/?mid=pcdirect1",
      "solution-pc12": "/more/solutions/12",
      "solution-pc14": "/more/solutions/14",
      "solution-pc16": "/more/solutions/16",
      "solution-pc18": "/more/solutions/18",
      "solution-pc11": "/more/solutions/11",
      "solution-pc13": "/more/solutions/13",
      "solution-pc15": "/more/solutions/15",
      "solution-pc17": "/more/solutions/17",
      "solution-pc8": "/more/solutions/8",
      "solution-pc9": "/more/solutions/9",
      "solution-pc10": "/more/solutions/10",
    };
    target = redirects[rest] || "/";
  }

  createFingerprint().then(() => {
    window.location.href = target;
  });

  return <Loader active />;
};

export default Redirector;
