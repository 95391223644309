import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { colors, buttonTextOnlyStyle } from "helpers/theme";
import { post } from "helpers/api";
import { useHistory, useRedirector, useLocation } from "helpers/navigation";

import { Button } from "semantic-ui-react";
import { ReactComponent as EmailIcon } from "assets/icons8-nachricht-gefüllt-100.svg";
import { ReactComponent as PhoneIcon } from "assets/icons8-telefon-100.svg";
import { ReactComponent as Close } from "assets/close.svg";

import urls from "config/urls";

const buttonStyle = {
  ...buttonTextOnlyStyle,
  background: "none",
  textDecoration: "underline",
  color: colors.stateRed[5],
  margin: 5,
};

const VerificationCheck = connect(
  ({ user }) => ({ user }),
  {}
)(({ user }) => {
  const { emailVerified, phoneVerified, phone, uuid } = user;
  const history = useHistory();

  const [emailDone, setEmailDone] = useState(false);
  const [phoneDone, setPhoneDone] = useState(false);

  useEffect(() => {
    setPhoneDone(false);
  }, [user.phone]);

  const phoneToBeDone = !phoneVerified && phone && !phoneDone && user.uuid,
    emailToBeDone = !emailVerified && !emailDone && user.uuid;
  const { pathname } = useLocation();
  const blacklistedUrls = ["/payment"];
  if (
    (!emailToBeDone && !phoneToBeDone) ||
    blacklistedUrls.indexOf(pathname) !== -1
  ) {
    return null;
  }

  const redirectQuery = useRedirector();

  const resend = () => {
    const type = emailToBeDone ? "email" : "phone";
    post("verifications/$1", [type])
      .auth(user.auth)
      .data({
        userId: uuid,
        returnUrl: "https://" + urls.domain + "/verify?type=" + type,
      })
      .then(() => {
        setDone();
      })
      .catch(() => {});
  };

  const setDone = () => {
    const type = emailToBeDone ? "email" : "phone";
    if (type === "email") {
      setEmailDone(true);
    } else {
      setPhoneDone(true);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        minHeight: 30,
        zIndex: 100000000,
        backgroundColor: colors.stateRed[2],
        color: colors.stateRed[5],
        textAlign: "center",
        padding: 5,
      }}
    >
      {emailToBeDone ? (
        <EmailIcon
          style={{ margin: 10, fill: colors.stateRed[5] }}
          height={22}
          width={22}
        />
      ) : (
        <PhoneIcon
          style={{ margin: 10, fill: colors.stateRed[5] }}
          height={22}
          width={22}
        />
      )}
      Bitte verifiziere deine{" "}
      {emailToBeDone ? "E-Mail Adresse" : "Telefonnummer"}!
      {emailToBeDone ? (
        <Button style={buttonStyle} onClick={resend}>
          Bestätigung neu senden
        </Button>
      ) : (
        <Button
          style={buttonStyle}
          onClick={() => {
            resend();
            history.push("verify", [], { type: "phone", ...redirectQuery.obj });
          }}
        >
          Jetzt bestätigen
        </Button>
      )}
      <Button
        icon
        style={{
          ...buttonStyle,
          textDecoration: "none",
          margin: 0,
          marginRight: 20,
          width: 40,
          float: "right",
        }}
        onClick={setDone}
      >
        <Close style={{ width: "100%", height: "auto", fill: "white" }} />
      </Button>
    </div>
  );
});

export default VerificationCheck;
