import React, { useState, useEffect } from "react";
import { Accordion } from "semantic-ui-react";

const FAQ = ({ content }) => {
  const [open, setOpen] = useState({});
  const toggleOpen = (i) => {
    setOpen((open) => ({ ...open, [i]: !open[i] }));
  };
  useEffect(() => {
    setOpen({});
  }, [content]);
  return (
    <Accordion style={{ width: "100%" }}>
      {content.map(({ title, content }, i) => (
        <div
          key={title}
          style={{ borderBottom: "1px solid", borderColor: "#dbdde2" }}
        >
          <Accordion.Title
            key={i}
            index={i}
            onClick={() => toggleOpen(i)}
            active={open[i]}
            style={{
              padding: 15,
            }}
          >
            <h5
              className="accordion_titel"
              style={{
                marginTop: 10,
                fontWeight: "300",
                fontSize: "19px",
                textAlign: "left",
                color: open[i] ? "#387bf5" : "",
              }}
            >
              {title}
            </h5>
          </Accordion.Title>
          <Accordion.Content active={open[i]}>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "300",
                padding: 15,
                textAlign: "left",
                marginBottom: 20,
              }}
            >
              {content.map((c) => (
                <p>{c}</p>
              ))}
            </div>
          </Accordion.Content>
        </div>
      ))}
    </Accordion>
  );
};

export default FAQ;
