import React from "react";
import { Footer, NoSearchHeader } from "components";
import background from "assets/Background_Rechtliches.svg";
import { useMediaQuery } from "react-responsive";

const Datenschutz = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "2600px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: 30,
            paddingTop: isTablet ? 300 : 150,
            paddingBottom: isTablet ? 210 : 130,
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          Datenschutzerklärung
        </h1>
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: 1100,
          padding: isTablet ? "0 50px" : "0 15px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: 100,
        }}
      >
        <h2>Einleitung</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
          welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von
          uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend
          zusammenfassend bezeichnet als "Onlineangebot“).
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 8. September 2020</p>
        <h2>Inhaltsübersicht</h2>
        <ul>
          <li>
            <p>Einleitung</p>
          </li>
          <li>
            <p>Verantwortlicher</p>
          </li>
          <li>
            <p>Übersicht der Verarbeitungen</p>
          </li>
          <li>
            <p>Maßgebliche Rechtsgrundlagen</p>
          </li>
          <li>
            <p>Sicherheitsmaßnahmen</p>
          </li>
          <li>
            <p>Übermittlung und Offenbarung von personenbezogenen Daten</p>
          </li>
          <li>
            <p>Datenverarbeitung in Drittländern</p>
          </li>
          <li>
            <p>Einsatz von Cookies</p>
          </li>
          <li>
            <p>Kommerzielle und geschäftliche Leistungen</p>
          </li>
          <li>
            <p>Zahlungsdienstleister</p>
          </li>
          <li>
            <p>Bereitstellung des Onlineangebotes und Webhosting</p>
          </li>
          <li>
            <p>Besondere Hinweise zu Applikationen (Apps)</p>
          </li>
          <li>
            <p>Registrierung, Anmeldung und Nutzerkonto</p>
          </li>
          <li>
            <p>Blogs und Publikationsmedien</p>
          </li>
          <li>
            <p>Kontaktaufnahme</p>
          </li>
          <li>
            <p>Kommunikation via Messenger</p>
          </li>
          <li>
            <p>Cloud-Dienste</p>
          </li>
          <li>
            <p>Gewinnspiele und Wettbewerbe</p>
          </li>
          <li>
            <p>Umfragen und Befragungen</p>
          </li>
          <li>
            <p>Webanalyse, Monitoring und Optimierung</p>
          </li>
          <li>
            <p>Onlinemarketing</p>
          </li>
          <li>
            <p>Präsenzen in sozialen Netzwerken (Social Media)</p>
          </li>
          <li>
            <p>Plugins und eingebettete Funktionen sowie Inhalte</p>
          </li>
          <li>
            <p>Planung, Organisation und Hilfswerkzeuge</p>
          </li>
          <li>
            <p>Löschung von Daten</p>
          </li>
          <li>
            <p>Änderung und Aktualisierung der Datenschutzerklärung</p>
          </li>
          <li>
            <p>Rechte der betroffenen Personen</p>
          </li>
          <li>
            <p>Begriffsdefinitionen</p>
          </li>
        </ul>
        <h2>Verantwortlicher</h2>
        <p>
          Unitutors
          <br />
          Jean-Nico Minor
          <br />
          Schlottfelder Str. 18
          <br />
          52074 Aachen
          <br />
          Deutschland
        </p>
        <p>E-Mail-Adresse: hello@unitutors.de</p>
        <p>Telefon: 015754135687</p>
        <p>Impressum: www.unitutors.de/impressum</p>
        <h2>Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>
            <p>Bestandsdaten (z.B. Namen, Adressen).</p>
          </li>
          <li>
            <p>Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</p>
          </li>
          <li>
            <p>Kontaktdaten (z.B. E-Mail, Telefonnummern).</p>
          </li>
          <li>
            <p>
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </p>
          </li>
          <li>
            <p>
              Sozialdaten (Daten, die dem Sozialgeheimnis (§ 35 SGB I)
              unterliegen und z.B. von Sozialversicherungsträgern,
              Sozialhilfeträger oder Versorgungsbehörden verarbeitet werden.).
            </p>
          </li>
          <li>
            <p>
              Standortdaten (Daten, die den Standort des Endgeräts eines
              Endnutzers angeben).
            </p>
          </li>
          <li>
            <p>
              Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
              Kundenkategorie).
            </p>
          </li>
          <li>
            <p>
              Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
              Zahlungshistorie).
            </p>
          </li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>
            <p>
              Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter).
            </p>
          </li>
          <li>
            <p>Geschäfts- und Vertragspartner.</p>
          </li>
          <li>
            <p>Interessenten.</p>
          </li>
          <li>
            <p>Kommunikationspartner.</p>
          </li>
          <li>
            <p>Kunden.</p>
          </li>
          <li>
            <p>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</p>
          </li>
          <li>
            <p>Gewinnspiel- und Wettbewerbsteilnehmer.</p>
          </li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>
            <p>Affiliate-Nachverfolgung.</p>
          </li>
          <li>
            <p>
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
            </p>
          </li>
          <li>
            <p>Besuchsaktionsauswertung.</p>
          </li>
          <li>
            <p>Büro- und Organisationsverfahren.</p>
          </li>
          <li>
            <p>Klickverfolgung.</p>
          </li>
          <li>
            <p>Content Delivery Network (CDN).</p>
          </li>
          <li>
            <p>
              Cross-Device Tracking (geräteübergreifende Verarbeitung von
              Nutzerdaten für Marketingzwecke).
            </p>
          </li>
          <li>
            <p>Direktmarketing (z.B. per E-Mail oder postalisch).</p>
          </li>
          <li>
            <p>Durchführung von Gewinnspielen und Wettbewerben.</p>
          </li>
          <li>
            <p>Feedback (z.B. Sammeln von Feedback via Online-Formular).</p>
          </li>
          <li>
            <p>Interessenbasiertes und verhaltensbezogenes Marketing.</p>
          </li>
          <li>
            <p>Kontaktanfragen und Kommunikation.</p>
          </li>
          <li>
            <p>Profiling (Erstellen von Nutzerprofilen).</p>
          </li>
          <li>
            <p>Remarketing.</p>
          </li>
          <li>
            <p>
              Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
              wiederkehrender Besucher).
            </p>
          </li>
          <li>
            <p>Sicherheitsmaßnahmen.</p>
          </li>
          <li>
            <p>
              Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung
              von Cookies).
            </p>
          </li>
          <li>
            <p>Vertragliche Leistungen und Service.</p>
          </li>
          <li>
            <p>Verwaltung und Beantwortung von Anfragen.</p>
          </li>
          <li>
            <p>
              Zielgruppenbildung (Bestimmung von für Marketingzwecke relevanten
              Zielgruppen oder sonstige Ausgabe von Inhalten).
            </p>
          </li>
        </ul>
        <h3>Maßgebliche Rechtsgrundlagen</h3>
        <p>
          Im Folgenden teilen wir die Rechtsgrundlagen der
          Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
          personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
          zusätzlich zu den Regelungen der DSGVO die nationalen
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten
          können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
          mit.
        </p>
        <p></p>
        <ul>
          <li>
            <p>
              Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) - Die betroffene
              Person hat ihre Einwilligung in die Verarbeitung der sie
              betreffenden personenbezogenen Daten für einen spezifischen Zweck
              oder mehrere bestimmte Zwecke gegeben.
            </p>
          </li>
          <li>
            <p>
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO) - Die Verarbeitung ist für die Erfüllung eines
              Vertrags, dessen Vertragspartei die betroffene Person ist, oder
              zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf
              Anfrage der betroffenen Person erfolgen.
            </p>
          </li>
          <li>
            <p>
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO) - Die
              Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
              erforderlich, der der Verantwortliche unterliegt.
            </p>
          </li>
          <li>
            <p>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO) - Die
              Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
              Interessen oder Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten erfordern,
              überwiegen.
            </p>
          </li>
        </ul>
        <p>
          Nationale Datenschutzregelungen in Deutschland: Zusätzlich zu den
          Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale
          Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere
          das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
          Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
          insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
          Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
          Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
          und zur Übermittlung sowie automatisierten Entscheidungsfindung im
          Einzelfall einschließlich Profiling. Des Weiteren regelt es die
          Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26
          BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder
          Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von
          Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen
          Bundesländer zur Anwendung gelangen.
        </p>
        <p></p>
        <h2>Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen und elektronischen Zugangs zu den Daten als
          auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
          Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
          Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
          die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
          gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
          Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          SSL-Verschlüsselung (https): Um Ihre via unser Online-Angebot
          übermittelten Daten zu schützen, nutzen wir eine SSL-Verschlüsselung.
          Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https://
          in der Adresszeile Ihres Browsers.
        </p>
        <h2>Übermittlung und Offenbarung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
          vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
          selbstständige Organisationseinheiten oder Personen übermittelt oder
          sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten
          können z.B. Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit
          IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und
          Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen
          Fall beachten wir die gesetzlichen Vorgaben und schließen insbesondere
          entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
          dienen, mit den Empfängern Ihrer Daten ab.
        </p>
        <p>
          Datenübermittlung innerhalb der Organisation: Wir können
          personenbezogene Daten an andere Stellen innerhalb unserer
          Organisation übermitteln oder ihnen den Zugriff auf diese Daten
          gewähren. Sofern diese Weitergabe zu administrativen Zwecken erfolgt,
          beruht die Weitergabe der Daten auf unseren berechtigten
          unternehmerischen und betriebswirtschaftlichen Interessen oder
          erfolgt, sofern sie Erfüllung unserer vertragsbezogenen
          Verpflichtungen erforderlich ist oder wenn eine Einwilligung der
          Betroffenen oder eine gesetzliche Erlaubnis vorliegt.
        </p>
        <h2>Datenverarbeitung in Drittländern</h2>
        <p>
          Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen
          Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder
          die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
          oder der Offenlegung bzw. Übermittlung von Daten an andere Personen,
          Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit
          den gesetzlichen Vorgaben.{" "}
        </p>
        <p>
          Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
          gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die
          Daten nur in Drittländern mit einem anerkannten Datenschutzniveau,
          vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln
          der EU-Kommission, beim Vorliegen von Zertifizierungen oder
          verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44
          bis 49 DSGVO, Informationsseite der EU-Kommission:
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
          ).
        </p>
        <h2>Einsatz von Cookies</h2>
        <p>
          Cookies sind Textdateien, die Daten von besuchten Websites oder
          Domains enthalten und von einem Browser auf dem Computer des Benutzers
          gespeichert werden. Ein Cookie dient in erster Linie dazu, die
          Informationen über einen Benutzer während oder nach seinem Besuch
          innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten
          Angaben können z.B. die Spracheinstellungen auf einer Webseite, der
          Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut
          wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere
          Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B.,
          wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen
          gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
        </p>
        <p>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</p>
        <ul>
          <li>
            <p>
              Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):Temporäre
              Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
              Online-Angebot verlassen und seinen Browser geschlossen hat.
            </p>
          </li>
          <li>
            <p>
              Permanente Cookies:Permanente Cookies bleiben auch nach dem
              Schließen des Browsers gespeichert. So kann beispielsweise der
              Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt
              werden, wenn der Nutzer eine Website erneut besucht. Ebenso können
              die Interessen von Nutzern, die zur Reichweitenmessung oder zu
              Marketingzwecken verwendet werden, in einem solchen Cookie
              gespeichert werden.
            </p>
          </li>
          <li>
            <p>
              First-Party-Cookies:First-Party-Cookies werden von uns selbst
              gesetzt.
            </p>
          </li>
          <li>
            <p>
              Third-Party-Cookies (auch: Drittanbieter-Cookies):
              Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden
              (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten.
            </p>
          </li>
          <li>
            <p>
              Notwendige (auch: essentielle oder unbedingt erforderliche)
              Cookies: Cookies können zum einen für den Betrieb einer Webseite
              unbedingt erforderlich sein (z.B. um Logins oder andere
              Nutzereingaben zu speichern oder aus Gründen der Sicherheit).
            </p>
          </li>
          <li>
            <p>
              Statistik-, Marketing- und Personalisierungs-Cookies: Ferner
              werden Cookies im Regelfall auch im Rahmen der Reichweitenmessung
              eingesetzt sowie dann, wenn die Interessen eines Nutzers oder sein
              Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von
              Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil
              gespeichert werden. Solche Profile dienen dazu, den Nutzern z.B.
              Inhalte anzuzeigen, die ihren potentiellen Interessen entsprechen.
              Dieses Verfahren wird auch als "Tracking", d.h., Nachverfolgung
              der potentiellen Interessen der Nutzer bezeichnet. Soweit wir
              Cookies oder "Tracking"-Technologien einsetzen, informieren wir
              Sie gesondert in unserer Datenschutzerklärung oder im Rahmen der
              Einholung einer Einwilligung.
            </p>
          </li>
        </ul>
        <p>
          Hinweise zu Rechtsgrundlagen: Auf welcher Rechtsgrundlage wir Ihre
          personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon
          ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und
          Sie in die Nutzung von Cookies einwilligen, ist die Rechtsgrundlage
          der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls
          werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage
          unserer berechtigten Interessen (z.B. an einem
          betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen
          Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies
          erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
        </p>
        <p>
          Speicherdauer: Sofern wir Ihnen keine expliziten Angaben zur
          Speicherdauer von permanenten Cookies mitteilen (z. B. im Rahmen eines
          sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die
          Speicherdauer bis zu zwei Jahre betragen kann.
        </p>
        <p>
          Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): Abhängig
          davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder
          gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit,
          eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer
          Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als
          "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst mittels
          der Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung
          von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit
          unseres Onlineangebotes eingeschränkt werden kann). Ein Widerspruch
          gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann
          auch mittels einer Vielzahl von Diensten, vor allem im Fall des
          Trackings, über die Webseiten https://optout.aboutads.info und
          https://www.youronlinechoices.com/ erklärt werden. Daneben können Sie
          weitere Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten
          Dienstleistern und Cookies erhalten.
        </p>
        <p>
          Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Bevor
          wir Daten im Rahmen der Nutzung von Cookies verarbeiten oder
          verarbeiten lassen, bitten wir die Nutzer um eine jederzeit
          widerrufbare Einwilligung. Bevor die Einwilligung nicht ausgesprochen
          wurde, werden allenfalls Cookies eingesetzt, die für den Betrieb
          unseres Onlineangebotes unbedingt erforderlich sind.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <p />
        <p></p>
        <h2>Kommerzielle und geschäftliche Leistungen</h2>
        <p>
          Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B.
          Kunden und Interessenten (zusammenfassend bezeichnet als
          "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren
          Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen
          der Kommunikation mit den Vertragspartnern (oder vorvertraglich),
          z.B., um Anfragen zu beantworten.
        </p>
        <p>
          Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen
          Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit diesen
          Angaben einhergehenden Verwaltungsaufgaben sowie der unternehmerischen
          Organisation. Die Daten der Vertragspartner geben wir im Rahmen des
          geltenden Rechts nur insoweit an Dritte weiter, als dies zu den
          vorgenannten Zwecken oder zur Erfüllung gesetzlicher Pflichten
          erforderlich ist oder mit Einwilligung der betroffenen Personen
          erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und
          sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und
          Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über
          weitere Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden
          die Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.
        </p>
        <p>
          Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir
          den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
          Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw.
          Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
        </p>
        <p>
          Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
          vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von 4
          Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
          werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung
          aufbewahrt werden müssen (z.B. für Steuerzwecke im Regelfall 10
          Jahre). Daten, die uns im Rahmen eines Auftrags durch den
          Vertragspartner offengelegt wurden, löschen wir entsprechend den
          Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
        </p>
        <p>
          Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
          Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und
          den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der
          jeweiligen Drittanbieter oder Plattformen.
        </p>
        <p>
          Kundenkonto: Vertragspartner können innerhalb unseres Onlineangebotes
          ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz "Kundenkonto").
          Falls die Registrierung eines Kundenkontos erforderlich ist, werden
          Vertragspartner hierauf ebenso hingewiesen wie auf die für die
          Registrierung erforderlichen Angaben. Die Kundenkonten sind öffentlich
          und können von Suchmaschinen indexiert werden. Im Rahmen der
          Registrierung sowie anschließender Anmeldungen und Nutzungen des
          Kundenkontos speichern wir die IP-Adressen der Kunden nebst den
          Zugriffszeitpunkten, um die Registrierung nachweisen und etwaigem
          Missbrauch des Kundenkontos vorbeugen zu können.
        </p>
        <p>
          Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das
          Kundenkonto betreffenden Daten gelöscht, vorbehaltlich, deren
          Aufbewahrung ist aus gesetzlichen Gründen erforderlich. Es obliegt den
          Kunden, ihre Daten bei erfolgter Kündigung des Kundenkontos zu
          sichern.
        </p>
        <p>
          Makler- und Vermittlungsleistungen: Wir verarbeiten die Daten unserer
          Kunden, Klienten und Interessenten (einheitlich bezeichnet als
          "Kunden“) entsprechend dem zugrundeliegenden Auftrag der Kunden. Wir
          können ferner die Angaben zu den Eigenschaften und Umständen von
          Personen oder ihnen gehörenden Sachen verarbeiten, wenn dies zum
          Gegenstand unseres Auftrags gehört. Dies können z.B. Angaben zu
          persönlichen Lebensumständen, mobilen oder immobilen Sachgütern und
          der finanziellen Situation sein.
        </p>
        <p>
          Sofern für die Vertragserfüllung oder gesetzlich erforderlich bzw.
          durch die Kunden bewilligt oder auf Grundlage auf unserer berechtigten
          Interessen basierend, offenbaren oder übermitteln wir die Daten der
          Kunden im Rahmen von Deckungsanfragen, Abschlüssen und der Abwicklung
          von Verträgen an Anbieter der vermittelten Leistungen/ Objekte,
          Versicherer, Rückversicherer, Maklerpools, technische Dienstleister,
          sonstige Dienstleister, wie z.B. kooperierende Verbände, sowie
          Finanzdienstleister, Kreditinstitute und Kapitalanlagegesellschaften
          sowie Sozialversicherungsträger, Steuerbehörden, Steuerberater,
          Rechtsberater, Wirtschaftsprüfer, Versicherungs-Ombudsmänner und die
          Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin). Ferner können
          wir, vorbehaltlich anderer Abreden, Unterauftragnehmer beauftragen,
          wie z.B. Untervermittler.
        </p>
        <p>
          Angebot von Software- und Plattformleistungen: Wir verarbeiten die
          Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer
          (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber
          unsere vertraglichen Leistungen erbringen zu können sowie auf
          Grundlage berechtigter Interessen, um die Sicherheit unseres Angebotes
          gewährleisten und es weiterentwickeln zu können. Die erforderlichen
          Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw.
          vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur
          Leistungserbringung und Abrechnung benötigten Angaben sowie
          Kontaktinformationen, um etwaige Rücksprachen halten zu können.
        </p>
        <p>
          Vermittlungsleistungen: Wir verarbeiten die von den Interessenten im
          Rahmen der Vermittlungsanfrage gemachten Angaben für Zwecke der
          Begründung, Durchführung und ggf. Beendigung eines Vertrages zur
          Vermittlung von Angeboten von Anbietern der ihrerseits angefragten
          Produkte oder Dienstleistungen.
        </p>
        <p>
          Die Kontaktdaten der Interessenten nutzen wir, um ihre Anfrage mittels
          des vereinbarten oder sonst erlaubten Kommunikationskanals (z.B.
          Telefon oder E-Mail) zu spezifizieren und um ihnen auf Grundlage der
          spezifizierten Anfrage passende Anbieter oder Angebote vorzuschlagen.
          Zudem können wir Interessenten zu einem späteren Zeitpunkt, im
          Einklang mit gesetzlichen Vorgaben, Rückfragen zum Erfolg unserer
          Vermittlungsleistung stellen.
        </p>
        <p>
          Wir verarbeiten die Daten der Interessenten als auch der Anbieter zur
          Erfüllung unserer Vertragspflichten, um die uns angetragene Anfrage
          der Interessenten mit den zu ihr passenden Angeboten der Anbieter zu
          verknüpfen und sie an entsprechende Anbieter weiterzuleiten, bzw. die
          Anbieter vorzuschlagen.
        </p>
        <p>
          Wir können die Eingaben in das von Interessenten abgesandte
          Onlineformular protokollieren, um das Bestehen des
          Vertragsverhältnisses und Einverständnisse der Interessenten
          entsprechend den gesetzlichen Rechenschaftspflichten (Art. 5 Abs. 2
          DSGVO) nachweisen zu können. Diese Angaben werden für einen Zeitraum
          von drei bis vier Jahren gespeichert, falls wir die ursprüngliche
          Anfrage nachweisen müssen (z.B., um die Berechtigung zu einer
          Kontaktaufnahme mit den Interessenten belegen zu können).
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
              Zahlungshistorie), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
              Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Interessenten, Geschäfts- und
              Vertragspartner, Kunden.
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Vertragliche Leistungen und Service,
              Kontaktanfragen und Kommunikation, Büro- und
              Organisationsverfahren, Verwaltung und Beantwortung von Anfragen,
              Sicherheitsmaßnahmen, Besuchsaktionsauswertung.
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Rechtliche Verpflichtung (Art.
              6 Abs. 1 S. 1 lit. c. DSGVO), Berechtigte Interessen (Art. 6 Abs.
              1 S. 1 lit. f. DSGVO), Einwilligung (Art. 6 Abs. 1 S. 1 lit. a
              DSGVO).
            </p>
          </li>
        </ul>
        <h2>Zahlungsdienstleister</h2>
        <p>
          Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund
          gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
          Interessen bieten wir den betroffenen Personen effiziente und sichere
          Zahlungsmöglichkeiten an und setzen hierzu neben Banken und
          Kreditinstituten weitere Zahlungsdienstleister ein (zusammenfassend
          "Zahlungsdienstleister").
        </p>
        <p>
          Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören
          Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B.
          Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen
          sowie die Vertrags-, Summen- und empfängerbezogenen Angaben. Die
          Angaben sind erforderlich, um die Transaktionen durchzuführen. Die
          eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister
          verarbeitet und bei diesen gespeichert. D.h., wir erhalten keine
          konto- oder kreditkartenbezogenen Informationen, sondern lediglich
          Informationen mit Bestätigung oder Negativbeauskunftung der Zahlung.
          Unter Umständen werden die Daten seitens der Zahlungsdienstleister an
          Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die
          Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und
          die Datenschutzhinweise der Zahlungsdienstleister.
        </p>
        <p>
          Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
          Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche
          innerhalb der jeweiligen Webseiten bzw. Transaktionsapplikationen
          abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer
          Informationen und Geltendmachung von Widerrufs-, Auskunfts- und
          anderen Betroffenenrechten.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
              Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand,
              Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>Betroffene Personen: Kunden, Interessenten.</p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Vertragliche Leistungen und Service,
              Kontaktanfragen und Kommunikation, Affiliate-Nachverfolgung.
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Klarna / Sofortüberweisung: Zahlungsdienstleistungen;
              Dienstanbieter: Klarna Bank AB (publ), Sveavägen 46, 111 34
              Stockholm, Schweden; Website: https://www.klarna.com/de;
              Datenschutzerklärung: https://www.klarna.com/de/datenschutz.
            </p>
          </li>
          <li>
            <p>
              PayPal: Zahlungsdienstleistungen und -Lösungen (z.B. PayPal,
              PayPal Plus, Braintree); Dienstanbieter: PayPal (Europe) S.à r.l.
              et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg; Website:
              https://www.paypal.com/de; Datenschutzerklärung:
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full.
            </p>
          </li>
          <li>
            <p>
              Stripe: Zahlungsdienstleistungen; Dienstanbieter: Stripe, Inc.,
              510 Townsend Street, San Francisco, CA 94103, USA; Website:
              https://stripe.com/de; Datenschutzerklärung:
              https://stripe.com/de/privacy.
            </p>
          </li>
          <li>
            <p>
              Visa: Zahlungsdienstleistungen; Dienstanbieter: Visa Europe
              Services Inc., Zweigniederlassung London, 1 Sheldon Square, London
              W2 6TT, GB; Website: https://www.visa.de; Datenschutzerklärung:
              https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html.
            </p>
          </li>
        </ul>
        <h2>Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Um unser Onlineangebot sicher und effizient bereitstellen zu können,
          nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern
          in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern)
          das Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
          Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
          Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und
          technische Wartungsleistungen in Anspruch nehmen.
        </p>
        <p>
          Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
          Daten können alle die Nutzer unseres Onlineangebotes betreffenden
          Angaben gehören, die im Rahmen der Nutzung und der Kommunikation
          anfallen. Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist,
          um die Inhalte von Onlineangeboten an Browser ausliefern zu können,
          und alle innerhalb unseres Onlineangebotes oder von Webseiten
          getätigten Eingaben.
        </p>
        <p>
          E-Mail-Versand und -Hosting: Die von uns in Anspruch genommenen
          Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang
          sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
          Adressen der Empfänger sowie Absender als auch weitere Informationen
          betreffend den E-Mailversand (z.B. die beteiligten Provider) sowie die
          Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten
          können ferner zu Zwecken der Erkennung von SPAM verarbeitet werden.
          Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich
          nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar
          auf dem Transportweg verschlüsselt, aber (sofern kein sogenanntes
          Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den
          Servern, von denen sie abgesendet und empfangen werden. Wir können
          daher für den Übertragungsweg der E-Mails zwischen dem Absender und
          dem Empfang auf unserem Server keine Verantwortung übernehmen.
        </p>
        <p>
          Erhebung von Zugriffsdaten und Logfiles: Wir selbst (bzw. unser
          Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server
          (sogenannte Serverlogfiles). Zu den Serverlogfiles können die Adresse
          und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des
          Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf,
          Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL
          (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der
          anfragende Provider gehören.
        </p>
        <p>
          Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
          eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
          (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
          DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
          Stabilität sicherzustellen.
        </p>
        <p>
          Content-Delivery-Network: Wir setzen ein "Content-Delivery-Network"
          (CDN) ein. Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte eines
          Onlineangebotes, insbesondere große Mediendateien, wie Grafiken oder
          Programm-Skripte, mit Hilfe regional verteilter und über das Internet
          verbundener Server schneller und sicherer ausgeliefert werden können.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Inhaltsdaten (z.B. Texteingaben,
              Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Content Delivery Network (CDN),
              Vertragliche Leistungen und Service.
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              1&amp;1 IONOS: Hostingplattform für E-Commerce / Websites;
              Dienstanbieter: 1&amp;1 IONOS SE, Elgendorfer Str. 57, 56410
              Montabaur, Deutschland; Website: https://www.ionos.de;
              Datenschutzerklärung:
              https://www.ionos.de/terms-gtc/terms-privacy.
            </p>
          </li>
          <li>
            <p>
              Amazon Web Services (AWS): Webhosting und infrastrukturelle
              Dienstleistungen; Dienstanbieter: Amazon Web Services, Inc., 410
              Terry Avenue North, Seattle WA 98109, USA; Website:
              https://aws.amazon.com/de/; Datenschutzerklärung:
              https://aws.amazon.com/de/privacy/?nc1=f_pr.
            </p>
          </li>
          <li>
            <p>
              Google Cloud: Wir verwenden Google Kubernetes zur Speicherung der
              Nutzerdaten und zum Hosten der Datenbanken. Außerdem werden Mails
              über Google versendet. Dienstanbieter: Google Ireland Limited,
              Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
              Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA; Website: https://cloud.google.com/?hl=de
              Datenschutzerklärung: https://policies.google.com/privacy.
            </p>
          </li>
        </ul>
        <h2>Besondere Hinweise zu Applikationen (Apps)</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer unserer Applikation, soweit diese
          erforderlich sind, um den Nutzern die Applikation sowie deren
          Funktionalitäten bereitstellen, deren Sicherheit überwachen und sie
          weiterentwickeln zu können. Wir können ferner Nutzer unter Beachtung
          der gesetzlichen Vorgaben kontaktieren, sofern die Kommunikation zu
          Zwecken der Administration oder Nutzung der Applikation erforderlich
          ist. Im Übrigen verweisen wir im Hinblick auf die Verarbeitung der
          Daten der Nutzer auf die Datenschutzhinweise in dieser
          Datenschutzerklärung.
        </p>
        <p>
          Rechtsgrundlagen: Die Verarbeitung von Daten, die für die
          Bereitstellung der Funktionalitäten der Applikation erforderlich ist,
          dient der Erfüllung von vertraglichen Pflichten. Dies gilt auch, wenn
          die Bereitstellung der Funktionen eine Berechtigung der Nutzer (z. B.
          Freigaben von Gerätefunktionen) voraussetzt. Sofern die Verarbeitung
          von Daten für die Bereitstellung der Funktionalitäten der Applikation
          nicht erforderlich ist, aber der Sicherheit der Applikation oder
          unseren betriebswirtschaftlichen Interessen dient (z. B. Erhebung von
          Daten zu Zwecken der Optimierung der Applikation oder
          Sicherheitszwecken), erfolgt sie auf Grundlage unserer berechtigten
          Interessen. Sofern Nutzer ausdrücklich deren Einwilligung in die
          Verarbeitung ihrer Daten gebeten werden, erfolgt die Verarbeitung der
          von der Einwilligung umfassten Daten auf Grundlage der Einwilligung.
        </p>
        <p>
          Kommerzielle Nutzung: Wir verarbeiten die Daten der Nutzer unserer
          Applikation, angemeldeter und etwaiger Testnutzer (nachfolgend
          einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber unsere
          vertraglichen Leistungen erbringen zu können sowie auf Grundlage
          berechtigter Interessen, um die Sicherheit unserer Applikation
          gewährleisten und sie weiterzuentwickeln zu können. Die erforderlichen
          Angaben sind als solche im Rahmen des Nutzungs-, Auftrags-, Bestell-
          bzw. vergleichbaren Vertragsschlusses gekennzeichnet und können die
          zur Leistungserbringung und zur etwaigen Abrechnung benötigten Angaben
          sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können,
          umfassen.
        </p>
        <p>
          Speicherung eines universellen und eindeutigen Identifikators (UUID):
          Die Applikation speichert für Zwecke der Analyse der Nutzung und
          Funktionsfähigkeit der Applikation sowie der Speicherung der
          Einstellungen der Nutzer einen so genannten universellen und
          eindeutigen Identifikator (englisch "Universally Unique Identifier",
          UUID). Dieser Identifikator wird bei der Installation dieser Anwendung
          generiert (ist jedoch nicht mit dem Gerät verbunden, also keine
          Gerätekennung in diesem Sinne), bleibt zwischen dem Start der
          Applikation sowie ihrer Aktualisierungen gespeichert und wird gelöscht
          wenn Nutzer die Applikation von ihrem Gerät entfernen.
        </p>
        <p>
          Geräteberechtigungen für den Zugriff auf Funktionen und Daten: Die
          Nutzung unserer Applikation oder ihrer Funktionalitäten kann
          Berechtigungen der Nutzer für Zugriff auf bestimmten Funktionen der
          verwendeten Geräte oder auf die, auf den Geräten gespeicherten oder
          mit Hilfe der Geräte zugänglichen Daten voraussetzen. Standardmäßig
          müssen diese Berechtigungen von den Nutzern erteilt werden und können
          jederzeit in den Einstellungen der jeweiligen Geräte widerrufen
          werden. Das genaue Verfahren zur Kontrolle der App-Berechtigungen kann
          vom Gerät und der Software der Nutzern abhängig sein. Bei
          Erläuterungsbedarf können sich Nutzer an uns wenden. Wir weisen darauf
          hin, dass die Versagung oder Widerruf der jeweiligen Berechtigungen
          die Funktionsfähigkeit unsere Applikation beeinflussen kann.
        </p>
        <p>
          Zugriff auf die Kamera sowie gespeicherten Aufnahmen: Im Rahmen der
          Nutzung unserer Applikation werden Bild- und/ oder Videoaufnahmen
          (wobei auch Audioaufnahmen umfasst sind) der Nutzer (und von anderen,
          von den Aufnahmen erfassten Personen) durch den Zugriff auf die
          Kamerafunktionen oder auf gespeicherte Aufnahmen verarbeitet. Der
          Zugriff auf die Kamerafunktionen oder gespeicherte Aufnahmen setzt
          eine jederzeit widerrufbare Berechtigung durch die Nutzer voraus. Die
          Verarbeitung der Bild- und/ oder Videoaufnahmen dient jeweils nur der
          Bereitstellung der jeweiligen Funktionalität unserer Applikation,
          entsprechend ihrer Beschreibung gegenüber den Nutzern, bzw. ihrer
          typischen und erwartbaren Funktionsweise.
        </p>
        <p>
          Kein Standortverlauf und keine Bewegungsprofile: Die Standortdaten
          werden lediglich punktuell eingesetzt und nicht zur Bildung eines
          Standortverlaufs oder eines Bewegungsprofils der verwendeten Geräte,
          bzw. ihrer Nutzer verarbeitet.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
              Zahlungshistorie), Vertragsdaten (z.B. Vertragsgegenstand,
              Laufzeit, Kundenkategorie), Bild- und/ oder Videoaufnahmen (z. B.
              Fotografien oder Videoaufnahmen einer Person), Standortdaten
              (Daten, die den Standort des Endgeräts eines Endnutzers angeben).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>Zwecke der Verarbeitung: Vertragliche Leistungen und Service.</p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </p>
          </li>
        </ul>
        <h2>Öffentlich zugängliche Inhalte</h2>
        <p>
          Unitutors ist eine Plattform zur Vermittlung von Kontaktdaten. Nutzer
          können untereinander interagieren. Dementsprechend werden alle von Dir
          zu deiner eigenen Person eingestellten Daten öffentlich gemacht.
          Ausnahme sind deine Telefonnummer sowie Email-Adresse. Diese werden
          nur deinen Tutoren und Studenten weitergegeben. Vor der Weitergabe
          findet eine Kontaktanfrage zwischen Tutor und Student statt.
        </p>
        <p>
          Sofern du damit nicht einverstanden bist, kannst du dein Nutzerkonto
          löschen, indem du uns eine Mail mit der Bitte der Löschung schreibst.
        </p>
        <p></p>
        <h2>
          Veröffentlichung bei Google und in anderen öffentlichen Suchmaschinen
          sowie auf Webseiten Dritter
        </h2>
        <p>
          Als „Tutor“ auf Unitutors willigst Du ein, dass ausgweählte
          Informationen, die in deinen Anzeigen enthalten sind (Foto, Vorname,
          Uni, Studiengang, Beschreibungen, Stundenlohn etc.) mit externen
          Suchmaschinen oder auch mit externen Webseiten geteilt werden.
        </p>
        <p>
          Um Deine Möglichkeiten zum Finden von neuen Schülern noch weiter zu
          verbessern, können daher ausgewählte Informationen in den
          Suchergebnissen Dritter (z.B. Google) und auf den externen Webseiten
          Dritter angezeigt werden, die diese Informationen der Öffentlichkeit
          zugänglich machen und auf Deinen Eintrag bei Superprof verlinken
          dürfen.
        </p>
        <h2>Registrierung, Anmeldung und Nutzerkonto</h2>
        <p>
          Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung
          werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu
          Zwecken der Bereitstellung des Nutzerkontos auf Grundlage
          vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten
          gehören insbesondere die Login-Informationen (Name, Passwort sowie
          eine E-Mail-Adresse). Die im Rahmen der Registrierung eingegebenen
          Daten werden für die Zwecke der Nutzung des Nutzerkontos und dessen
          Zwecks verwendet.{" "}
        </p>
        <p>
          Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant
          sind, wie z.B. technische Änderungen, per E-Mail und SMS informiert
          werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren
          Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer
          gesetzlichen Aufbewahrungspflicht, gelöscht. Es obliegt den Nutzern,
          ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern.
          Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherte
          Daten des Nutzers unwiederbringlich zu löschen.
        </p>
        <p>
          Im Rahmen der Inanspruchnahme unserer Registrierungs- und
          Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
          IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
          Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
          auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger
          unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
          grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer
          Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung
          hierzu.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Vertragliche Leistungen und Service,
              Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </p>
          </li>
        </ul>
        <h2>Verwendung deiner Kontaktdaten für Benachrichtigungen</h2>
        <p>
          Wir werden dich über neue Anfragen, Bewertungen, Rückmeldungen zu
          Anfragen und sonstige Nutzerinteraktionsbenachrichtigungen per Email
          und SMS benachrichtigen.
        </p>
        <h2>Blogs und Publikationsmedien</h2>
        <p>
          Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und
          Publikation (nachfolgend "Publikationsmedium"). Die Daten der Leser
          werden für die Zwecke des Publikationsmediums nur insoweit
          verarbeitet, als es für dessen Darstellung und die Kommunikation
          zwischen Autoren und Lesern oder aus Gründen der Sicherheit
          erforderlich ist. Im Übrigen verweisen wir auf die Informationen zur
          Verarbeitung der Besucher unseres Publikationsmediums im Rahmen dieser
          Datenschutzhinweise.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Vertragliche Leistungen und Service,
              Feedback (z.B. Sammeln von Feedback via Online-Formular).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <h2>Kontaktaufnahme</h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
          Telefon oder via soziale Medien) werden die Angaben der anfragenden
          Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen
          und etwaiger angefragter Maßnahmen erforderlich ist.
        </p>
        <p>
          Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder
          vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
          vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen
          Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an
          der Beantwortung der Anfragen.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos).
            </p>
          </li>
          <li>
            <p>Betroffene Personen: Kommunikationspartner.</p>
          </li>
          <li>
            <p>Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation.</p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <h2>Kommunikation via Messenger</h2>
        <p>
          Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten daher
          darum, die nachfolgenden Hinweise zur Funktionsfähigkeit der
          Messenger, zur Verschlüsselung, zur Nutzung der Metadaten der
          Kommunikation und zu Ihren Widerspruchsmöglichkeiten zu beachten.
        </p>
        <p>
          Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder
          E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten
          Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes
          angegebenen Kontaktmöglichkeiten.
        </p>
        <p>
          Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der
          Inhalt Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass die
          Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte
          Bilder) von Ende zu Ende verschlüsselt werden. Das bedeutet, dass der
          Inhalt der Nachrichten nicht einsehbar ist, nicht einmal durch die
          Messenger-Anbieter selbst. Sie sollten immer eine aktuelle Version der
          Messenger mit aktivierter Verschlüsselung nutzen, damit die
          Verschlüsselung der Nachrichteninhalte sichergestellt ist.{" "}
        </p>
        <p>
          Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin,
          dass die Anbieter der Messenger zwar nicht den Inhalt einsehen, aber
          in Erfahrung bringen können, dass und wann Kommunikationspartner mit
          uns kommunizieren sowie technische Informationen zum verwendeten Gerät
          der Kommunikationspartner und je nach Einstellungen ihres Gerätes auch
          Standortinformationen (sogenannte Metadaten) verarbeitet werden.
        </p>
        <p>
          Hinweise zu Rechtsgrundlagen: Sofern wir Kommunikationspartner vor der
          Kommunikation mit ihnen via Messenger um eine Erlaubnis bitten, ist
          die Rechtsgrundlage unserer Verarbeitung ihrer Daten deren
          Einwilligung. Im Übrigen, falls wir nicht um eine Einwilligung bitten
          und sie z.B. von sich aus Kontakt mit uns aufnehmen, nutzen wir
          Messenger im Verhältnis zu unseren Vertragspartnern sowie im Rahmen
          der Vertragsanbahnung als eine vertragliche Maßnahme und im Fall
          anderer Interessenten und Kommunikationspartner auf Grundlage unserer
          berechtigten Interessen an einer schnellen und effizienten
          Kommunikation und Erfüllung der Bedürfnisse unser
          Kommunikationspartner an der Kommunikation via Messenger. Ferner
          weisen wir Sie darauf hin, dass wir die uns mitgeteilten Kontaktdaten
          ohne Ihre Einwilligung nicht erstmalig an die Messenger übermitteln.
        </p>
        <p>
          Widerruf, Widerspruch und Löschung: Sie können jederzeit eine erteilte
          Einwilligung widerrufen und der Kommunikation mit uns via Messenger
          jederzeit widersprechen. Im Fall der Kommunikation via Messenger
          löschen wir die Nachrichten entsprechend unseren generellen
          Löschrichtlinien (d.h. z.B., wie oben beschrieben, nach Ende
          vertraglicher Beziehungen, im Kontext von Archivierungsvorgaben etc.)
          und sonst, sobald wir davon ausgehen können, etwaige Auskünfte der
          Kommunikationspartner beantwortet zu haben, wenn kein Rückbezug auf
          eine vorhergehende Konversation zu erwarten ist und der Löschung keine
          gesetzlichen Aufbewahrungspflichten entgegenstehen.
        </p>
        <p>
          Vorbehalt des Verweises auf andere Kommunikationswege: Zum Abschluss
          möchten wir darauf hinweisen, dass wir uns aus Gründen Ihrer
          Sicherheit vorbehalten, Anfragen über Messenger nicht zu beantworten.
          Das ist der Fall, wenn z.B. Vertragsinterna besonderer Geheimhaltung
          bedürfen oder eine Antwort über Messenger den formellen Ansprüchen
          nicht genügt. In solchen Fällen verweisen wir Sie auf adäquatere
          Kommunikationswege.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Kontaktdaten (z.B. E-Mail,
              Telefonnummern), Nutzungsdaten (z.B. besuchte Webseiten, Interesse
              an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen), Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos).
            </p>
          </li>
          <li>
            <p>Betroffene Personen: Kommunikationspartner.</p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation,
              Direktmarketing (z.B. per E-Mail oder postalisch).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Facebook-Messenger: Facebook-Messenger mit
              Ende-zu-Ende-Verschlüsselung (die Ende-zu-Ende-Verschlüsselung des
              Facebook-Messengers setzt eine Aktivierung voraus, sofern sie
              nicht standardmäßig aktiviert sein sollte); Dienstanbieter:
              https://www.facebook.com, Facebook Ireland Ltd., 4 Grand Canal
              Square, Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen:
              Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:
              https://www.facebook.com; Datenschutzerklärung:
              https://www.facebook.com/about/privacy; Widerspruchsmöglichkeit
              (Opt-Out): https://www.facebook.com/settings?tab=ads.
            </p>
          </li>
          <li>
            <p>
              WhatsApp: WhatsApp Messenger mit Ende-zu-Ende-Verschlüsselung;
              Dienstanbieter: WhatsApp Inc. WhatsApp Legal 1601 Willow Road
              Menlo Park, California 94025, USA; Website:
              https://www.whatsapp.com/; Datenschutzerklärung:
              https://www.whatsapp.com/legal.
            </p>
          </li>
        </ul>
        <h2>Cloud-Dienste</h2>
        <p>
          Wir nutzen über das Internet zugängliche und auf den Servern ihrer
          Anbieter ausgeführte Softwaredienste (sogenannte "Cloud-Dienste", auch
          bezeichnet als "Software as a Service") für die folgenden Zwecke:
          Dokumentenspeicherung und Verwaltung, Kalenderverwaltung,
          E-Mail-Versand, Tabellenkalkulationen und Präsentationen, Austausch
          von Dokumenten, Inhalten und Informationen mit bestimmten Empfängern
          oder Veröffentlichung von Webseiten, Formularen oder sonstigen
          Inhalten und Informationen sowie Chats und Teilnahme an Audio- und
          Videokonferenzen.
        </p>
        <p>
          In diesem Rahmen können personenbezogenen Daten verarbeitet und auf
          den Servern der Anbieter gespeichert werden, soweit diese Bestandteil
          von Kommunikationsvorgängen mit uns sind oder von uns sonst, wie im
          Rahmen dieser Datenschutzerklärung dargelegt, verarbeitet werden. Zu
          diesen Daten können insbesondere Stammdaten und Kontaktdaten der
          Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren
          Inhalte gehören. Die Anbieter der Cloud-Dienste verarbeiten ferner
          Nutzungsdaten und Metadaten, die von ihnen zu Sicherheitszwecken und
          zur Serviceoptimierung verwendet werden.
        </p>
        <p>
          Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder
          öffentlich zugängliche Webseiten Formulare o.a. Dokumente und Inhalte
          bereitstellen, können die Anbieter Cookies auf den Geräten der Nutzer
          für Zwecke der Webanalyse oder, um sich Einstellungen der Nutzer (z.B.
          im Fall der Mediensteuerung) zu merken, speichern.
        </p>
        <p>
          Hinweise zu Rechtsgrundlagen: Sofern wir um eine Einwilligung in den
          Einsatz der Cloud-Dienste bitten, ist die Rechtsgrundlage der
          Verarbeitung die Einwilligung. Ferner kann deren Einsatz ein
          Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der
          Einsatz der Cloud-Dienste in diesem Rahmen vereinbart wurde. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h., Interesse an effizienten und sicheren Verwaltungs-
          und Kollaborationsprozessen) verarbeitet
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Kunden, Beschäftigte (z.B. Angestellte,
              Bewerber, ehemalige Mitarbeiter), Interessenten,
              Kommunikationspartner.
            </p>
          </li>
          <li>
            <p>Zwecke der Verarbeitung: Büro- und Organisationsverfahren.</p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Google Cloud-Dienste: Cloud-Speicher-Dienste; Dienstanbieter:
              Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
              Mountain View, CA 94043, USA; Website: https://cloud.google.com/;
              Datenschutzerklärung: https://www.google.com/policies/privacy,
              Sicherheitshinweise: https://cloud.google.com/security/privacy;
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei
              Verarbeitung in Drittländern):
              https://cloud.google.com/terms/data-processing-terms; Zusätzliche
              Hinweise zum Datenschutz:
              https://cloud.google.com/terms/data-processing-terms.
            </p>
          </li>
        </ul>
        <h2>Gewinnspiele und Wettbewerbe</h2>
        <p>
          Wir verarbeiten personenbezogene Daten der Teilnehmer von
          Gewinnspielen und Wettbewerben nur unter Einhaltung der einschlägigen
          Datenschutzbestimmungen, soweit die Verarbeitung zur Bereitstellung,
          Durchführung und Abwicklung des Gewinnspiels vertraglich erforderlich
          ist, die Teilnehmer in die Verarbeitung eingewilligt haben oder die
          Verarbeitung unseren berechtigten Interessen dient (z.B. an der
          Sicherheit des Gewinnspiels oder dem Schutz unserer Interessen vor
          Missbrauch durch mögliche Erfassung von IP-Adressen bei Einreichung
          von Gewinnspielbeiträgen).
        </p>
        <p>
          Falls im Rahmen der Gewinnspiele Beiträge der Teilnehmer
          veröffentlicht werden (z.B. im Rahmen einer Abstimmung oder
          Präsentation der Gewinnspielbeiträge bzw. der Gewinner oder der
          Berichterstattung zum Gewinnspiel), weisen wir darauf hin, dass die
          Namen der Teilnehmer in diesem Zusammenhang ebenfalls veröffentlicht
          werden können. Die Teilnehmer können dem jederzeit widersprechen.
        </p>
        <p>
          Findet das Gewinnspiel innerhalb einer Online-Plattform oder eines
          sozialen Netzwerks (z.B. Facebook oder Instagram, nachfolgend
          bezeichnet als "Online-Plattform“) statt, gelten zusätzlich die
          Nutzungs- und Datenschutzbestimmungen der jeweiligen Plattformen. In
          diesen Fällen weisen wir darauf hin, dass wir für die im Rahmen des
          Gewinnspiels mitgeteilten Angaben der Teilnehmer verantwortlich sind
          und Anfragen im Hinblick auf das Gewinnspiel an uns zu richten sind.
        </p>
        <p>
          Die Daten der Teilnehmer werden gelöscht, sobald das Gewinnspiel oder
          der Wettbewerb beendet sind und die Daten nicht mehr erforderlich
          sind, um die Gewinner zu informieren oder weil mit Rückfragen zum
          Gewinnspiel zu rechnen ist. Grundsätzlich werden die Daten der
          Teilnehmer spätestens 6 Monate nach Ende des Gewinnspiels gelöscht.
          Daten der Gewinner können länger einbehalten werden, um z.B.
          Rückfragen zu den Gewinnen beantworten oder die Gewinnleistungen
          erfüllen zu können; in diesem Fall richtet sich die Aufbewahrungsdauer
          nach der Art des Gewinns und beträgt z.B. bei Sachen oder Leistungen
          bis zu drei Jahre, um z.B. Gewährleistungsfälle bearbeiten zu können.
          Ferner können die Daten der Teilnehmer länger gespeichert werden, z.B.
          in Form der Berichterstattung zum Gewinnspiel in Online- und
          Offline-Medien.
        </p>
        <p>
          Sofern Daten im Rahmen des Gewinnspiels auch zu anderen Zwecken
          erhoben wurden, richten sich deren Verarbeitung und die
          Aufbewahrungsdauer nach den Datenschutzhinweisen zu dieser Nutzung
          (z.B. im Fall einer Anmeldung zum Newsletter im Rahmen eines
          Gewinnspiels).
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
            </p>
          </li>
          <li>
            <p>Betroffene Personen: Gewinnspiel- und Wettbewerbsteilnehmer.</p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Durchführung von Gewinnspielen und
              Wettbewerben.
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen
              (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
            </p>
          </li>
        </ul>
        <h2>Umfragen und Befragungen</h2>
        <p>
          Die von uns durchgeführten Umfragen und Befragungen (nachfolgend
          "Befragungen") werden anonym ausgewertet. Eine Verarbeitung
          personenbezogener Daten erfolgt nur insoweit, als dies zu
          Bereitstellung und technischen Durchführung der Umfragen erforderlich
          ist (z.B. Verarbeitung der IP-Adresse, um die Umfrage im Browser des
          Nutzers darzustellen oder mithilfe eines temporären Cookies
          (Session-Cookie) eine Wiederaufnahme der Umfrage zu ermöglichen) oder
          Nutzer eingewilligt haben.
        </p>
        <p>
          Hinweise zu Rechtsgrundlagen: Sofern wir die Teilnehmer um eine
          Einwilligung in die Verarbeitung iherer Daten bitten, ist diese
          Rechtsgrundlage der Verarbeitung, ansonsten erfolgt die Verarbeitung
          der Daten der Teilnehmer auf Grundlage unserer berechtigten Interessen
          an der Durchführung einer objektiven Umfrage.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Kontaktdaten (z.B. E-Mail,
              Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien,
              Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
              Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
              Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Kommunikationspartner, Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation,
              Direktmarketing (z.B. per E-Mail oder postalisch), Tracking (z.B.
              interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
              Feedback (z.B. Sammeln von Feedback via Online-Formular).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Google-Formular: Google-Cloud-Formulare; Dienstanbieter: Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA; Website: https://firebase.google.com;
              Datenschutzerklärung: https://policies.google.com/privacy;
              Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
              https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für
              die Darstellung von Werbeeinblendungen:
              https://adssettings.google.com/authenticated.
            </p>
          </li>
        </ul>
        <h2>Webanalyse, Monitoring und Optimierung</h2>
        <p>
          Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
          Auswertung der Besucherströme unseres Onlineangebotes und kann
          Verhalten, Interessen oder demographische Informationen zu den
          Besuchern, wie z.B. das Alter oder das Geschlecht, als pseudonyme
          Werte umfassen. Mit Hilfe der Reichweitenanalyse können wir z.B.
          erkennen, zu welcher Zeit unser Onlineangebot oder dessen Funktionen
          oder Inhalte am häufigsten genutzt werden oder zur Wiederverwendung
          einladen. Ebenso können wir nachvollziehen, welche Bereiche der
          Optimierung bedürfen.{" "}
        </p>
        <p>
          Neben der Webanalyse können wir auch Testverfahren einsetzen, um z.B.
          unterschiedliche Versionen unseres Onlineangebotes oder seiner
          Bestandteile zu testen und optimieren.
        </p>
        <p>
          Zu diesen Zwecken können sogenannte Nutzerprofile angelegt und in
          einer Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren
          mit dem gleichen Zweck genutzt werden. Zu diesen Angaben können z.B.
          betrachtete Inhalte, besuchte Webseiten und dort genutzte Elemente und
          technische Angaben, wie der verwendete Browser, das verwendete
          Computersystem sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer
          in die Erhebung ihrer Standortdaten eingewilligt haben, können je nach
          Anbieter auch diese verarbeitet werden.
        </p>
        <p>
          Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
          nutzen wir ein IP-Masking-Verfahren (d.h., Pseudonymisierung durch
          Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im
          Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten
          der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern
          Pseudonyme. D.h., wir als auch die Anbieter der eingesetzten Software
          kennen nicht die tatsächliche Identität der Nutzer, sondern nur den
          für Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten
          Angaben.
        </p>
        <p>
          Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
          Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <p>
          Google Tag Manager: Sie sollten dieses Modul auswählen, wenn Sie den
          Google Tag Manager im Zusammenhang mit Marketingzwecken einsetzen (z.
          B. um Zielgruppen zu bilden oder um Konversionen nachzuverfolgen).
          Falls Sie den Google Tag Manager ausschließlich im Zusammenhang mit
          der Reichweitenmessung (also Messung von Besucherströmen innerhalb
          Ihres Onlineangebotes) einsetzen, dann empfehlen wir Ihnen die Auswahl
          des Google Tag Managers in der Gruppe "Onlinemarketing und Vermarktung
          von Onlinewerbeflächen".
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Reichweitenmessung (z.B.
              Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking
              (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von
              Cookies), Besuchsaktionsauswertung, Profiling (Erstellen von
              Nutzerprofilen).
            </p>
          </li>
          <li>
            <p>
              Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der
              IP-Adresse).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Google Analytics: Reichweitenmessung und Webanalyse;
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://marketingplatform.google.com/intl/de/about/analytics/;
              Datenschutzerklärung: https://policies.google.com/privacy.
            </p>
          </li>
          <li>
            <p>
              Google Tag Manager: Google Tag Manager ist eine Lösung, mit der
              wir sog. Website-Tags über eine Oberfläche verwalten und so andere
              Dienste in unser Onlineangebot einbinden können (hierzu wird auf
              weitere Angaben in dieser Datenschutzerklärung verwiesen). Mit dem
              Tag Manager selbst (welches die Tags implementiert) werden daher
              z. B. noch keine Profile der Nutzer erstellt oder Cookies
              gespeichert. Google erfährt lediglich die IP-Adresse des Nutzers,
              was notwendig ist, um den Google Tag Manager auszuführen.
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://marketingplatform.google.com; Datenschutzerklärung:
              https://policies.google.com/privacy.
            </p>
          </li>
        </ul>
        <p></p>
        <h2>Onlinemarketing</h2>
        <p>
          Wir verarbeiten personenbezogene Daten zu Zwecken des
          Onlinemarketings, worunter insbesondere die Vermarktung von
          Werbeflächen oder Darstellung von werbenden und sonstigen Inhalten
          (zusammenfassend als "Inhalte" bezeichnet) anhand potentieller
          Interessen der Nutzer sowie die Messung ihrer Effektivität fallen
          kann.{" "}
        </p>
        <p>
          Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
          einer Datei (sogenannte "Cookie") gespeichert oder ähnliche Verfahren
          genutzt, mittels derer die für die Darstellung der vorgenannten
          Inhalte relevante Angaben zum Nutzer gespeichert werden. Zu diesen
          Angaben können z.B. betrachtete Inhalte, besuchte Webseiten, genutzte
          Onlinenetzwerke, aber auch Kommunikationspartner und technische
          Angaben, wie der verwendete Browser, das verwendete Computersystem
          sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer in die Erhebung
          ihrer Standortdaten eingewilligt haben, können auch diese verarbeitet
          werden.
        </p>
        <p>
          Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
          nutzen wir zur Verfügung stehende IP-Masking-Verfahren (d.h.,
          Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der Nutzer.
          Generell werden im Rahmen des Onlinemarketingverfahren keine Klardaten
          der Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern
          Pseudonyme. D.h., wir als auch die Anbieter der
          Onlinemarketingverfahren kennen nicht die tatsächlich Identität der
          Nutzer, sondern nur die in deren Profilen gespeicherten Angaben.
        </p>
        <p>
          Die Angaben in den Profilen werden im Regelfall in den Cookies oder
          mittels ähnlicher Verfahren gespeichert. Diese Cookies können später
          generell auch auf anderen Webseiten die dasselbe
          Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
          Darstellung von Inhalten analysiert als auch mit weiteren Daten
          ergänzt und auf dem Server des Onlinemarketingverfahrensanbieters
          gespeichert werden.
        </p>
        <p>
          Ausnahmsweise können Klardaten den Profilen zugeordnet werden. Das ist
          der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen Netzwerks
          sind, dessen Onlinemarketingverfahren wir einsetzen und das Netzwerk
          die Profile der Nutzer mit den vorgenannten Angaben verbindet. Wir
          bitten darum, zu beachten, dass Nutzer mit den Anbietern zusätzliche
          Abreden, z.B. durch Einwilligung im Rahmen der Registrierung, treffen
          können.
        </p>
        <p>
          Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
          Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können wir
          im Rahmen sogenannter Konversionsmessungen prüfen, welche unserer
          Onlinemarketingverfahren zu einer sogenannten Konversion geführt
          haben, d.h. z.B., zu einem Vertragsschluss mit uns. Die
          Konversionsmessung wird alleine zur Analyse des Erfolgs unserer
          Marketingmaßnahmen verwendet.
        </p>
        <p>
          Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
          verwendete Cookies für einen Zeitraum von zwei Jahren gespeichert
          werden.
        </p>
        <p>
          Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
          Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <p>
          Zielgruppenbildung mit Google Analytics: Wir setzen Google Analytics
          ein, um die durch innerhalb von Werbediensten Googles und seiner
          Partner geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die
          auch ein Interesse an unserem Onlineangebot gezeigt haben oder die
          bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder
          Produkten, die anhand der besuchten Webseiten bestimmt werden)
          aufweisen, die wir an Google übermitteln (sog. "Remarketing-“, bzw.
          "Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences
          möchten wir auch sicherstellen, dass unsere Anzeigen dem potentiellen
          Interesse der Nutzer entsprechen
        </p>
        <p>
          Facebook-Pixel: Mit Hilfe des Facebook-Pixels ist es Facebook zum
          einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für
          die Darstellung von Anzeigen (sogenannte "Facebook-Ads") zu bestimmen.
          Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns
          geschalteten Facebook-Ads nur solchen Nutzern bei Facebook und
          innerhalb der Dienste der mit Facebook kooperierenden Partner (so
          genanntes "Audience Network" https://www.facebook.com/audiencenetwork/
          ) anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt
          haben oder die bestimmte Merkmale (z.B. Interesse an bestimmten Themen
          oder Produkten, die anhand der besuchten Webseiten ersichtlich werden)
          aufweisen, die wir an Facebook übermitteln (sogenannte "Custom
          Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch
          sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der
          Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des
          Facebook-Pixels können wir ferner die Wirksamkeit der
          Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
          nachvollziehen, indem wir sehen, ob Nutzer nach dem Klick auf eine
          Facebook-Werbeanzeige auf unsere Webseite weitergeleitet wurden
          (sogenannte "Konversionsmessung“).
        </p>
        <p>
          Erweiterter Abgleich für das Facebook-Pixel: Beim Einsatz des
          Facebook-Pixels wird die Zusatzfunktion "erweiterter Abgleich“
          eingesetzt. In diesem Zusammenhang werden Daten, wie z.B.
          E-Mail-Adressen oder Facebook-IDs der Nutzer, zur Bildung von
          Zielgruppen (verschlüsselt) an Facebook übermittelt.
        </p>
        <p>
          Facebook - Zielgruppen Bildung via Datenupload: Hochladen von Daten,
          wie z.B. Telefonnummern, E-Mail-Adressen oder Facebook-IDs bei der
          Plattform Facebook. Die Daten werden hierbei verschlüsselt. Der
          Hochladevorgang dient nur dazu, um den Inhabern der Daten oder
          Personen, deren Nutzerprofilen etwaigen Nutzerprofilen der Inhaber der
          Daten bei Facebook entsprechen, Werbeanzeigen anzuzeigen. Wir möchten
          damit sicherstellen, dass die Anzeigen nur Nutzern angezeigt werden,
          die ein Interesse an unseren Informationen und Leistungen haben.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Daten,
              die den Standort des Endgeräts eines Endnutzers angeben),
              Sozialdaten (Daten, die dem Sozialgeheimnis (§ 35 SGB I)
              unterliegen und z.B. von Sozialversicherungsträgern,
              Sozialhilfeträger oder Versorgungsbehörden verarbeitet werden.).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten), Interessenten, Kunden, Beschäftigte (z.B.
              Angestellte, Bewerber, ehemalige Mitarbeiter),
              Kommunikationspartner.
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Tracking (z.B.
              interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
              Remarketing, Besuchsaktionsauswertung, Interessenbasiertes und
              verhaltensbezogenes Marketing, Profiling (Erstellen von
              Nutzerprofilen), Reichweitenmessung (z.B. Zugriffsstatistiken,
              Erkennung wiederkehrender Besucher), Zielgruppenbildung
              (Bestimmung von für Marketingzwecke relevanten Zielgruppen oder
              sonstige Ausgabe von Inhalten), Cross-Device Tracking
              (geräteübergreifende Verarbeitung von Nutzerdaten für
              Marketingzwecke), Klickverfolgung.
            </p>
          </li>
          <li>
            <p>
              Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der
              IP-Adresse).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
            </p>
          </li>
          <li>
            <p>
              Widerspruchsmöglichkeit (Opt-Out): Wir verweisen auf die
              Datenschutzhinweise der jeweiligen Anbieter und die zu den
              Anbietern angegebenen Widerspruchsmöglichkeiten (sog. "Opt-Out").
              Sofern keine explizite Opt-Out-Möglichkeit angegeben wurde,
              besteht zum einen die Möglichkeit, dass Sie Cookies in den
              Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch
              Funktionen unseres Onlineangebotes eingeschränkt werden. Wir
              empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten,
              die zusammenfassend auf jeweilige Gebiete gerichtet angeboten
              werden:a) Europa: https://www.youronlinechoices.eu. b) Kanada:
              https://www.youradchoices.ca/choices. c) USA:
              https://www.aboutads.info/choices. d) Gebietsübergreifend:
              https://optout.aboutads.info.
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Google Tag Manager: Google Tag Manager ist eine Lösung, mit der
              wir sog. Website-Tags über eine Oberfläche verwalten und so andere
              Dienste in unser Onlineangebot einbinden können (hierzu wird auf
              weitere Angaben in dieser Datenschutzerklärung verwiesen). Mit dem
              Tag Manager selbst (welches die Tags implementiert) werden daher
              z. B. noch keine Profile der Nutzer erstellt oder Cookies
              gespeichert. Google erfährt lediglich die IP-Adresse des Nutzers,
              was notwendig ist, um den Google Tag Manager auszuführen.
              Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
              Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
              https://marketingplatform.google.com; Datenschutzerklärung:
              https://policies.google.com/privacy.
            </p>
          </li>
          <li>
            <p>
              Google Analytics: Onlinemarketing und Webanalyse; Dienstanbieter:
              Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
              Mountain View, CA 94043, USA; Website:
              https://marketingplatform.google.com/intl/de/about/analytics/;
              Datenschutzerklärung: https://policies.google.com/privacy;
              Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
              https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für
              die Darstellung von Werbeeinblendungen:
              https://adssettings.google.com/authenticated.
            </p>
          </li>
          <li>
            <p>
              Google Ads und Konversionsmessung: Wir nutzen das
              Onlinemarketingverfahren "Google Ads", um Anzeigen im
              Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in
              Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt werden,
              die ein mutmaßliches Interesse an den Anzeigen haben. Ferner
              messen wir die Konversion der Anzeigen. Wir erfahren jedoch nur
              die anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige
              geklickt haben und zu einer mit einem sog
              "Conversion-Tracking-Tag" versehenen Seite weitergeleitet wurden.
              Wir selbst erhalten jedoch keine Informationen, mit denen sich
              Nutzer identifizieren lassen. Dienstanbieter: Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent
              company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA; Website: https://marketingplatform.google.com;
              Datenschutzerklärung: https://policies.google.com/privacy.
            </p>
          </li>
          <li>
            <p>
              Facebook-Pixel: Dienstanbieter: https://www.facebook.com, Facebook
              Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
              Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA
              94025, USA; Website: https://www.facebook.com;
              Datenschutzerklärung: https://www.facebook.com/about/privacy;
              Widerspruchsmöglichkeit (Opt-Out):
              https://www.facebook.com/settings?tab=ads; Zusätzliche Hinweise
              zum Datenschutz: Vereinbarung über gemeinsame Verarbeitung
              personenbezogener Daten mit dem Facebook-Pixel (und anderen
              Business-Tools von Facebook):
              https://www.facebook.com/legal/controller_addendum..
            </p>
          </li>
        </ul>
        <h2>Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>
          Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
          verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven
          Nutzern zu kommunizieren oder um Informationen über uns anzubieten.
        </p>
        <p>
          Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
          Raumes der Europäischen Union verarbeitet werden können. Hierdurch
          können sich für die Nutzer Risiken ergeben, weil so z.B. die
          Durchsetzung der Rechte der Nutzer erschwert werden könnte.
        </p>
        <p>
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
          z.B. anhand des Nutzungsverhaltens und sich daraus ergebender
          Interessen der Nutzer Nutzungsprofile erstellt werden. Die
          Nutzungsprofile können wiederum verwendet werden, um z.B.
          Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
          mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken
          werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert,
          in denen das Nutzungsverhalten und die Interessen der Nutzer
          gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten
          unabhängig der von den Nutzern verwendeten Geräte gespeichert werden
          (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen
          sind und bei diesen eingeloggt sind).
        </p>
        <p>
          Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen
          und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die
          Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
          Netzwerke.
        </p>
        <p>
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden können. Nur die Anbieter
          haben jeweils Zugriff auf die Daten der Nutzer und können direkt
          entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
          dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation,
              Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung
              von Cookies), Remarketing, Reichweitenmessung (z.B.
              Zugriffsstatistiken, Erkennung wiederkehrender Besucher).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f. DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Instagram : Soziales Netzwerk; Dienstanbieter: Instagram Inc.,
              1601 Willow Road, Menlo Park, CA, 94025, USA; Website:
              https://www.instagram.com; Datenschutzerklärung:
              https://instagram.com/about/legal/privacy.
            </p>
          </li>
          <li>
            <p>
              Facebook: Soziales Netzwerk; Dienstanbieter: Facebook Ireland
              Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland,
              Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025,
              USA; Website: https://www.facebook.com; Datenschutzerklärung:
              https://www.facebook.com/about/privacy; Widerspruchsmöglichkeit
              (Opt-Out): Einstellungen für Werbeanzeigen:
              https://www.facebook.com/settings?tab=ads; Zusätzliche Hinweise
              zum Datenschutz: Vereinbarung über gemeinsame Verarbeitung
              personenbezogener Daten auf Facebook-Seiten:
              https://www.facebook.com/legal/terms/page_controller_addendum,
              Datenschutzhinweise für Facebook-Seiten:
              https://www.facebook.com/legal/terms/information_about_page_insights_data;
              Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten
              mit Business-Tools von Facebook:
              https://www.facebook.com/legal/controller_addendum..
            </p>
          </li>
          <li>
            <p>
              LinkedIn: Soziales Netzwerk; Dienstanbieter: LinkedIn Ireland
              Unlimited Company, Wilton Place, Dublin 2, Irland; Website:
              https://www.linkedin.com; Datenschutzerklärung:
              https://www.linkedin.com/legal/privacy-policy;
              Widerspruchsmöglichkeit (Opt-Out):
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
            </p>
          </li>
          <li>
            <p>
              YouTube: Soziales Netzwerk; Dienstanbieter: Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA; Datenschutzerklärung:
              https://policies.google.com/privacy; Widerspruchsmöglichkeit
              (Opt-Out): https://adssettings.google.com/authenticated.
            </p>
          </li>
        </ul>
        <h2>Plugins und eingebettete Funktionen sowie Inhalte</h2>
        <p>
          Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,
          die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet
          als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel
          um Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge
          handeln (nachfolgend einheitlich bezeichnet als "Inhalte”).
        </p>
        <p>
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
          Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
          IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
          IP-Adresse ist damit für die Darstellung dieser Inhalte oder
          Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
          verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
          Auslieferung der Inhalte verwenden. Drittanbieter können ferner
          sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
          bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
          "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
          Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
          Informationen können ferner in Cookies auf dem Gerät der Nutzer
          gespeichert werden und unter anderem technische Informationen zum
          Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
          Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
          enthalten als auch mit solchen Informationen aus anderen Quellen
          verbunden werden.
        </p>
        <p>
          Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
          Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
              Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
              (z.B. Geräte-Informationen, IP-Adressen), Bestandsdaten (z.B.
              Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
              Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes
              und Nutzerfreundlichkeit, Vertragliche Leistungen und Service,
              Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </p>
          </li>
        </ul>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Font Awesome: Darstellung von Schriftarten und Symbolen;
              Dienstanbieter: Fonticons, Inc. ,6 Porter Road Apartment 3R,
              Cambridge, MA 02140, USA; Website: https://fontawesome.com/;
              Datenschutzerklärung: https://fontawesome.com/privacy.
            </p>
          </li>
          <li>
            <p>
              YouTube-Videos: Videoinhalte; Dienstanbieter: Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Irland,
              Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA; Website: https://www.youtube.com;
              Datenschutzerklärung: https://policies.google.com/privacy;
              Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
              https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für
              die Darstellung von Werbeeinblendungen:
              https://adssettings.google.com/authenticated.
            </p>
          </li>
        </ul>
        <h2>Planung, Organisation und Hilfswerkzeuge</h2>
        <p>
          Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter
          (nachfolgend bezeichnet als "Drittanbieter”) zu Zwecken der
          Organisation, Verwaltung, Planung sowie Erbringung unserer Leistungen
          ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten
          wir die gesetzlichen Vorgaben.{" "}
        </p>
        <p>
          In diesem Rahmen können personenbezogenen Daten verarbeitet und auf
          den Servern der Drittanbieter gespeichert werden. Hiervon können
          diverse Daten betroffen sein, die wir entsprechend dieser
          Datenschutzerklärung verarbeiten. Zu diesen Daten können insbesondere
          Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen,
          sonstigen Prozessen und deren Inhalte gehören.
        </p>
        <p>
          Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen
          Beziehungen mit uns auf die Drittanbieter bzw. deren Software oder
          Plattformen verwiesen werden, können die Drittanbieter Nutzungsdaten
          und Metadaten zu Sicherheitszwecken, zur Serviceoptimierung oder zu
          Marketingzwecken verarbeiten. Wir bitten daher darum, die
          Datenschutzhinweise der jeweiligen Drittanbieter zu beachten.
        </p>
        <p>
          Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
          Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ferner
          kann deren Einsatz ein Bestandteil unserer (vor)vertraglichen
          Leistungen sein, sofern der Einsatz der Drittanbieter in diesem Rahmen
          vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage
          unserer berechtigten Interessen (d.h. Interesse an effizienten,
          wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In
          diesem Zusammenhang möchten wir Sie auch auf die Informationen zur
          Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
        </p>
        <ul>
          <li>
            <p>
              Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
              Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
              Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
              Webseiten, Interesse an Inhalten, Zugriffszeiten),
              Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
              IP-Adressen).
            </p>
          </li>
          <li>
            <p>
              Betroffene Personen: Kommunikationspartner, Nutzer (z.B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </p>
          </li>
          <li>
            <p>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
              DSGVO).
            </p>
          </li>
        </ul>
        <p></p>
        <p>Eingesetzte Dienste und Diensteanbieter:</p>
        <ul>
          <li>
            <p>
              Google Cloud: Wir verwenden Google Kubernetes zur Speicherung der
              Nutzerdaten und zum Hosten der Datenbanken. Außerdem werden Mails
              über Google versendet. Dienstanbieter: Google Ireland Limited,
              Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
              Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
              USA; Website: https://cloud.google.com/?hl=de
              Datenschutzerklärung: https://policies.google.com/privacy.
            </p>
          </li>
        </ul>
        <h2>Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
          Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
          Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
          (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
          sie für den Zweck nicht erforderlich sind).
        </p>
        <p>
          Sofern die Daten nicht gelöscht werden, weil sie für andere und
          gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
          auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht
          für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
          handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder
          deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
          oder juristischen Person erforderlich ist.
        </p>
        <p>
          Weitere Hinweise zu der Löschung von personenbezogenen Daten können
          ferner im Rahmen der einzelnen Datenschutzhinweise dieser
          Datenschutzerklärung erfolgen.
        </p>
        <h2>Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
          können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
        </p>
        <h2>Rechte der betroffenen Personen</h2>
        <p>
          Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die
          sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <p>
              Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
              personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.
            </p>
          </li>
          <li>
            <p>
              Widerrufsrecht bei Einwilligungen: Sie haben das Recht, erteilte
              Einwilligungen jederzeit zu widerrufen.
            </p>
          </li>
          <li>
            <p>
              Auskunftsrecht: Sie haben das Recht, eine Bestätigung darüber zu
              verlangen, ob betreffende Daten verarbeitet werden und auf
              Auskunft über diese Daten sowie auf weitere Informationen und
              Kopie der Daten entsprechend den gesetzlichen Vorgaben.
            </p>
          </li>
          <li>
            <p>
              Recht auf Berichtigung: Sie haben entsprechend den gesetzlichen
              Vorgaben das Recht, die Vervollständigung der Sie betreffenden
              Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten
              zu verlangen.
            </p>
          </li>
          <li>
            <p>
              Recht auf Löschung und Einschränkung der Verarbeitung: Sie haben
              nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen,
              dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
              alternativ nach Maßgabe der gesetzlichen Vorgaben eine
              Einschränkung der Verarbeitung der Daten zu verlangen.
            </p>
          </li>
          <li>
            <p>
              Recht auf Datenübertragbarkeit: Sie haben das Recht, Sie
              betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
              der gesetzlichen Vorgaben in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten oder deren Übermittlung an
              einen anderen Verantwortlichen zu fordern.
            </p>
          </li>
          <li>
            <p>
              Beschwerde bei Aufsichtsbehörde: Sie haben ferner nach Maßgabe der
              gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde,
              insbesondere in dem Mitgliedstaat Ihres gewöhnlichen
              Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des
              mutmaßlichen Verstoßes Beschwerde einzulegen, wenn Sie der Ansicht
              sind, dass die Verarbeitung der Sie betreffenden personenbezogenen
              Daten gegen die DSGVO verstößt.
            </p>
          </li>
        </ul>
        <p>Für uns zuständige Aufsichtsbehörde: </p>
        <p>
          Helga Block
          <br />
          <br />
          Postfach 20 04 44
          <br />
          40102 Düsseldorf
          <br />
          <br />
          Kavalleriestraße 2-4
          <br />
          40213 Düsseldorf
          <br />
          <br />
          <br />
          Telefon: 02 11/384 24-0
          <br />
          Telefax: 02 11/384 24-10
          <br />
          <br />
          <br />
          E-Mail: poststelle@ldi.nrw.de
          <br />
          <br />
          Homepage: https://www.ldi.nrw.de
        </p>
        <h2>Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
          sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
          gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
          Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
          Begriffe sind alphabetisch sortiert.
        </p>
        <p></p>
        <ul>
          <li>
            <p>
              Affiliate-Nachverfolgung: Im Rahmen der Affiliate-Nachverfolgung
              werden Links, mit deren Hilfe die verlinkenden Webseiten Nutzer zu
              Webseiten mit Produkt- oder sonstigen Angeboten verweisen,
              protokolliert. Die Betreiber der jeweils verlinkenden Webseiten
              können eine Provision erhalten, wenn Nutzer diesen sogenannten
              Affiliate-Links folgen und anschließend die Angebote wahrnehmen
              (z.B. Waren kaufen oder Dienstleistungen in Anspruch nehmen).
              Hierzu ist es erforderlich, dass die Anbieter nachverfolgen
              können, ob Nutzer, die sich für bestimmte Angebote interessieren,
              diese anschließend auf die Veranlassung der Affiliate-Links
              wahrnehmen. Daher ist es für die Funktionsfähigkeit von
              Affiliate-Links erforderlich, dass sie um bestimmte Werte ergänzt
              werden, die ein Bestandteil des Links werden oder anderweitig,
              z.B. in einem Cookie, gespeichert werden. Zu den Werten gehören
              insbesondere die Ausgangswebseite (Referrer), der Zeitpunkt, eine
              Online-Kennung der Betreiber der Webseite, auf der sich der
              Affiliate-Link befand, eine Online-Kennung des jeweiligen
              Angebotes, eine Online-Kennung des Nutzers als auch
              nachverfolgungsspezifische Werte, wie, z.B. Werbemittel-ID,
              Partner-ID und Kategorisierungen.{" "}
            </p>
          </li>
          <li>
            <p>
              Besuchsaktionsauswertung: "Besuchsaktionsauswertung" (englisch
              "Conversion Tracking") bezeichnet ein Verfahren, mit dem die
              Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu
              wird im Regelfall ein Cookie auf den Geräten der Nutzer innerhalb
              der Webseiten, auf denen die Marketingmaßnahmen erfolgen,
              gespeichert und dann erneut auf der Zielwebseite abgerufen.
              Beispielsweise können wir so nachvollziehen, ob die von uns auf
              anderen Webseiten geschalteten Anzeigen erfolgreich waren).{" "}
            </p>
          </li>
          <li>
            <p>
              Content Delivery Network (CDN): Ein "Content Delivery Network"
              (CDN) ist ein Dienst, mit dessen Hilfe Inhalte eines
              Onlineangebotes, insbesondere große Mediendateien, wie Grafiken
              oder Programm-Skripte mit Hilfe regional verteilter und über das
              Internet verbundener Server, schneller und sicherer ausgeliefert
              werden können.{" "}
            </p>
          </li>
          <li>
            <p>
              Cross-Device Tracking: Das Cross-Device Tracking ist eine Form des
              Trackings, bei der Verhaltens- und Interessensinformationen der
              Nutzer geräteübergreifend in sogenannten Profilen erfasst werden,
              indem den Nutzern eine Onlinekennung zugeordnet wird. Hierdurch
              können die Nutzerinformationen unabhängig von verwendeten Browsern
              oder Geräten (z.B. Mobiltelefonen oder Desktopcomputern) im
              Regelfall für Marketingzwecke analysiert werden. Die Onlinekennung
              ist bei den meisten Anbietern nicht mit Klardaten, wie Namen,
              Postadressen oder E-Mail-Adressen, verknüpft.{" "}
            </p>
          </li>
          <li>
            <p>
              IP-Masking: Als "IP-Masking” wird eine Methode bezeichnet, bei der
              das letzte Oktett, d.h., die letzten beiden Zahlen einer
              IP-Adresse, gelöscht wird, damit die IP-Adresse nicht mehr der
              eindeutigen Identifizierung einer Person dienen kann. Daher ist
              das IP-Masking ein Mittel zur Pseudonymisierung von
              Verarbeitungsverfahren, insbesondere im Onlinemarketing{" "}
            </p>
          </li>
          <li>
            <p>
              Interessenbasiertes und verhaltensbezogenes Marketing: Von
              interessens- und/oder verhaltensbezogenem Marketing spricht man,
              wenn potentielle Interessen von Nutzern an Anzeigen und sonstigen
              Inhalten möglichst genau vorbestimmt werden. Dies geschieht anhand
              von Angaben zu deren Vorverhalten (z.B. Aufsuchen von bestimmten
              Webseiten und Verweilen auf diesen, Kaufverhaltens oder
              Interaktion mit anderen Nutzern), die in einem sogenannten Profil
              gespeichert werden. Zu diesen Zwecken werden im Regelfall Cookies
              eingesetzt.{" "}
            </p>
          </li>
          <li>
            <p>
              Klickverfolgung: Clicktracking erlaubt, die Bewegungen der Nutzer
              innerhalb eines gesamten Onlineangebotes zu überblicken. Da die
              Ergebnisse dieser Tests genauer sind, wenn die Interaktion der
              Nutzer über einen gewissen Zeitraum verfolgt werden kann (z.B.,
              damit wir herausfinden können, ob ein Nutzer gerne wiederkehrt),
              werden für diese Test-Zwecke im Regelfall Cookies auf den Rechnern
              der Nutzer gespeichert.{" "}
            </p>
          </li>
          <li>
            <p>
              Personenbezogene Daten: "Personenbezogene Daten“ sind alle
              Informationen, die sich auf eine identifizierte oder
              identifizierbare natürliche Person (im Folgenden "betroffene
              Person“) beziehen; als identifizierbar wird eine natürliche Person
              angesehen, die direkt oder indirekt, insbesondere mittels
              Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
              zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu
              einem oder mehreren besonderen Merkmalen identifiziert werden
              kann, die Ausdruck der physischen, physiologischen, genetischen,
              psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
              dieser natürlichen Person sind.{" "}
            </p>
          </li>
          <li>
            <p>
              Profiling: Als "Profiling“ wird jede Art der automatisierten
              Verarbeitung personenbezogener Daten bezeichnet, die darin
              besteht, dass diese personenbezogenen Daten verwendet werden, um
              bestimmte persönliche Aspekte, die sich auf eine natürliche Person
              beziehen (je nach Art des Profilings gehören dazu Informationen
              betreffend das Alter, das Geschlecht, Standortdaten und
              Bewegungsdaten, Interaktion mit Webseiten und deren Inhalten,
              Einkaufsverhalten, soziale Interaktionen mit anderen Menschen) zu
              analysieren, zu bewerten oder, um sie vorherzusagen (z.B. die
              Interessen an bestimmten Inhalten oder Produkten, das
              Klickverhalten auf einer Webseite oder den Aufenthaltsort). Zu
              Zwecken des Profilings werden häufig Cookies und Web-Beacons
              eingesetzt.{" "}
            </p>
          </li>
          <li>
            <p>
              Reichweitenmessung: Die Reichweitenmessung (auch als Web Analytics
              bezeichnet) dient der Auswertung der Besucherströme eines
              Onlineangebotes und kann das Verhalten oder Interessen der
              Besucher an bestimmten Informationen, wie z.B. Inhalten von
              Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können
              Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher ihre
              Webseite besuchen und für welche Inhalte sie sich interessieren.
              Dadurch können sie z.B. die Inhalte der Webseite besser an die
              Bedürfnisse ihrer Besucher anpassen. Zu Zwecken der
              Reichweitenanalyse werden häufig pseudonyme Cookies und
              Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und
              so genauere Analysen zur Nutzung eines Onlineangebotes zu
              erhalten.{" "}
            </p>
          </li>
          <li>
            <p>
              Remarketing: Vom "Remarketing“ bzw. "Retargeting“ spricht man,
              wenn z.B. zu Werbezwecken vermerkt wird, für welche Produkte sich
              ein Nutzer auf einer Webseite interessiert hat, um den Nutzer auf
              anderen Webseiten an diese Produkte, z.B. in Werbeanzeigen, zu
              erinnern.{" "}
            </p>
          </li>
          <li>
            <p>
              Tracking: Vom "Tracking“ spricht man, wenn das Verhalten von
              Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden
              kann. Im Regelfall werden im Hinblick auf die genutzten
              Onlineangebote Verhaltens- und Interessensinformationen in Cookies
              oder auf Servern der Anbieter der Trackingtechnologien gespeichert
              (sogenanntes Profiling). Diese Informationen können anschließend
              z.B. eingesetzt werden, um den Nutzern Werbeanzeigen anzuzeigen,
              die voraussichtlich deren Interessen entsprechen.{" "}
            </p>
          </li>
          <li>
            <p>
              Verantwortlicher: Als "Verantwortlicher“ wird die natürliche oder
              juristische Person, Behörde, Einrichtung oder andere Stelle, die
              allein oder gemeinsam mit anderen über die Zwecke und Mittel der
              Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.{" "}
            </p>
          </li>
          <li>
            <p>
              Verarbeitung: "Verarbeitung" ist jeder mit oder ohne Hilfe
              automatisierter Verfahren ausgeführte Vorgang oder jede solche
              Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der
              Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten,
              sei es das Erheben, das Auswerten, das Speichern, das Übermitteln
              oder das Löschen.{" "}
            </p>
          </li>
          <li>
            <p>
              Zielgruppenbildung: Von Zielgruppenbildung (bzw. "Custom
              Audiences“) spricht man, wenn Zielgruppen für Werbezwecke, z.B.
              Einblendung von Werbeanzeigen, bestimmt werden. So kann z.B.
              anhand des Interesses eines Nutzers an bestimmten Produkten oder
              Themen im Internet geschlussfolgert werden, dass dieser Nutzer
              sich für Werbeanzeigen für ähnliche Produkte oder den Onlineshop,
              in dem er die Produkte betrachtet hat, interessiert. Von
              "Lookalike Audiences“ (bzw. ähnlichen Zielgruppen) spricht man
              wiederum, wenn die als geeignet eingeschätzten Inhalte Nutzern
              angezeigt werden, deren Profile bzw. Interessen mutmaßlich den
              Nutzern, zu denen die Profile gebildet wurden, entsprechen. Zu
              Zwecken der Bildung von Custom Audiences und Lookalike Audiences
              werden im Regelfall Cookies und Web-Beacons eingesetzt.{" "}
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Datenschutz;
