import React from "react";
export const accountQs = [
  {
    title: "Wie kann ich mein Passwort ändern?",
    content: [
      <>
        Logge dich aus und klicke auf "Passwort vergessen". Du bekommst einen
        Link per Mail zugeschickt, über den du ein neues Passwort vergeben
        kannst.
      </>,
    ],
  },
  {
    title: "Was ist, wenn ich mein Passwort vergessen habe?",
    content: [
      'Klicke im Login-Bereich auf "Passwort vergessen". Du bekommst einen Link per Mail zugeschickt, über den du ein neues Passwort vergeben kannst.',
    ],
  },
  {
    title: "Wie kann ich meine Tutoringanzeige schließen?",
    content: [
      <>
        Schreibe uns für das Schließen deiner Tutoringanzeige bitte eine Mail an{" "}
        <a href="mailto:hello@unitutors.de?subject=Tutoringanzeige schließen&body=Ich möchte gerne meine Tutoringanzeige für den Account <EMAIL HIER EINFÜGEN> schließen.">
          hello@unitutors.de
        </a>
        . Bis wir deine Anzeige schließen, kannst du deinen Status vorübergehend
        auf offline stellen.
      </>,
      <>
        <b>Wichtig:</b> Schreibe die Mail bitte von der Email-Adresse, mit der
        du bei Unitutors registriert bist.
      </>,
    ],
  },
  {
    title: "Wie kann ich meinen Account löschen?",
    content: [
      <>
        Schreibe uns für die Löschung deines Accounts bitte eine Mail an{" "}
        <a href="mailto:hello@unitutors.de ?subject=Account löschen&body=Bitte löscht meinen Acount mit der Email-Adresse <EMAIL HIER EINFÜGEN>.">
          hello@unitutors.de
        </a>
        .
      </>,
      <>
        <b>Wichtig:</b> Schreibe die Mail bitte von der Email-Adresse, mit der
        du bei Unitutors registriert bist.
      </>,
    ],
  },
];
export const studentQs = [
  {
    title: "Woher weiß ich, welcher Tutor der Richtige für mich ist?",
    content: [
      "In erster Linie kannst du dich an den Tutoringanzeigen orientieren.",
      <>
        Wenn du einen Tutor anfragst, schildere ihm dein Anliegen: Wann soll der
        Unterricht stattfinden, wann hast du Zeit, gibt es eine Deadline,
        handelt es sich um Gruppen- oder Einzelunterricht. Erkläre außerdem, in
        welchen Themen du Hilfe benötigst. Mit diesen Informationen kann sich
        der Tutor ein besseres Bild deines Anliegens verschaffen und so stellt
        ihr sicher, dass ihr zusammenpasst und am Ende zufrieden seid.
      </>,
      <>
        Bist du trotz Absprache unsicher, welcher Tutor am besten zu dir passt?
        Versuch es einfach mit mehreren Tutoren. Mit unserem Semesterabonnement
        kannst du so viele Tutoren kontaktieren wie du möchtest. Darüber hinaus
        bieten die meisten Tutoren die erste Stunde gratis an.
      </>,
      <>
        Hat letztendlich nichts geholfen und du bist unzufrieden? Das tut uns
        leid! Daher erstatten wir dir die Kosten für das Semesterabonnement.
      </>,
    ],
  },
  {
    title:
      "Wie kann ich sichergehen, dass mein Tutor auch wirklich kompetent ist?",
    content: [
      <>
        Häufig kannst du schon an der Tutoringanzeige erkennen, ob der Tutor
        geeignet ist oder nicht. Ist die Anzeige gut geschrieben? Hat der Tutor
        die benötigten Qualifikationen? Wenn alles passt, kannst du eine Anfrage
        stellen. Hierbei beschreibst du dein Anliegen um sicherzugehen, dass der
        Tutor die auch wirklich helfen kann.
      </>,
    ],
  },
  {
    title: "Was ist, wenn ich mit meinem Tutor nicht zufrieden bin?",
    content: [
      <>
        Mit dem Semesterabonnement kannst du so viele Tutoren kontaktieren, wie
        du möchtest. Außerdem bieten die meisten Tutoren die erste Stunde gratis
        an. Finde also jederzeit einen neuen Tutor.
      </>,
      <></>,
      <>
        Hat letztendlich nichts geholfen und du bist unzufrieden? Das tut uns
        leid! Daher erstatten wir dir die Kosten für das Semesterabonnement.
      </>,
    ],
  },
  {
    title: "Was ist der Student Pass und wieso kostet er 29€?",
    content: [
      <>
        Mit dem Student Pass kannst du ein Semester lang so viele Tutoren
        kontaktieren wie du möchtest.
      </>,
      <>
        Wir legen viel Wert auf eine qualitativ hochwertige Plattform und
        arbeiten mit großem Engagement daran, Studenten die Tutorensuche zu
        vereinfachen und passende Tutoren zur Verfügung zu stellen.
      </>,
      <>
        <b>Dabei verkaufen wir deine Daten nicht!</b> Damit wir euch diese
        Plattform trotzdem anbieten können, gibt es ein Abo.
      </>,
    ],
  },
  {
    title: "Wie kann ich den Student Pass kündigen?",
    content: [
      <>
        Sende uns einfach bis 3 Tage vor Verlängerung des Abos eine Mail an{" "}
        <a href="mailto:hello@unitutors.de ?subject=Student Pass Kündigung&body=Ich möchte gerne meinen Student Pass kündigen.">
          hello@unitutors.de
        </a>{" "}
        und wir werden den Student Pass für dich kündigen.
      </>,
    ],
  },
  {
    title: "Wie funktioniert die 30 Tage Geld-zurück-Garantie?",
    content: [
      <>
        Falls du keine Tutoren finden konntest, deine angefragten Tutoren deine
        Kontaktanfrage nicht angenommen haben oder du letztendlich mit dem
        Unterricht unzufrieden warst, erstatten wir dir die Kosten unseres
        Semesterabonnements. Beachte bitte, dass die Garantie ihre Gültigkeit
        verliert, wenn du in der Freitextnachricht an einen Tutor Kontaktdaten
        angibst.
      </>,
      <>
        <b>Wie bekomme ich das Geld zurück?</b>
      </>,
      <>
        Möchtest du dein Geld erstattet bekommen, schreib uns bitte eine Mail an{" "}
        <a href="mailto:hello@unitutors.de ?subject=Geld-zurück-Garantie&body=Ich möchte gerne mein Geld zurück bekommen. %0A %0AGrund: %0A <BITTE NENNE HIER DEN GRUND FÜR DEINE GEWÜNSCHTE RÜCKERSTATTUNG>">
          hello@unitutors.de
        </a>
        . Entweder überweisen wir dir das Geld auf dein Konto oder schicken es
        dir per PayPal.
      </>,
    ],
  },
  {
    title: "Wie bezahle ich meine Nachhilfe-Tutoren?",
    content: [
      <>
        Die Tutoren, die du auf unserer Plattform findest, arbeiten unabhängig
        von uns. Unsere Plattform bietet lediglich die Vermittlung an. Daher
        könnt ihr die Art der Bezahlung unter euch vereinbaren.
      </>,
    ],
  },
  {
    title: "Wo kann ich mich mit meinem Tutor treffen?",
    content: [
      <>
        Die Nachhilfe-Tutoren von Unitutors geben in ihrem Profil den Ort des
        Unterrichts an. Sie können zwischen „Beim Studenten“, „Beim Tutor“,
        „Online“ und „Nach Absprache“ (Mehrfachwahl möglich) wählen. Lernräume
        und Bibs bieten z.B. eine gute Möglichkeit zum Treffen.
      </>,
    ],
  },
  {
    title: "Wieviele Minuten sind eine Stunde bei Unitutors?",
    content: [
      <>
        Wir gehen von Zeitstunden aus: Eine Unterrichtsstunde dauert 60 Minuten.
      </>,
    ],
  },
];
export const tutorQs = [
  {
    title: "Kommen Kosten auf mich zu?",
    content: ["Nein, Unitutors ist für Tutoren kostenlos."],
  },
  {
    title: "Muss ich eine Provision bezahlen?",
    content: ["Nein, Unitutors nimmt keine Provision."],
  },
  {
    title: "Habe ich Verpflichtungen, wenn ich mich registriere?",
    content: [
      "Nein, du bist nicht an uns gebunden und kannst selbst entscheiden, wem, wie oft und in welchen Fächern du Nachhilfe anbietest. ",
    ],
  },
  {
    title: "Was ist, wenn ich mal keine Zeit habe?",
    content: [
      "Kein Problem, stelle deinen Status oben rechts im Tutorenprofil auf inaktiv und schon sehen Suchende, dass du momentan nicht verfügbar bist.",
      "Du entscheidest, wann und wie oft du private Tutoring anbieten möchtest.",
      "Sobald du wieder Zeit hast, stellst du deinen Status einfach auf aktiv.",
    ],
  },
  {
    title: "Wie werde ich von meinen Studenten bezahlt?",
    content: [
      "Da du unabhängig von uns arbeitest, könnt ihr das direkt unter euch ausmachen. Nutzt z.B. Paypal oder Bargeld.",
    ],
  },
  {
    title:
      "Kann ich selbst entscheiden, wem ich Tutoring anbieten möchte und wem nicht?",
    content: [
      "Klaro! Sendet dir ein Student eine Anfrage, so bekommst du von ihm eine Nachricht, in welcher er u.a. sein Anliegen schildern kann. Anschließend kannst du die Anfrage annehmen oder ablehnen.",
    ],
  },
  {
    title: "Für welche Fächer kann ich Nachhilfe anbieten?",
    content: [
      "Wir haben tausende Fächer von vielen hundert Studiengängen und über 400 Unis in unserer Datenbank. Falls dein Fach nicht gefunden wird, füge es mit zwei Clicks hinzu.",
    ],
  },
  {
    title: "Wie biete ich guten Unterricht an?",
    content: [
      "Wichtig ist, dass ihr euch vor der ersten Stunde absprecht. Versuche, das Problem des Studenten zu verstehen. Bereite dich anschließend vor und mache dir Gedanken über den Ablauf des Unterrichts.",
    ],
  },
  {
    title: "Was ist ein angemessener Stundenlohn?",
    content: [
      "Das kommt natürlich ganz auf deine Erfahrung und Qualifikationen an. Im Durchschnitt nehmen Nachhilfe-Tutoren anfangs zwischen 12 und 20€ pro Stunde.",
    ],
  },
  {
    title: "Warum muss ich meine Telefonnummer angeben?",
    content: [
      <>
        <ol>
          <li>
            Ein Qualitätsmerkmal unserer Plattform ist die schnelle Antwortzeit
            der Tutoren. Um diese zu bieten, senden wir dir bei jeder
            Kontaktanfrage eine SMS-Benachrichtigung.
          </li>
          <li>
            Damit Studenten dich einfach und schnell erreichen können, empfehlen
            wir Whatsapp, SMS oder Anrufe zum Vereinbaren des Unterrichts. Wenn
            du die Anfrage eines Studenten annimmst, geben wir daher deine
            Nummer an den Studenten weiter.
          </li>
        </ol>
      </>,
    ],
  },
  {
    title: "Wo kann ich mich mit meinen Studenten treffen?",
    content: [
      "In deinem Profil kannst du den Ort des Unterrichts angeben. Wähle zwischen „Beim Studenten“, „Beim Tutor“, „Online“ und „Nach Absprache“ (Mehrfachwahl möglich). Gute Orte zum Lernen sind z.B. Lernräume und Bibs.",
    ],
  },
  {
    title: "Sind Studenten überhaupt interessiert an Nachhilfe?",
    content: [
      "Eindeutig ja! Es gibt mehr Nachfrage, als es Tutoren gibt. 40% aller Studenten suchen im Laufe des Studiums Nachhilfe. Nicht nur bei uns, sondern auch bei den Fachschaften gehen täglich dutzende Anfragen ein.",
    ],
  },
];
