export const setFetching = (name) => ({
  type: "SET_FETCHING",
  name,
});

const actionNames = [setFetching().type];

const reducer = (types) => (state = {}, action = {}) => {
  switch (action.type) {
    case types.SET_FETCHING.name:
      return {
        ...state,
        [action.name]: true,
      };
    case types.SET_USER.name:
      return {
        ...state,
        user: false,
      };
    case types.SET_TUTOR.name:
      return {
        ...state,
        tutor: false,
      };
    default:
      return state;
  }
};

export default {
  reducer,
  actionNames,
  blacklisted: true,
};
