import React from "react";
import { colors } from "helpers/theme";

const NotificationBubble = ({ count, style, inline }) => {
  if (count) {
    return (
      <div
        style={{
          background: colors.red,
          color: "white",
          borderRadius: 100,
          width: 22,
          height: 22,
          border: "2px solid white",
          lineHeight: "19px",
          textAlign: "center",
          fontSize: "10px",
          fontWeight: "bold",
          ...(inline
            ? {
                marginLeft: 10,
                display: "inline-block",
              }
            : {
                marginBottom: -22,
                position: "relative",
                top: -10,
                left: -10,
              }),
          ...style,
        }}
      >
        {count}
      </div>
    );
  }
  return null;
};

export default NotificationBubble;
