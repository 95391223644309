const styleWrong = {
  color: "#e30020",
};

const inputStyle = {
  resize: "none",
  width: "100%",
  border: "1px solid rgb(203, 206, 212)",
  borderRadius: 15,
  padding: 15,
  marginTop: 10,
  color: "#31313f",
  lineHeight: "32px",
  background: "white",
};

const buttonStyle = {
  backgroundColor: "#387bf5",
  width: "100%",
  color: "white",
  margin: "20px auto",
  padding: 10,
  border: "none",
  borderRadius: 10,
  lineHeight: "26px",
};

const buttonStyleDoubleShadow = {
  backgroundColor: "#387bf5",
  padding: "20px 40px",
  boxShadow:
    "#d8e5fc 13px -13px 0px 0px, rgba(56, 123, 245, 0.1) 0px 0px 30px 3px",
  fontSize: "16px",
  fontWeight: "600",
  color: "white",
  marginTop: "40px",
  border: "none",
  borderRadius: 10,
  lineHeight: "16px",
};

const buttonStyleAngefragt = {
  backgroundColor: "white",
  padding: "20px 40px",
  fontSize: "16px",
  fontWeight: "500",
  color: "#505662",
  marginTop: "40px",
  border: "1px solid",
  borderColor: "#bdc1c9",
  borderRadius: 10,
};

const buttonStyleAbgelehnt = {
  backgroundColor: "white",
  padding: "20px 40px",
  fontSize: "16px",
  fontWeight: "500",
  color: "#ea4736",
  marginTop: "40px",
  border: "1px solid",
  borderColor: "#ea4736",
  borderRadius: 10,
};

const buttonOutlineStyle = {
  backgroundColor: "white",
  width: "100%",
  color: "#387bf5",
  padding: 10,
  border: "1px solid #387bf5",
  borderRadius: 10,
};

const buttonTextOnlyStyle = {
  backgroundColor: "white",
  height: "44px",
  color: "#387bf5",
  margin: 20,
  padding: 10,
  border: "none",
};

const buttonIconStyle = {
  backgroundColor: "white",
  width: "100%",
  height: "44px",
  color: "#387bf5",
  margin: "20px auto",
  padding: 10,
  border: "none",
};

const colors = {
  headings: "#595963",
  accent: "rgb(145, 56, 245)",
  accent2: "rgb(254, 193, 67)",
  light: "rgb(223, 234, 252)",
  light2: "#edf0f6",
  light3: "#f4f6fa",
  lila: "#302ea7",
  shadow: "rgb(36, 52, 78)",

  /* Bis hier sind die alten, die stehen hier noch, weil es sein kann, 
  dass diese irgendwo verwendet werden. Unten stehen die neuen, die ab 
  jetzt verwendet werden. Teilweise müssen wir jetzt anfangen neu zuzuordnen. 
  Sobald wir eine Standardfarbe für zum Beispiel Disabled haben, markieren wir diese unten*/

  text: "#2c3139",
  primary: "#387bf5",

  dark: [
    "#0b0c0e",
    "#2c3139" /*Textfarbe*/,
    "#22252a",
    "#393e46",
    "#5b616b",
    "#676f7e",
  ],
  bright: [
    "#818898",
    "#9fa5b0",
    "#bdc1c9",
    "#dbdde2",
    "#edf0f6" /* Rahemenfarbe und Hintergründe */,
    "#f4f6fa" /* manchmal Hintergrund */,
  ],
  color1: [
    "#142a52",
    "#24344e" /*Dunkle Abstufung Schattenfarbe - Immer Transparenz zwischen 10 und 30%*/,
    "#1b386d",
    "#224688",
    "#2954a3",
    "#3062be",
    "#4173cf",
    "#5c87d6",
    "#779bdd",
    "#92afe4",
    "#adc3eb",
    "#dfeafc" /*Blauer Hintergrund leichtes Blau*/,
  ],

  secondary: "#9138f5",
  color2: [
    "#311452",
    "#421b6d",
    "#522288",
    "#6329a3",
    "#7330be",
    "#8441cf",
    "#965cd6",
    "#a777dd",
    "#b992e4",
    "#caadeb",
  ],

  yellow: "#fec143",
  secondary2: "#fec143",
  stateYellow: ["#604206", "#a8740a", "#f0a60f", "#f5c157", "#f9db9f"],

  green: "#35aaa0",
  stateGreen: [
    "#15514c",
    "#248e86",
    "#33ccbf",
    "#71dbd2",
    "#aeeae5",
    "#eaf6f5" /* Hintergrundfarbe Succesmessage */,
  ],

  red: "#ea4736",
  stateRed: [
    "#521a14",
    "#8f2e23",
    "#cc4133",
    "#dc7a70",
    "#ebb3ad",
    "#fcd1d1" /* Hintergrundfarbe von Errormessage */,
  ],
};

const font = '"MyInter", "Helvetica Neue", sans-serif';

export {
  font,
  styleWrong,
  inputStyle,
  buttonStyle,
  buttonOutlineStyle,
  buttonIconStyle,
  buttonStyleDoubleShadow,
  buttonStyleAngefragt,
  buttonStyleAbgelehnt,
  buttonTextOnlyStyle,
  colors,
};
