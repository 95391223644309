import React from "react";
import { Footer, NoSearchHeader } from "components";
import background from "assets/Background_Rechtliches.svg";
import { useMediaQuery } from "react-responsive";

const AGB = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "2600px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: 30,
            paddingTop: isTablet ? 300 : 150,
            paddingBottom: isTablet ? 210 : 130,
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          Unsere Allgemeinen Nutzungs&shy;bedingungen
        </h1>
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: 1100,
          padding: isTablet ? "0 50px" : "0 15px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: 100,
        }}
      >
        <h3 style={{ fontWeight: 500 }}>1. Geltungsbereich</h3>
        <br />
        Die nachfolgende Allgemeinen Nutzungsbedingungen gelten für alle
        Webseiten von Unitutors (gemeint sind Unterseiten sowie Subdomains und
        deren Unterseiten).
        <br />
        <br />
        Aus Gründen der einfacheren Lesbarkeit wird im Folgenden nur die
        männliche Form verwendet, allerdings sind Personen männlichen und
        weiblichen Geschlechts gemeint.
        <br />
        <br />
        Unitutors behält sich das Recht vor, den Zugriff auf die Seite zu
        unterbinden und den User-Account zu löschen/sperren, falls Nutzer mit
        den Bedingungen nicht einverstanden sind.
        <br />
        <br />
        <br />
        <h3 style={{ fontWeight: 500 }}>
          2. Leistungsumfang, Beschreibung unserer Webseite
        </h3>
        <br />
        Unitutors ist eine Plattform zur Vermittlung von Nachhilfe-Tutoren an
        Studenten. Ziel und Zweck der Plattform ist es, Tutorsuchenden
        Kontaktdaten von passenden Tutoren und andersherum zu vermitteln.
        <br />
        Um diese Leistung in Anspruch nehmen zu können, müssen sowohl Studenten,
        als auch Tutoren gleichermaßen ein Benutzerkonto erstellen. Die Benutzer
        verpflichten sich dazu, nur ein einziges Profil zu erstellen. <br />
        Das eigene Profil kann in einem persönlichen Bereich nach Anmeldung auf
        unserer Plattform bearbeitet und verwaltet werden.
        <br />
        Möchte man die Funktion des Profilbildes nutzen, ist man dazu
        verpflichtet, eines von sich hochzuladen, auf welchem das Gesicht gut
        erkennbar ist. Das Bild sollte außerdem aus einer nahen Perspektive
        sein, bedeutet, dass die Beine nicht sichtbar sein dürfen. Darüberhinaus
        ist es nicht gestattet, ein Bild von anderen Personen oder Objekten
        hochzuladen.
        <br />
        Die Löschung eines Benutzerkontos erfolgt auf Anfrage per{" "}
        <a href="mailto:hello@unitutors.de ?subject=Account löschen&body=Bitte löscht meinen Acount mit der Email-Adresse <EMAIL HIER EINFÜGEN>.">
          Mail
        </a>{" "}
        durch unser Team.
        <br />
        <br />
        <b>Tutor werden, Tutoringanzeige erstellen</b>
        <br />
        Um von Tutorsuchenden gefunden zu werden, erstellen die Tutoren
        kostenlos eine Tutoringanzeige/Kontaktanzeige, welche auf unserer
        Website veröffentlich und möglicherweise in Google indexiert wird. Die
        Anzeigen werden von den Tutoren eigenständig geschaltet und sind nach
        Uni und Studiengängen sortiert. Fehlerhafte und/oder irreführende
        Informationen sowie die Angabe von Kontaktinformationen, außer in den
        dafür vorgesehenen Feldern, sind untersagt. Unitutors übernimmt
        keinerlei Haftung für den Inhalt der Kontaktanzeigen/Tutoringanzeigen.
        Ebenfalls übernimmt Unitutor keinerlei Gewähr für die Korrektheit der
        Anzeigen. Bei Zuwiderhandlung behält sich Unitutors das Recht vor, die
        Anzeige und den dahintersteckenden Benutzeraccount zu löschen und zu
        sperren. Die Kontaktinformationen der Tutoren werden nicht auf unserer
        Seite für alle veröffentlicht, sondern sind nur für die Studenten des
        jeweiligen Tutors nach seiner Annahme er Kontaktanfrage sichtbar. Die
        Tutoren sind dazu verpflichtet, auf Anfragen innerhalb von 48 Stunden
        einzugehen. Geschieht dies nicht, kann die Tutoringanzeige offline
        genommen werden. Nach längerer Nichtaktivität (1 Jahr nicht angemeldet)
        können Tutoringanzeigen offline genommen werden. Zuvor schicken wir 2
        Erinnerungsmails. Sollte daraufhin eine Anmeldung stattfinden, bleibt
        die Tutoringanzeige weiterhin online. Sobald sie offline genommen wurde,
        kann diese jederzeit im persönlichen Bereich wieder inseriert werden.
        <br />
        <br />
        <b>Anfragen beantworten</b>
        <br />
        Studenten können mittels einer Kontaktanfrage mit Tutoren Kontakt
        aufnehmen. Diese Anfragen enthalten eine vom Studenten geschriebene
        Nachricht, welche für den angeklagten Tutor ersichtlich ist. Anhand
        dieser Nachricht entscheidet der Tutor, ob er den Studenten annimmt oder
        nicht. Bei Annahme sind nun die Kontaktdaten für den Studenten sichtbar
        und andersherum. Tutoren haben die Möglichkeit, solche Kontaktanfragen
        abzulehnen und können dementsprechend eine Begründung geben, welche dem
        Studenten gezeigt wird. In dieser Begründung dürfen keine
        Kontaktinformationen wie z.B. Telefonnummer, Nachname, Email-Adresse,
        Links zu Webseiten, Facebook o.ä. angegeben werden. Bei Nichteinhaltung
        behält sich Unitutors das Recht vor, den Account zu sperren.
        <br />
        <br />
        <b>Tutoren suchen und kontaktieren</b>
        <br />
        Jeder Besucher unserer Website kann Tutoren suchen. Um diese zu
        kontaktieren, muss man sich ein Benutzerkonto erstellen und unser
        Semester Abonnement (Student Pass) abschließen. Dieses kostest 29 € und
        hat eine Laufzeit von 6 Monaten ab Tag der Abbuchung. Mit dem Student
        Pass haben Nutzer 6 Monate lang die Möglichkeit, so viele Tutoren zu
        kontaktieren wie sie möchten. Nach 6 Monaten wird der Student Pass
        automatisch verlängert. Das Abonnement kann jederzeit bis 3 Tage vor
        Verlängerung per{" "}
        <a href="mailto:hello@unitutors.de ?subject=Student Pass Kündigung&body=Ich möchte gerne meinen Student Pass kündigen.">
          Mail
        </a>{" "}
        gekündigt werden. Es läuft dann bis zum Ende der verbleibenden Laufzeit
        automatisch aus. Studenten mit dem Student Pass können Tutoren eine
        Kontaktanfrage senden. In dieser werden sie in Schritt 1, falls nicht
        schon vorher im persönlichen Bereich angegeben, gebeten, ihre
        Telefonnummer anzugeben. Im zweiten Schritt können sie eine Nachricht an
        den Tutor senden und ihr Anliegen schildern. In dieser Nachricht dürfen
        unter keinen Umständen Kontaktinformationen (Telefonnummer,
        Email-Adresse, Nachname, Links zur Website, Facebook o.ä.) angegeben
        werden. Nachrichten mit solchen Kontaktinformationen und die
        dahinterstehenden Benutzerprofile können von Unitutors gelöscht werden.
        Außerdem entfällt bei Nichteinhaltung der Regeln unsere
        Geld-zurück-Garantie. Wird die Kontaktanfrage vom Tutor angenommen, so
        kann dieser die Telefonnummer vom Studenten und der Student kann die
        Telefonnummer sowie Email-Adresse des Tutors sehen.
        <br />
        <br />
        Unitutors hält sich aus den Transaktionen zwischen Studenten und Tutoren
        heraus. Haftungsansprüche gegen Unitutors, welche sich direkt oder
        indirekt auf die Transaktionen zwischen Studenten und Tutoren zur
        Durchführung des angebotenen Privat- & Einzelunterrichts beziehen, sind
        hiermit ausgeschlossen. Unitutors garantiert nicht für das Erbringen der
        Leistungen von Seiten der Studenten oder Tutoren und stellt lediglich
        eine Vermittlungsplattform zur Verfügung. Die Tutoren arbeiten
        selbstständig auf ihre eigene Verantwortung und müssen sich selber um
        ihre Steuern, Abgaben sowie Anmeldung der Tätigkeit kümmern. Es ist
        nicht gestattet, unaufgefordert kommerzielle Inhalte an Nutzer über
        unsere Plattform zu senden.
        <br />
        <br />
        <br />
        <h3 style={{ fontWeight: 500 }}>
          3. Bezahlungs&shy;möglich&shy;keiten
        </h3>
        <br />
        Zur Abwicklung von Zahlungen geben wir die hierfür erforderlichen
        Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut und ggf.
        von uns beauftragte Zahlungs- und Rechnungsdienstleister bzw. an den von
        Dir im Bestellprozess ausgewählten Zahlungsdienst weiter. Die Abwicklung
        der Zahlungen zwischen dir und Unitutors erfolgt über folgende
        Dienstleister: 1) Zahlung per Visa oder Mastercard sowie Zahlung per
        SEPA-Lastschrift:
        <br />
        <br />
        <b>1) Zahlung per Paypal:</b>
        <br />
        PayPal (Europe) S.à r.l. et Cie, S.C.A. 22-24 Boulevard Royal L-2449
        Luxembourg Alle für die Zahlungsabwicklung erforderlichen Daten werden
        sicher über das Verfahren "SSL" übermittelt. Paypal transferiert,
        verarbeitet und speichert gegebenenfalls personenbezogene Daten
        außerhalb der EU. Wenn Du Paypal als Zahlungsweise wählst, stimmst Du
        den{" "}
        <a
          href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Datenschutzbestimmungen von Paypal
        </a>{" "}
        zu. Es wird darauf hingewiesen, dass Deine Vertragsbeziehung zu Paypal
        S.à.r.l. et Cie, S.C.A. unabhängig ist von Deiner vertraglichen
        Beziehung zu Unitutors.
        <br />
        <br />
        <b>
          2) Zahlung per Visa oder Mastercard sowie Zahlung per
          SEPA-Lastschrift:
        </b>
        <br />
        Stripe, Inc. 510 Townsend Street San Francisco CA 94103 USA Alle für die
        Zahlungsabwicklung erforderlichen Daten werden von Stripe und Unitutors
        ausschließlich für die Durchführung der Zahlungen verwendet und sicher
        über das Verfahren "SSL" übermittelt.  Stripe transferiert, verarbeitet
        und speichert gegebenenfalls personenbezogene Daten außerhalb der EU.
        Mit der Nutzung einer Kreditkartenzahlung oder dem Erteilen einer
        Einzugsermächtigung über Stripe akzeptierst Du die{" "}
        <a
          href="https://stripe.com/de/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Datenschutzbestimmungen von Stripe
        </a>
        . Es wird darauf hingewiesen, dass Deine Vertragsbeziehung zu Stripe
        Payments Europe Ltd unabhängig ist von Deiner vertraglichen Beziehung zu
        Unitutors.
        <br />
        <br />
        <br />
        <h3 style={{ fontWeight: 500 }}>4. Rechte von Unitutors</h3>
        <br />
        Unitutors behält sich das Recht vor, Benutzer für Rückfragen persönlich
        zu kontaktieren. Unitutors behält sich das Recht vor, auf der Website
        ausgetauschte Nachrichten und Anfragen zu speichern, zu löschen und
        einzusehen. Unitutors behält sich das Recht vor, Benutzerkonten und
        Tutoringanzeigen zu löschen. Unitutors behält sich das Recht vor,
        Änderungen an den von der Seite angebotenen Leistungen vorzunehmen, ohne
        dass Studenten oder Tutoren daraus ein Recht auf Entschädigung geltend
        machen können.
        <br />
        <br />
        <br />
        <h3 style={{ fontWeight: 500 }}>5. Geld-zurück-Garantie</h3>
        <br />
        Um die Zufriedenheit der Studenten unserer Plattform zu maximieren,
        bieten wir eine 30-tägige Geld-zurück-Garantie an. Diese bezieht sich
        nicht auf die Vergütung der Tutoren, sondern lediglich auf die Kosten
        unseres Semester Abonnements im ersten Semester. Weitere Abonnement
        Zyklen über das erste Semester hinaus werden nicht zurückerstattet.
        Unsere Geld-zurück-Garantie entfällt, wenn in der Nachricht an den Tutor
        Kontaktinformationen angegeben werden. Die Beantragung auf Anspruch der
        Geld-zurück-Garantie findet per Mail statt:{" "}
        <a href="mailto:hello@unitutors.de ?subject=Geld-zurück-Garantie&body=Ich möchte gerne mein Geld zurück bekommen. %0A %0AGrund: %0A <BITTE NENNE HIER DEN GRUND FÜR DEINE GEWÜNSCHTE RÜCKERSTATTUNG>">
          hello@unitutors.de
        </a>
        . Wird diese gestattet, behält sich Unitutors das Recht vor, zu
        entscheiden, ob das Geld auf ein Bankkonto überwiesen wird oder per
        PayPal zurückerstattet wird.
        <br />
        <br />
        <br />
        <h3 style={{ fontWeight: 500 }}>6. Widerrufsbelehrung</h3>
        <br />
        Du hast das Recht, innerhalb von 14 Tagen nach Vertragsabschluss diesen
        Vertrag zu widerrufen. Für die Ausübung Deines Widerrufsrechts kannst Du
        uns eine Mail vor Ablauf der Widerrufsfrist senden. Außerhalb der
        Widerrufsfrist bietet Unitutors eine 30-tägige Geld-zurück-Garantie an.
      </div>

      <Footer />
    </>
  );
};

export default AGB;
