import React, { useState } from "react";
import {
  CardElement as CardElementStripe,
  useStripe as _useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { post } from "helpers/api";

import { inputStyle, colors, font } from "helpers/theme";

import payment from "config/payment";
const { stripe: STRIPE } = payment;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: font,
      fontSize: "16px",
      "::placeholder": {
        color: colors.bright[2],
      },
      ...inputStyle,
    },
    invalid: {
      color: colors.red,
      iconColor: colors.red,
    },
  },
};

export const CardElement = () => (
  <CardElementStripe options={CARD_ELEMENT_OPTIONS} />
);

const useStripe = (user, promoCode, onSuccess) => {
  const stripe = _useStripe();
  const elements = useElements();

  const priceId = STRIPE.priceId;

  const [isSubscribing, setSubscribing] = useState(false);
  const { customerId } = user || {};

  const catchError = (error) => {
    console.log("CREATESUBSCRIPTION CATCH");
    // An error has happened. Display the failure to the user here.
    // We utilize the HTML element we created.
    const msg = error.message || error.error.decline_code;
    console.log(new Error());
    setSubscribing(false);
    alert(
      "Es gab ein Problem mit deiner Kreditkarte. Bitte versuche eine andere Zahlungsmethode. " +
        msg
    );
  };

  const onSubmit = async (event) => {
    if (isSubscribing) {
      return;
    }
    setSubscribing(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElementStripe);

    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[createPaymentMethod error]", error);
      setSubscribing(false);
      alert(error.message);
      return;
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const paymentMethodId = paymentMethod.id;
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        // Update the payment method and retry invoice payment
        const invoiceId = localStorage.getItem("latestInvoiceId");
        retryInvoiceWithNewPaymentMethod({
          customerId,
          paymentMethodId,
          invoiceId,
          priceId,
        });
      } else {
        // Create the subscription
        post("users/$1/subscriptions/stripe/customer", [user.uuid])
          .auth(user.auth)
          .then((result) => {
            createSubscription({
              customerId: result.customer.id,
              paymentMethodId,
              priceId,
              promoCode,
            });
          })
          .catch(catchError);
      }
    }
  };

  function createSubscription({
    customerId,
    paymentMethodId,
    priceId,
    promoCode,
  }) {
    return (
      post("users/$1/subscriptions/stripe", [user.uuid])
        .auth(user.auth)
        .data({
          promoCode: promoCode || undefined,
          customerId,
          paymentMethodId,
          priceId,
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then(({ subscription }) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch(catchError)
    );
  }

  function onSubscriptionComplete(result) {
    // Payment was successful.
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.removeItem("latestInvoiceId");
      localStorage.removeItem("latestInvoicePaymentIntentStatus");
    }
    onSuccess();
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch(catchError);
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error("Your card was declined.");
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) {
    return (
      post("users/$1/subscriptions/stripe/retry", [user.uuid])
        .data({
          customerId,
          paymentMethodId,
          invoiceId,
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then(({ invoice, ...result }) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch(catchError)
    );
  }

  return {
    buttonDisabled: !stripe,
    onSubmit,
    isSubscribing,
  };
};

export default useStripe;
