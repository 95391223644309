import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "helpers/navigation";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { getUnreadNotis } from "redux/user";
import NotificationBubble from "components/NotificationBubble";

import { ReactComponent as Profile } from "assets/icons8-benutzer-100.svg";
import { ReactComponent as CV } from "assets/icons8-lebenslauf-100.svg";
import { ReactComponent as Subjects } from "assets/icons8-kurse-100-2.svg";
import { ReactComponent as Messages } from "assets/icons8-nachricht-gefüllt-100.svg";
import { ReactComponent as MyStudents } from "assets/icons8-benutzergruppen-100.svg";
import { ReactComponent as MyTutors } from "assets/icons8-doktorhut-100.svg";

const sidebarStyle = {
  fontSize: 20,
  lineHeight: "70px",
  color: "#595963",
};

const notifications = (user) => {
  const notiCount = getUnreadNotis(user.notifications).length;

  return {
    name: notiCount ? (
      <div
        style={{
          display: "inline-block",
          lineHeight: "inherit",
          fontSize: "inherit",
          fontWeight: "inherit",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            lineHeight: "inherit",
            fontSize: "inherit",
            fontWeight: "inherit",
          }}
        >
          Nachrichten
          <NotificationBubble count={notiCount} inline />
        </div>{" "}
      </div>
    ) : (
      "Nachrichten"
    ),
    location: "notifications",
    Icon: Messages,
  };
};

const tutorEntries = (user) => [
  { name: "Mein Account", location: "profile", Icon: Profile },
  { name: "Meine Studenten", location: "mystudents", Icon: MyStudents },
  { name: "Tutorenprofil", location: "tutorprofile", Icon: CV },
  { name: "Meine Fächer", location: "subjects", Icon: Subjects },
  { name: "Meine Tutoren", location: "mytutors", Icon: MyTutors },
  notifications(user),
];

const studentEntries = (user) => [
  {
    name: "Mein Account",
    location: "profile",
    Icon: Profile,
  },
  { name: "Meine Tutoren", location: "mytutors", Icon: MyStudents },
  notifications(user),
];

const useSidebarShown = () => useMediaQuery({ query: "(min-width: 900px)" });

const Sidebar = connect(
  ({ user }) => ({ user }),
  {}
)(({ current, user }) => {
  const isWide = useSidebarShown();
  const { isTutor } = user;
  const entries = (isTutor ? tutorEntries : studentEntries)(user);
  return (
    isWide && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "stretch",
          paddingTop: 200,
          minWidth: "280px",
          marginRight: 30,
          borderRight: "1px solid rgb(227, 229, 233)",
        }}
      >
        {entries.map(({ name, location }) => (
          <div
            key={location}
            className="profil_menu_links_div"
            style={{
              borderRight:
                location === current ? "2px solid rgb(203, 206, 212)" : "none",
              paddingRight: 90,
            }}
          >
            <Link
              className="profil_menu_links"
              style={{
                ...sidebarStyle,
                fontWeight: location === current ? "600" : "",
                fontSize: location === current ? "22px" : "20px",
              }}
              to={"/" + location}
            >
              {name}
            </Link>
          </div>
        ))}
      </div>
    )
  );
});

const MenuEntries = connect(
  ({ user }) => ({ user }),
  {}
)(({ user }) => {
  const history = useHistory();
  const { isTutor } = user;
  const entries = (isTutor ? tutorEntries : studentEntries)(user);
  return entries.map(({ Icon, name, location }) => (
    <Dropdown.Item
      icon={Icon && <Icon style={{ marginRight: 10 }} height={20} width={20} />}
      key={name}
      text={name}
      onClick={() => history.push(location)}
    />
  ));
});

export default Sidebar;
export { MenuEntries, useSidebarShown };
