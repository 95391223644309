import { useEffect } from "react";

const usePaypalButton = (buttons, ready) =>
  useEffect(() => {
    if (ready) {
      const fileref = document.createElement("script");
      fileref.onload = () => {
        for (const { buttonId, onApprove, price } of buttons) {
          window.paypal
            .Buttons({
              style: {
                shape: "rect",
                color: "blue",
                layout: "vertical",
                label: "pay",
              },
              createOrder: function (data, actions) {
                return actions.order.create({
                  purchase_units: [
                    { amount: { currency_code: "EUR", value: price } },
                  ],
                });
              },
              onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                  onApprove();
                });
              },
            })
            .render(buttonId);
        }
      };
      fileref.setAttribute("type", "text/javascript");
      fileref.setAttribute("data-sdk-integration-source", "button-factory");
      fileref.setAttribute(
        "src",
        "https://www.paypal.com/sdk/js?client-id=AWpmoUbr7bdNkzcdQLgKmW1a6nBhyMC8hpGyhlVZs8wc7LCQIKdkuL2KXMFceWYMJfP0ZDggqY5-TmQ6&currency=EUR"
      );
      document.getElementsByTagName("head")[0].appendChild(fileref);
    }
  }, [buttons, ready]);

export default usePaypalButton;
