import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SubmitButton, Footer, NoSearchHeader, Field } from "components";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { patch } from "helpers/api";
import { useHistory, useSearch } from "helpers/navigation";
import { styleWrong, inputStyle } from "helpers/theme";
import background from "assets/Background_Login_Screen.svg";

const NewPWScreen = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [done, setDone] = useState(false);
  const history = useHistory();

  const { c: token, email } = useSearch();

  const { force, redirect, ...searchParams } = useSearch();
  const onSubmit = ({ password }, actions) => {
    if (password) {
      setWrong(false);
      setLoading(true);
      patch("login/password")
        .data({ newPassword: password, token, email })
        .on(401, () => {
          setWrong(true);
          setLoading(false);
        })
        .on(404, () => {
          setWrong(true);
          setLoading(false);
        })
        .then((me) => {
          setLoading(false);
          setDone(true);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (user.uuid && redirect && !force) {
      history.push(redirect, [], searchParams);
    } else if (user.uuid && !force) {
      history.push("");
    }
  }, [user, history, redirect, force, searchParams]);

  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {done ? (
          "Dein Passwort wurde zurückgesetzt. Du kannst dich jetzt einloggen."
        ) : (
          <Formik
            initialValues={{ password: "" }}
            validationSchema={Yup.object({
              password: Yup.string().required("Bitte gib dein Passwort ein."),
            })}
            onSubmit={onSubmit}
          >
            <Form
              style={{
                width: "min(500px, calc(100% - 20px))",
                padding: 40,
                paddingTop: 120,
              }}
            >
              <h2 style={{ marginBottom: 10 }}>Passwort zurücksetzen</h2>
              <Field
                style={inputStyle}
                placeholder={"Passwort"}
                name="password"
                type="password"
              />
              <SubmitButton loading={loading}>Jetzt zurücksetzen</SubmitButton>
              {wrong && (
                <div style={styleWrong}>
                  Es gibt ein Problem mit deinem Link. Bitte versuche dein
                  Passwort nocheinmal zurückzusetzen.
                </div>
              )}

              <div style={{ textAlign: "right", margin: 8 }}>
                <Link
                  to="/login"
                  style={{ textDecoration: "underline", color: "#387bf5" }}
                >
                  Einloggen
                </Link>
              </div>
            </Form>
          </Formik>
        )}
      </div>
      <Footer />
    </>
  );
};
NewPWScreen.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(({ user }) => ({ user }), {})(NewPWScreen);
