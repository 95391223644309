import React from "react";
import { SubmitButton, Field } from "components";
import { Modal } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const RejectionModal = ({ loading, onClose, send }) => {
  const isMedium = useMediaQuery({ query: "(min-width: 768px)" });

  const onSend = ({ rejection }) => send(rejection);

  return (
    <Modal open closeIcon onClose={onClose}>
      <Modal.Header>Nachricht an den Studenten</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ rejection: "" }}
          validationSchema={Yup.object({
            rejection: Yup.string().required(
              "Bitte beschreibe, warum du die Anfrage abgelehnt hast."
            ),
          })}
          onSubmit={onSend}
        >
          <Form>
            <div style={{ maxWidth: 500, margin: "auto" }}>
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  marginTop: 10,
                }}
              >
                Bitte erkläre kurz, warum du ablehnst
              </h6>
              <p style={{ fontSize: "15px" }}>
                Hast du gerade keine Zeit? Liegt es an dem Thema oder an der
                Unterrichtsart...?
              </p>
              <Field
                as="textarea"
                placeholder={"Nachricht an den Studenten"}
                name="rejection"
                style={{ height: 150 }}
              />
              <SubmitButton fullWidth={!isMedium} loading={loading}>
                Abschließen
              </SubmitButton>
            </div>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default RejectionModal;
