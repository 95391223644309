import React from "react";
import ReactDOM from "react-dom";
import "./fonts/Inter-Black.ttf";
import "./fonts/Inter-Black.ttf";
import "./fonts/Inter-Bold.ttf";
import "./fonts/Inter-ExtraBold.ttf";
import "./fonts/Inter-ExtraLight.ttf";
import "./fonts/Inter-Light.ttf";
import "./fonts/Inter-Medium.ttf";
import "./fonts/Inter-Regular.ttf";
import "./fonts/Inter-SemiBold.ttf";
import "./fonts/Inter-Thin.ttf";

import "./fonts/BalsamiqSans-Regular.ttf";
import "semantic-ui-css/semantic.min.css";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-rater/lib/react-rater.css";

import "./index.css";

//import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
//    <Helmet bodyAttributes={{ style: "background-color : white" }} />

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
