export const setOffline = () => ({
  type: "SET_NETWORK",
  state: false,
});

export const setOnline = () => ({
  type: "SET_NETWORK",
  state: true,
});

const actionNames = [setOffline().type];

const defaultState = true;
const reducer = (types) => (state = defaultState, action = {}) => {
  switch (action.type) {
    case types.SET_NETWORK.name:
      return action.state;
    default:
      return state;
  }
};

export default {
  reducer,
  actionNames,
  blacklisted: true,
};
