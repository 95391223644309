import { useState } from "react";
import { put, del } from "helpers/api";
import { useHistory, useRedirector } from "helpers/navigation";

const useFavorites = (user) => {
  const history = useHistory();
  const redirectQuery = useRedirector();
  return (tutorId, status) => {
    if (!user.uuid) {
      history.push("signup", [], redirectQuery.obj);
    }
    if (status) {
      return put("users/tutors/$1/favorites", [tutorId]).auth(user.auth);
    } else {
      return del("users/tutors/$1/favorites", [tutorId]).auth(user.auth);
    }
  };
};

const useToggleFavorite = (id, starred, setFavorite) => {
  const [favoriteLoading, setFavoriteLoading] = useState();
  const [isFavorite, setIsFavorite] = useState(starred);
  const toggleFavorite = () => {
    setFavoriteLoading(true);
    setFavorite(id, !isFavorite)
      .then(() => {
        setFavoriteLoading(false);
        setIsFavorite(!isFavorite);
      })
      .catch(() => {
        setFavoriteLoading(false);
      });
  };
  return { toggleFavorite, isFavorite, favoriteLoading };
};

export { useToggleFavorite };
export default useFavorites;
