import React from "react";
import { Footer, NoSearchHeader } from "components";
import background from "assets/Background_Rechtliches.svg";
import { useMediaQuery } from "react-responsive";
import urls from "config/urls";

const Imprint = () => {
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "2600px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            margin: 30,
            paddingTop: isTablet ? 300 : 150,
            paddingBottom: isTablet ? 210 : 130,
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          Impressum
        </h1>
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: 1100,
          padding: isTablet ? "0 50px" : "0 15px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: 100,
        }}
      >
        <br />
        <br />
        <h2>Diensteanbieter</h2>
        <br />
        Unitutors
        <br /> Jean-Nico Minor
        <br /> Schlottfelder Str. 18
        <br /> 52074 Aachen
        <br /> Deutschland
        <br />
        <br />
        <h2>Kontaktmöglichkeiten</h2>
        <br />
        E-Mail-Adresse: info@unitutors.de
        <br /> Telefon: 015754135687
        <br />
        <br />
        <h2>Journalistisch-redaktionelle Angebote</h2>
        <br />
        Inhaltlich verantwortlich: Jean-Nico Minor
        <br />
        <br />
        <h2>Angaben zum Unternehmen</h2>
        <br />
        AGB: www.unitutors.de/agb
        <br />
        <br />
        <h2>Online-Streitbeilegung (OS)</h2>
        <br />
        Online-Streitbeilegung: Die Europäische Kommission stellt eine Plattform
        zur Online-Streitbeilegung (OS) bereit, die Sie unter
        https://ec.europa.eu/consumers/odr/ finden. Verbraucher haben die
        Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu
        nutzen.
        <br />
        <br />
        <h2>Social Media und andere Onlinepräsenzen</h2>
        <br />
        Dieses Impressum gilt auch für die folgenden Social-Media-Präsenzen und
        Onlineprofile:
        <br />
        {urls.youtube}
        <br />
        {urls.linkedin}
        <br />
        {urls.instagram}
        <br />
        <br />
        <h2>Haftungs- und Schutzrechtshinweise</h2>
        <br />
        <p>
          Haftungsausschluss: Die Inhalte dieses Onlineangebotes wurden
          sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen
          jedoch nur der Information und entfalten keine rechtlich bindende
          Wirkung, sofern es sich nicht um gesetzlich verpflichtende
          Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder
          verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns
          vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen,
          soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote
          sind freibleibend und unverbindlich.
        </p>
        <p>
          Links auf fremde Webseiten: Inhalte fremder Webseiten, auf die wir
          direkt oder indirekt verweisen, liegen außerhalb unseres
          Verantwortungsbereiches und machen wir uns nicht zu Eigen. Für alle
          Inhalte und insbesondere für Schäden, die aus der Nutzung der in den
          verlinkten Webseiten aufrufbaren Informationen entstehen, haftet
          allein der Anbieter der verlinkten Webseiten.
        </p>
        <p>
          Urheberrechte und Markenrechte: Alle auf dieser Website dargestellten
          Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen
          sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte)
          geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren
          Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteverwalter.
        </p>
        <p>
          Hinweise auf Rechtsverstöße: Sollten Sie innerhalb unseres
          Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns auf
          diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach
          Kenntnisnahme unverzüglich entfernen.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Imprint;
