import React, { useEffect, useState } from "react";

import { ReactComponent as Erfolgreich } from "assets/icons8-geprüft-100.svg";
import { useMediaQuery } from "react-responsive";
import { useSidebarShown } from "./ProfileSidebar.js";

const DataSaved = ({ show }) => {
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const sideBarShown = useSidebarShown();
  return (
    <div
      style={{
        backgroundColor: "#35aaa0",
        borderRadius: "8px",
        padding: "12px 50px",
        display: show ? "flex" : "none",
        position: "fixed",
        margin: "auto",
        left: sideBarShown ? "50%" : undefined,
        width: sideBarShown ? undefined : "calc(100% - 40px)",
        bottom: 50,
        boxShadow: "0 0 40px 5px rgba(34, 37, 42,0.3)",
        border: "1px solid #35aaa0",
        textAlign: "center",
        zIndex: 10000,
        alignItems: "center",
      }}
    >
      <Erfolgreich
        style={{
          width: "32px",
          height: 32,
          marginRight: is500 ? 50 : 20,
          fill: "#eaf6f5",
        }}
      />
      <div style={{ color: "#eaf6f5" }}>Erfolgreich gespeichert</div>
    </div>
  );
};

const useDataSaved = () => {
  const [showSaved, setShowSaved] = useState(false);
  useEffect(() => {
    if (showSaved === true) {
      const timeout = setTimeout(() => setShowSaved(false), 5000);
      window.scrollTo(0, 0);
      return () => {
        clearTimeout(timeout);
      };
    }
    return () => {};
  }, [showSaved]);
  return [showSaved, setShowSaved];
};

export { DataSaved, useDataSaved };
