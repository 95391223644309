import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SubmitButton, Footer, NoSearchHeader, Field } from "components";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { post } from "helpers/api";
import { useHistory, useSearch } from "helpers/navigation";
import { styleWrong, inputStyle } from "helpers/theme";
import background from "assets/Background_Login_Screen.svg";
import urls from "config/urls";

const ResetPWScreen = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [done, setDone] = useState(false);
  const history = useHistory();

  const { force, redirect, ...searchParams } = useSearch();
  const onSubmit = ({ email }, actions) => {
    if (email) {
      setWrong(false);
      setLoading(true);
      post("login/password/token")
        .data({ email, returnUrl: "https://" + urls.domain + "/newpw" })
        .on(401, () => {
          setWrong(true);
          setLoading(false);
        })
        .on(404, () => {
          setWrong(true);
          setLoading(false);
        })
        .then((me) => {
          setLoading(false);
          setDone(true);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (user.uuid && redirect && !force) {
      history.push(redirect, [], searchParams);
    } else if (user.uuid && !force) {
      history.push("");
    }
  }, [user, history, redirect, force, searchParams]);

  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {done ? (
          "Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen geschickt."
        ) : (
          <Formik
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(
                  "Unglültige Email-Addresse. Bitte überprüfe deine Eingabe."
                )
                .required("Bitte gib deine Email-Addresse ein."),
            })}
            onSubmit={onSubmit}
          >
            <Form
              style={{
                width: "min(500px, calc(100% - 20px))",
                padding: 40,
                paddingTop: 120,
              }}
            >
              <h2 style={{ marginBottom: 10 }}>Passwort zurücksetzen</h2>
              <Field
                style={inputStyle}
                placeholder={"Email"}
                name="email"
                type="email"
              />
              <SubmitButton loading={loading}>Jetzt zurücksetzen</SubmitButton>
              {wrong && (
                <div style={styleWrong}>
                  Ungültige Email-Adresse. Bitte überprüfe deine Eingabe.
                </div>
              )}

              <div style={{ textAlign: "right", margin: 8 }}>
                <Link
                  to="/login"
                  style={{ textDecoration: "underline", color: "#387bf5" }}
                >
                  Einloggen
                </Link>
              </div>
            </Form>
          </Formik>
        )}
      </div>
      <Footer />
    </>
  );
};
ResetPWScreen.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(({ user }) => ({ user }), {})(ResetPWScreen);
