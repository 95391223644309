import React from "react";
import { Loader } from "semantic-ui-react";

const CardList = ({ cards, none, card, ...rest }) => {
  if (cards === false) {
    return <Loader active inline />;
  }
  if (cards.length === 0) {
    return <div>{none}</div>;
  }
  return cards.map((r) => card({ key: r.id, ...r, ...rest }));
};

export default CardList;
