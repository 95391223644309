import React from "react";
import { useMediaQuery } from "react-responsive";

const StepDisplay = ({ count, current }) => {
  const isMedium = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <div>
      <div
        style={{
          marginLeft: "5px",
          color: "rgba(49, 49, 63, 0.4)",
          fontWeight: 600,
          fontSize: "15px",
          textAlign: "center",
          marginTop: 20,
        }}
      >
        Schritt {current} von 2
      </div>
      <div
        style={{
          display: "flex",
          paddingLeft: isMedium ? 50 : 10,
          paddingRight: isMedium ? 50 : 10,
          justifyContent: "center",
          marginBottom: 55,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "Center",
            maxWidth: 500,
            width: "100%",
          }}
        >
          {"x"
            .repeat(count)
            .split("")
            .map((_, i) => (
              <>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: 100,
                    backgroundColor: current > i ? "#387bf5" : "#edf0f6",
                    fontSize: 16,
                    fontWeight: 600,
                    color: current > i ? "white" : "#595963",
                    textAlign: "center",
                    lineHeight: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {i + 1}
                </div>
                {i < count - 1 && (
                  <div
                    style={{
                      flexGrow: 2,
                      height: "3px",
                      backgroundColor: current - 1 > i ? "#387bf5" : "#edf0f6",
                    }}
                  ></div>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StepDisplay;
