import { useEffect, useState } from "react";

const usePagination = (pageSize, query, queryDependents) => {
  const [anythingLoaded, setAnythingLoaded] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAnythingLoaded(false);
    setLoading(true);
    query(0).then((newValues) => {
      setAllLoaded(newValues.length < pageSize);
      setAnythingLoaded(true);
      setValues(newValues);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [pageSize, ...queryDependents]);

  const loadMore = () => {
    if (!allLoaded && !loading && anythingLoaded) {
      setLoading(true);
      const page = Math.floor((values || []).length / pageSize);
      query(page).then((newValues) => {
        setValues((values) => {
          const vIds = (values || []).map(({ id }) => id);
          setLoading(false);
          return [
            ...(values || []),
            ...newValues.filter(({ id }) => vIds.indexOf(id) === -1),
          ];
        });
      });
    }
  };

  return { loadMore, values, allLoaded, loading };
};

export default usePagination;
