import React from "react";

const Price = ({ price, trialLesson, twoLines, style }) => (
  <div style={{ textAlign: "right", color:"#387bf5", lineHeight: "10px", ...style }}>
    {price}€ <span style={{ color: "#d4d6db" }}>/ Std.</span>
    {trialLesson ? (
      <span
        style={{
          color: "#9138f5",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "15px",
          paddingLeft: twoLines ? 0 : 25,
        }}
      >
        {twoLines && <br />}
        1. Std. Gratis
      </span>
    ) : (
      ""
    )}
  </div>
);

export default Price;
