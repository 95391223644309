import { useEffect } from "react";
import { useHistory, useRedirector } from "helpers/navigation";

const useIsLoggedIn = (user) => {
  const history = useHistory();
  const redirectQuery = useRedirector();
  useEffect(() => {
    if (!user.uuid) {
      history.push("login", [], redirectQuery.obj);
    }
  }, [user.uuid, history, redirectQuery]);
};

export default useIsLoggedIn;
