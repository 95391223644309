import configureStore from "@apparts/redux";

import user from "./user";
import fetching from "./fetching";
import network from "./network";

const { store, persistor } = configureStore({ user, fetching, network });

export { persistor };
export default store;
