import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SubmitButton, Footer, NoSearchHeader, Field } from "components";
import { Loader } from "semantic-ui-react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { patch } from "helpers/api";
import { useHistory, useSearch } from "helpers/navigation";
import { loadUser } from "redux/user";
import { styleWrong, inputStyle } from "helpers/theme";
import background from "assets/Background_Login_Screen.svg";

const VerifyScreen = ({ user, loadUser }) => {
  const [loading, setLoading] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [done, setDone] = useState(false);

  const { v: token, type, redirect, ...searchParams } = useSearch();
  const history = useHistory();

  useEffect(() => {
    if (type === "email" && !loading && !done) {
      setLoading(true);
      patch("verifications/email")
        .data({ token })
        .on(404, () => {
          alert(
            "Der Verifizierungslink scheint abgelaufen zu sein. Bitte versuche es erneut."
          );
        })
        .then(() => {
          loadUser().then(() => {
            setDone(true);
            setLoading(false);
            setTimeout(() => {
              history.push("", []);
            }, 1000);
          });
        })
        .catch((e) => {
          if (e) {
            setLoading(false);
            alert(e);
          }
        });
    }
  }, [
    token,
    type,
    setDone,
    loadUser,
    history,
    loading,
    done,
    redirect,
    searchParams,
  ]);

  const onSubmit = ({ code }, actions) => {
    if (code) {
      setWrong(false);
      setLoading(true);
      patch("verifications/phone")
        .data({ token: code, userId: user.uuid })
        .then((me) => {
          loadUser().then(() => {
            setLoading(false);
            setDone(true);
            setTimeout(() => {
              history.push(redirect, [], searchParams);
            }, 1000);
          });
        })
        .catch((e) => {
          setLoading(false);
          setWrong(true);
        });
    }
  };

  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        {done ? (
          "Erfolgreich verifiziert."
        ) : type === "email" ? (
          <Loader inline active={loading} />
        ) : (
          <Formik
            initialValues={{ code: "" }}
            validationSchema={Yup.object({
              code: Yup.string()
                .matches(
                  /[\d]{6}/,
                  "Bitte gib den gesamten Code ein, den du per SMS erhalten hast. Der Code sollte aus sechs Zahlen bestehen."
                )
                .required("Bitte gib deinen Code ein."),
            })}
            onSubmit={onSubmit}
          >
            <Form
              style={{
                width: "min(500px, calc(100% - 20px))",
                padding: 40,
                paddingTop: 120,
              }}
            >
              <h2 style={{ marginBottom: 10 }}>Telefonnummer verifizieren</h2>
              <Field style={inputStyle} placeholder={"Code"} name="code" />
              <SubmitButton loading={loading}>Jetzt verifizieren</SubmitButton>
              {wrong && (
                <div style={styleWrong}>
                  Der eingegebene Code scheint falsch zu sein. Bitte verifiziere
                  deine Telefonnummer erneut.
                </div>
              )}
            </Form>
          </Formik>
        )}
      </div>
      <Footer />
    </>
  );
};
VerifyScreen.propTypes = {
  user: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired,
};

export default connect(({ user }) => ({ user }), { loadUser })(VerifyScreen);
