import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSearch } from "helpers/navigation";
import { connect } from "react-redux";
import * as getFingerprint from "@apparts/fingerprint";
import log, { flushLogQueue } from "./log";

let createFingerprintTimeout;
export const createFingerprint = () => {
  if (createFingerprintTimeout) {
    clearTimeout(createFingerprintTimeout);
  }
  createFingerprintTimeout = true;
  if (sessionStorage.getItem("fp")) {
    window.fp = sessionStorage.getItem("fp");
    return flushLogQueue();
  }
  return getFingerprint().then((fp) => {
    sessionStorage.setItem("fp", fp);
    window.fp = fp;
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-line no-undef

      const oldfp = localStorage.getItem("fp");
      localStorage.setItem("fp", fp);
      if (oldfp !== window.fp) {
        console.log("Old", oldfp);
        console.log("New", fp);
        alert("fp changed");
      }
    }
    return flushLogQueue();
  });
};

const Tracker = withRouter(({ children, location, addTrackingId, user }) => {
  const { mid } = useSearch();
  useEffect(() => {
    if (mid) {
      sessionStorage.setItem("mid", mid);
    }
  }, [mid]);
  useEffect(() => {
    if (!createFingerprintTimeout) {
      createFingerprintTimeout = setTimeout(() => {
        createFingerprint();
      }, 2000);
    }
  }, []);
  useEffect(() => {
    log(user.uuid, "href", { href: location.pathname + location.search, mid });
  }, [location, user.uuid, mid]);

  return children;
});

export default connect(({ user }) => ({ user }), {})(Tracker);
