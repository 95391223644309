import React from "react";
import { Label } from "components";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Active, Price, Star } from "components/Profile";

const TutorCard = ({
  firstName,
  lastName,
  profilePicture,
  description,
  price,
  active,
  trialLesson,
  starred,
  modules,
  module,
  course,
  id,
  setFavorite,
}) => {
  const isMedium = useMediaQuery({ query: "(min-width: 700px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isWide = useMediaQuery({ query: "(min-width: 1250px)" });
  const is1600 = useMediaQuery({ query: "(min-width: 1600px)" });

  const courseName = (
    modules.filter(({ courseId }) => courseId === course)[0] || {}
  ).course;

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 5,
        padding: isMedium ? 25 : 10,
        width: is1600 ? "29%" : isTablet ? "45%" : "94%",
        margin: isTablet ? (is1600 ? "2.166%" : "2.5%") : "3%",
        marginBottom: isWide ? 20 : 30,
        marginTop: isMedium ? 20 : 8,
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Link to={"/profile/" + id}>
        <div
          className="button buttoncard"
          style={{
            display: "flex",
          }}
        >
          <div>
            <img
              alt={`Bild von ${firstName}`}
              src={profilePicture}
              style={{ borderRadius: 1000, width: 70, height: 70 }}
            />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: 600,
              paddingLeft: 20,
              flex: 1,
              alignSelf: "flex-end",
              color: "rgb(33, 37, 41)",
            }}
          >
            {firstName}
            <br />
            <div
              style={{
                fontSize: "10px !important",
                fontWeight: 600,
              }}
            >
              <Label
                style={{ fontWeight: 600, fontSize: "11px" }}
                text={courseName}
              />
              {module && (
                <Label style={{ fontWeight: 600 }} text={module} secondary />
              )}
            </div>
          </div>
        </div>
      </Link>

      <Star
        id={id}
        starred={starred}
        setFavorite={setFavorite}
        style={{
          margin: "-10px",
          position: "absolute",
          top: 15,
          right: 15,
          width: "auto",
        }}
      />
      <div
        style={{
          flex: 1,
          paddingTop: 10,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {description}
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "6px",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Active
          active={active}
          style={{ marginTop: 0, fontWeight: 500, fontSize: "12px" }}
        />
        <Price twoLines price={price} trialLesson={trialLesson} />
      </div>
    </div>
  );
};

export default TutorCard;
