import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Footer, NoSearchHeader, Signup } from "components";
import { connect } from "react-redux";

import { useHistory, useSearch } from "helpers/navigation";

import background1 from "../assets/BackgroundRegistrieren.svg";

const SignupScreen = ({ user, login }) => {
  const history = useHistory();

  const { redirect, ...searchParams } = useSearch();
  useEffect(() => {
    if (user.uuid && redirect) {
      history.push(redirect, [], searchParams);
    } else if (user.uuid) {
      history.push("");
    }
  }, [user, history, redirect, searchParams]);

  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundImage: `url(${background1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Signup />
      </div>
      <Footer />
    </>
  );
};
SignupScreen.propTypes = {
  user: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};

export default connect(({ user }) => ({ user }), {})(SignupScreen);
