const defaultState = { tutor: {}, notifications: [] };

export default (types) => {
  const notificationsReducer = (state = [], action = {}) => {
    switch (action.type) {
      case types.SET_NOTI_READ.name:
        return state.map((n) =>
          n.id !== action.id ? n : { ...n, read: action.read }
        );
      default:
        return state;
    }
  };

  return (state = defaultState, action = {}) => {
    switch (action.type) {
      case types.USER_LOGIN.name:
        return { ...defaultState, ...action.user, auth: action.user.apiKey };
      case types.USER_LOGOUT.name:
        return defaultState;
      case types.SET_TUTOR.name:
        return { ...state, tutor: action.tutor || {}, isTutor: true };
      case types.SET_USER.name:
        if (action.user.apiKey) {
          return { ...state, ...action.user, auth: action.user.apiKey };
        } else {
          return { ...state, ...action.user };
        }
      case types.SET_NOTIS.name:
        return { ...state, notifications: action.notifications };
      case types.SET_NOTI_READ.name:
        return {
          ...state,
          notifications: notificationsReducer(state.notifications, action),
        };
      default:
        return state;
    }
  };
};
