import { useApi, Request } from "@apparts/api";

import { useRedirector as getRedirect } from "helpers/navigation";
import { useState, useEffect } from "react";
import { setOffline, setOnline } from "redux/network";
import store from "redux/store";
import isDev from "helpers/isDev";

const APIVERSION = 1;

const URL = isDev()
  ? "https://kaktus.studybear.de/v"
  : "https://api.studybear.de/v";

const logout = () => {
  // Log out user on 401 HTTP-Error
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-line no-undef
    alert(401);
  } else {
    const redirectQuery = getRedirect();
    window.location.href =
      "/login?force=true&" + (redirectQuery.str || "").substr(1);
  }
};

class MyRequest extends Request {
  constructor(...ps) {
    super(...ps);
    this._auth = {
      headers: {
        Authorization: "Mvwd1n4W9RmWHmEx0Pc1gJWNvb3JFF3DGDZmJhX1bsc=",
      },
    };
  }

  getURL(apiVersion) {
    // Tell Request what the URL prefix is
    return URL + apiVersion;
  }
  getAPIVersion() {
    // Tell Request what the default APIVersion is
    return APIVERSION;
  }

  online() {
    store.dispatch(setOnline());
  }

  auth(auth) {
    // Define a method for authenticating with a user token.
    // This will be called by you, when you want to authenticate with a user
    if (auth) {
      if (typeof auth !== "string") {
        throw new Error("Request.auth: parameter auth must be a String!");
      }

      this._auth = {
        headers: {
          Authorization: auth,
        },
      };
    }

    return this;
  }

  async middleware() {
    // Tell Request what to do on recieving not-yet caught errors, that should be
    // handled globally.

    this.on(410, () =>
      alert(
        "Eine neuere Version dieser Website ist verfügbar. Bitte lade diese Seite neu um sie zu benutzen."
      )
    );
    this.on(401, logout);
    this.on(0, () => {
      store.dispatch(setOffline());
    });
  }
}

const { get, put, patch, post, del } = useApi(MyRequest);

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export { URL, get, put, patch, post, del, useDebounce };
