import { useApi, Request } from "@apparts/api";
let logQueue = [];

const URL =
  process.env.NODE_ENV === "development" || // eslint-disable-line no-undef
  window.location.host === "dev.unitutors.de"
    ? "https://kaktus.studybear.de/e/"
    : "https://api.studybear.de/e/";

class MyRequest extends Request {
  getURL(apiVersion) {
    return URL + apiVersion;
  }
  getAPIVersion() {
    return 1;
  }
}

const { post } = useApi(MyRequest);

const log = async (...args) => {
  const [userid, event, data] = args;
  if (typeof window.fp === "string") {
    try {
      await post("events").data({
        user_id: userid,
        device_id: window.fp,
        event_type: event,
        data: {
          ...data,
          referrer: document.referrer,
          ua: window.navigator.userAgent,
        },
      });
    } catch (e) {}
  } else {
    logQueue.push(args);
  }
};

export const flushLogQueue = () => {
  const res = Promise.all(
    logQueue.map((args) => {
      return log(...args);
    })
  );
  logQueue = [];
  return res;
};

export default log;
