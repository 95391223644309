import React from "react";
import { useMediaQuery } from "react-responsive";

import background from "assets/Background_Payment_Page.svg";

import { colors } from "helpers/theme";

const Display = ({
  trialLesson,
  children,
  coursePriceTotal,
  courseModuleShortcut,
  courseStudy,
  courseDuration,
  coursePriceHour,
  courseImg,
}) => {
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const is1000 = useMediaQuery({ query: "(min-width: 1000px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <div
      style={{
        width: "100%",
        paddingLeft: isTablet ? 30 : 10,
        paddingRight: isTablet ? 30 : 10,
        paddingTop: 180,
        paddingBottom: 150,
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1024,
          width: "100%",
          display: "flex",
          flexDirection: is1000 ? "" : "column",
        }}
      >
        <div
          style={{
            flex: 2,
            marginLeft: is1000 ? 100 : 0,
            marginRight: is1000 ? 100 : 0,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: is500 ? "40px" : "28px",
                  marginBottom: 6,
                }}
              >
                Online Crashkurs
              </h1>
              <h2 style={{ fontSize: is500 ? "22px" : "18px" }}>
                Klausurvorbereitung
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  color: colors.color1[11],
                  backgroundColor: colors.primary,
                  fontWeight: 700,
                  fontSize: is500 ? "34px" : "26px",
                  borderRadius: 8,
                  padding: is500 ? "14px 10px" : "10px",
                  transform: "rotate(0deg)",
                }}
              >
                {coursePriceTotal}€
              </div>
              <div>{coursePriceHour} € pro Stunde </div>
            </div>
          </div>
          <div style={{ marginTop: is1000 ? 50 : 70 }}>
            <ul style={{ fontSize: "16px" }}>
              <li>
                {courseModuleShortcut} für {courseStudy}
              </li>
              <li>{courseDuration}</li>
              <li>15 Minuten Einzelunterricht mit dem Tutor</li>
              <li>Student Pass im Wert von 29 Euro inkl.</li>
            </ul>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Display;
