import React from "react";
import { useField } from "formik";
import { ErrorMessage } from "formik";
import { Checkbox } from "semantic-ui-react";

const ToggleSwitchRaw = ({
  name,
  onChange,
  value,
  text,
  textAlign = "right",
}) => {
  return (
    <label
      name={name}
      style={{ display: "flex", alignItems: "center", marginTop: 10 }}
    >
      {textAlign === "left" && (
        <span style={{ flex: 1, marginRight: 10 }}> {text} </span>
      )}
      <Checkbox
        toggle
        id={name}
        name={name}
        onChange={(e) => {
          onChange(e);
        }}
        checked={value}
      />
      {textAlign === "right" && (
        <span style={{ flex: 1, marginLeft: 10 }}> {text} </span>
      )}
    </label>
  );
};

const ToggleSwitch = ({ name, text, style, ...rest }) => {
  const [{ value, onChange }, meta, { setTouched }] = useField(name);
  return (
    <div style={style}>
      <ToggleSwitchRaw
        {...rest}
        name={name}
        onChange={(...ps) => {
          setTouched(true, false);
          onChange(...ps);
        }}
        value={value}
        text={text}
      />
      <ErrorMessage name={name} {...meta} />
    </div>
  );
};
export { ToggleSwitchRaw };
export default ToggleSwitch;
