import { useEffect, useRef } from "react";
import * as R from "ramda";

const useEffectWhen = (func, whatchers, when) => {
  const refs = useRef([]);
  useEffect(() => {
    if (
      !R.zip(refs.current, when).reduce((a, [b1, b2]) => a && b1 === b2, true)
    ) {
      return func();
    }
    // eslint-disable-next-line
  }, whatchers);
  useEffect(() => {
    refs.current = when;
    // eslint-disable-next-line
  }, when);
};

export { useEffectWhen, R };
