import React, { useState, useEffect } from "react";
import { Loader, Button } from "semantic-ui-react";
import { Footer, NoSearchHeader } from "components";
import { useHistory, useSearch } from "helpers/navigation";
import { connect } from "react-redux";
import { loadUser } from "redux/user";
import usePaypal from "./usePaypalButton";
import Display from "./CoursePaymentDisplay";
import { get, post } from "helpers/api";

const CoursePaymentPage = ({ user, loadUser, fetchingUser }) => {
  const { courseId } = useSearch();

  const history = useHistory();

  useEffect(() => {
    loadUser();
  }, [loadUser, user.auth]);

  const [availableCourses, setCourses] = useState([]);
  useEffect(() => {
    get("crashcourse").then((crashcourses) => {
      setCourses(crashcourses);
    });
  }, []);
  const course = availableCourses.filter(({ id: cId }) => cId == courseId)[0];

  usePaypal(
    [
      {
        buttonId: "#paypal-button-container",
        onApprove: () => {
          post("crashcourse/members")
            .data({
              userId: user.uuid,
              firstName: user.firstName,
              lastName: user.lastName,
              emailAddress: user.emailAddress,
              courseName: course && course.courseTitleSite,
              courseLink: "https://unitutors.de/course/" + courseId,
              testPeriod: false,
            })
            .then(() => {
              history.push("coursePaymentDone", [], { courseId });
            })
            .catch(() => {
              alert(
                "Es ist etwas schiefgegangen. Bitte versuche es später nochmal"
              );
            });
        },
        price: course && course.coursePriceTotal,
      },
    ],
    course && user.auth
  );

  if (!course) {
    return <Loader active />;
  }
  return (
    <>
      <NoSearchHeader />
      <Display {...course}>
        <h3 style={{ marginTop: 80 }}>Zahlungsart</h3>
        <div id="paypal-button-container"></div>
      </Display>
      <Footer />
    </>
  );
};

export default connect(
  ({ user, fetching: { user: fetchingUser } }) => ({ user, fetchingUser }),
  { loadUser }
)(CoursePaymentPage);
