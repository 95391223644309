import React from "react";

import { Popup } from "semantic-ui-react";
import { ReactComponent as Hilfe } from "assets/icons8-hilfe-100.svg";
import { ReactComponent as Attention } from "assets/icons8-achtung-100.svg";

const ExplainPopup = ({ popupContent, popupText, attention }) => (
  <Popup
    content={popupContent}
    position="top right"
    trigger={
      <div
        style={{
          fontSize: 12,
          paddingLeft: 10,
          display: "flex",
          alignItems: "center",
          color: "#387bf5",
        }}
        className="hilfe_popup"
      >
        {popupText}{" "}
        {attention ? (
          <Attention
            style={{
              height: "18px",
              width: "auto",
              fill: "#387bf5",
              marginLeft: 6,
            }}
          />
        ) : (
          <Hilfe
            style={{
              height: "18px",
              width: "auto",
              fill: "#387bf5",
              marginLeft: 6,
            }}
          />
        )}
      </div>
    }
  />
);

export default ExplainPopup;
