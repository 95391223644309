import { get, put } from "helpers/api";
import { setFetching } from "../fetching";

export const setTutorFetching = () => setFetching("tutor");
export const setUserFetching = () => setFetching("user");

export const login = (user) => (dispatch, getState) => {
  dispatch(_login(user));
  dispatch(loadUser());
  dispatch(loadNotifications());
};

const _login = (user) => ({
  type: "USER_LOGIN",
  user,
});

export const loadTutor = () => (dispatch, getState) => {
  const { user } = getState();
  dispatch(setTutorFetching());
  get("users/tutors/$1", [user.uuid])
    .auth(user.auth)
    .on(404, () => {})
    .then((res) => {
      dispatch(_setTutor(res));
    })
    .catch(() => {});
};

const _setTutor = (tutor) => ({
  type: "SET_TUTOR",
  tutor,
});

export const loadUser = () => (dispatch, getState) => {
  const { user } = getState();
  dispatch(setUserFetching());
  return new Promise((resolve) => {
    get("users/$1", [user.uuid])
      .auth(user.auth)
      .on(404, () => {})
      .then((res) => {
        dispatch(_setUser(res));
        resolve();
      })
      .catch(() => {
        resolve();
      });
  });
};

export const makeTutor = () => (dispatch, getState) => {
  dispatch(loadUser());
  dispatch(_makeTutor());
};

export const _makeTutor = () => ({
  type: "SET_USER",
  user: { isTutor: true },
});

const _setUser = (user) => ({
  type: "SET_USER",
  user,
});

export const logout = (user) => ({
  type: "USER_LOGOUT",
  user,
});

export const loadNotifications = () => (dispatch, getState) => {
  const { user } = getState();
  return new Promise((res, rej) => {
    if (user.uuid) {
      get("users/$1/notifications", [user.uuid])
        .auth(user.auth)
        .then(({ notifications }) => {
          dispatch(
            setNotis(
              notifications.map(
                ({ acknowledged: read, notificationType: type, ...rest }) => ({
                  read,
                  type,
                  ...rest,
                })
              )
            )
          );
          res();
        })
        .catch(() => {
          setNotis([]);
          res();
        });
    }
  });
};

const setNotis = (notifications) => ({
  type: "SET_NOTIS",
  notifications,
});

export const setNotiRead =
  (id, read = true) =>
  (dispatch, getState) => {
    const { user } = getState();
    return new Promise((res, rej) => {
      if (user.uuid) {
        put("users/$1/notifications/$2/status", [user.uuid, id])
          .auth(user.auth)
          .data({ acknowledged: read })
          .then(() => {
            dispatch(_setNotiRead(id, read));
          })
          .catch(() => {
            rej();
          });
      }
    });
  };

const _setNotiRead = (id, read) => ({
  type: "SET_NOTI_READ",
  id,
  read,
});

export const getUnreadNotis = (notifications) =>
  notifications?.filter(({ read }) => !read);

export default [
  _login().type,
  logout().type,
  _setTutor().type,
  _setUser().type,
  setNotis().type,
  _setNotiRead().type,
];
