import React from "react";
import { useMediaQuery } from "react-responsive";

import background from "assets/Background_Payment_Page.svg";
import background2 from "assets/Background_PaymentPage_Tutor.svg";

import { ReactComponent as IconGarantie } from "assets/Icon_Geld_Zurück.svg";
import { ReactComponent as IconTutorenKontakt } from "assets/Icon_Tutoren_Suchen.svg";

import { colors } from "helpers/theme";

const DEFAULTPRICE = 29;

const Display = ({
  tId,
  firstName,
  profilePicture,
  trialLesson,
  children,
  price = DEFAULTPRICE,
}) => {
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const is1000 = useMediaQuery({ query: "(min-width: 1000px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <div
      style={{
        width: "100%",
        paddingLeft: isTablet ? 30 : 10,
        paddingRight: isTablet ? 30 : 10,
        paddingTop: 180,
        paddingBottom: 150,
        backgroundImage: tId ? `url(${background2})` : `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1024,
          width: "100%",
          display: "flex",
          flexDirection: is1000 ? "" : "column",
        }}
      >
        {tId && (
          <>
            <div style={{ marginBottom: is1000 ? "" : 80 }}>
              <div
                style={{
                  borderRadius: 8,
                  marginLeft: isTablet ? "" : 10,
                  marginRight: isTablet ? "" : 10,
                  border: "1px solid",
                  borderColor: "#edf0f6",
                  width: "auto",
                  padding: is500 ? "30px 80px" : "30px 40px",
                  textAlign: "center",
                  boxShadow: " 5px 10px 60px 5px rgba(36, 52, 78, 0.0470588)",
                }}
              >
                <img
                  alt={`Bild von ${firstName}`}
                  src={profilePicture}
                  style={{
                    borderRadius: 1000,
                    width: 150,
                    height: 150,
                    marginBottom: 20,
                  }}
                />
                <h2 style={{ fontWeight: 500 }}>{firstName}</h2>
                {trialLesson && (
                  <div
                    style={{
                      color: "#9138f5",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    1. h Gratis
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div
          style={{
            flex: 2,
            marginLeft: is1000 ? 100 : 0,
            marginRight: tId ? "" : is1000 ? 100 : 0,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: is500 ? "40px" : "28px",
                  marginBottom: 6,
                }}
              >
                Student Pass
              </h1>
              <h2 style={{ fontSize: is500 ? "22px" : "18px" }}>
                Semester Abonnement
              </h2>
            </div>
            <div>
              {price !== DEFAULTPRICE && (
                <div
                  className="strikeThrough"
                  style={{
                    color: colors.stateRed[5],
                    backgroundColor: colors.stateRed[2],
                    textDecoration: "line-through",
                    fontWeight: 700,
                    fontSize: is500 ? "21px" : "18px",
                    borderRadius: 8,
                    padding: is500 ? "4px 15px" : "1px 10px",
                    transform: "rotate(-10deg)",
                    width: "min-content",
                    marginBottom: -6,
                    marginLeft: -14,
                  }}
                >
                  {DEFAULTPRICE}€
                </div>
              )}
              <div
                style={{
                  color: colors.color1[11],
                  backgroundColor: colors.primary,
                  fontWeight: 700,
                  fontSize: is500 ? "34px" : "26px",
                  borderRadius: 8,
                  padding: is500 ? "14px 10px" : "10px",
                  transform: "rotate(0deg)",
                }}
              >
                {price}€
              </div>
            </div>
          </div>
          <div style={{ marginTop: is1000 ? 50 : 70 }}>
            <div
              style={{
                display: "flex",
                alignItems: isTablet ? "center" : "flex-start",
              }}
            >
              <div>
                <IconGarantie style={{ width: 66, height: "auto" }} />
              </div>
              <div style={{ marginLeft: isTablet ? 40 : 20, fontSize: "16px" }}>
                <p style={{ lineHeight: "25px", fontWeight: 700 }}>
                  Geld-zurück-Garantie
                </p>
                <p>
                  Wenn deine angefragten Tutoren keine Zeit hatten oder du mit
                  dem Unterricht nicht zufrieden warst, bekommst du dein Geld
                  für den Student Pass zurück.{" "}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: isTablet ? "center" : "flex-start",
                marginTop: is1000 ? 40 : 60,
              }}
            >
              <div>
                <IconTutorenKontakt style={{ width: 66, height: "auto" }} />
              </div>
              <div style={{ marginLeft: isTablet ? 40 : 20, fontSize: "16px" }}>
                <p style={{ lineHeight: "25px", fontWeight: 700 }}>
                  Unbegrenzt Tutoren kontaktieren
                </p>
                <p>
                  Mit dem Student Pass von Unitutors kannst du ein Semester (6
                  Monate) lang so viele Tutoren kontaktieren wie du möchtest.
                </p>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Display;
