import isDev from "helpers/isDev";

const payment = () => ({
  stripe: isDev()
    ? {
        publishableKey:
          "pk_test_51HX5CMBxY48wfjEXvr2GBOoC9L8CeaW0AEjbQQg9X4fsKrOfCch97RuwBUvMBk511OJUxeXeNt8LKkzylTzVBIIp003T3cnlZI",
        priceId: "price_1HYtTgBxY48wfjEXKK6Cf43h",
      }
    : {
        publishableKey:
          "pk_live_51HX5CMBxY48wfjEXFQnaoFzWnQD9aaijUcw1C3zHDRYbIY5Xp8GUR2hpf4grbIYGts3UCx7oL0Qgf06hwnRU7pXP00ZX5uJp9n",
        priceId: "price_1HX5SZBxY48wfjEXZBjLWooh",
      },
  actionCodes: {
    EARLYBIRD: {
      price: 14,
      text: "Early Bird Bonus 🐦: Student Pass zum halben Preis!",
    },
    TUTOR50: {
      price: 14,
      text: "Spare über 50% 💰: Student Pass zum halben Preis!",
    },
    HELLOTUTOR: {
      price: 14,
      text: 'Wir sagen "Hallo" 👋: Student Pass zum halben Preis!',
    },
  },
});

export default payment();
