import React, { useState } from "react";
import { post } from "helpers/api";
import { useHistory, useSearch } from "helpers/navigation";
import { Formik, Form, Field as FField } from "formik";
import * as Yup from "yup";
import { ErrorMessage, SubmitButton, Field } from "components";
import { Link } from "react-router-dom";

import { login } from "redux/user";
import { connect } from "react-redux";

import log from "helpers/tracking";

import werbungIcon from "../assets/forbidden.svg";
import urls from "config/urls";

const Signup = ({ tutor, login }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { redirect, ...searchParams } = useSearch();
  const onSubmit = (
    { email, password, firstName, lastName, legal },
    actions
  ) => {
    if (email && password && firstName && lastName && legal) {
      setLoading(true);
      post("users")
        .data({
          emailAddress: email,
          password,
          firstName,
          lastName,
          description: "",
        })
        .on(409, () => {
          alert(
            "Es existiert bereits ein Accounts mit dieser E-Mail-Adresse. Versuche Dich einzuloggen oder dein Passwort zurückzusetzen."
          );
          setLoading(false);
        })
        .on(401, () => {
          setLoading(false);
        })
        .then(({ uuid, apiKey }) => {
          log(uuid, "signup", { mid: window.mid });
          post("login")
            .data({ emailAddress: email, password })
            .on(401, () => {
              setLoading(false);
              history.push("login");
            })
            .then(({ uuid, apiKey }) => {
              setLoading(false);
              login({ uuid, apiKey, email });
              post("verifications/email")
                .auth(apiKey)
                .data({
                  userId: uuid,
                  returnUrl: "https://" + urls.domain + "/verify?type=email",
                })
                .then(() => {})
                .catch(() => {});

              if (tutor) {
                history.push("tutorprofile");
              } else {
                if (redirect) {
                  history.push(redirect, [], searchParams);
                } else {
                  history.push("profile");
                }
              }
            })
            .catch((e) => {
              console.log(e);
              setLoading(false);
            });
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        legal: false,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Bitte gib deinen Vornamen ein."),
        lastName: Yup.string().required("Bitte gib deinen Nachnamen ein."),
        email: Yup.string()
          .email("Unglültige Email-Addresse. Bitte überprüfe deine Eingabe.")
          .required("Bitte gib eine Email-Addresse ein."),
        password: Yup.string().required("Bitte gib ein Passwort ein."),
        legal: Yup.bool().oneOf(
          [true],
          "Bitte bestätige, dass du die AGB und Datenschutzbestimmungen gelesen hast und sie akzeptierst."
        ),
      })}
      onSubmit={onSubmit}
    >
      <Form
        style={{
          maxWidth: 500,
          padding: 40,
          paddingTop: tutor ? 0 : 120,
        }}
      >
        <h2 style={{ marginBottom: 10 }}>
          {!tutor && "Jetzt Profil erstellen"}
        </h2>
        <Field placeholder={"Vorname"} name="firstName" />
        <Field placeholder={"Nachname"} name="lastName" />
        <Field placeholder={"Email"} name="email" type="email" />
        <Field placeholder={"Passwort"} name="password" type="password" />
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <label style={{ display: "flex", alignItems: "center" }}>
            <FField
              name="legal"
              type="checkbox"
              style={{ margin: 10, marginRight: "15px" }}
            />
            <span style={{ flex: 1, lineHeight: "24px" }}>
              Ich akzeptiere die{" "}
              <Link style={{ lineHeight: "24px" }} to="/agb">
                AGB
              </Link>{" "}
              und die{" "}
              <Link style={{ lineHeight: "24px" }} to="/datenschutz">
                Datenschutzbedingungen
              </Link>
              .
            </span>
          </label>
          <ErrorMessage name="legal" />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="Durchfahrt verboten Schild. Wir verwenden keine Werbung."
            src={werbungIcon}
            style={{
              height: 18,
              marginLeft: 7,
              marginRight: 12,
            }}
          />
          <span style={{ lineHeight: "25px" }}>
            Wir verschicken keine Werbemails oder -SMS, sondern nur für dich
            relevante Benachrichtigungen
          </span>
        </div>
        <SubmitButton simple loading={loading}>
          Jetzt registrieren
        </SubmitButton>
      </Form>
    </Formik>
  );
};

export default connect(({ user }) => ({ user }), { login })(Signup);
