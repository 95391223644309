import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ToggleSwitch,
  Field,
  IfElse,
  FieldRow,
  ProfileSidebar,
  MultiSelectField,
  Wrapper,
  SubmitButton,
  PromptIfDirty,
  ExplainPopup,
} from "components";
import { DataSaved, useDataSaved } from "components/DataSavedPopup";
import AvatarPicker, { AvatarUpload } from "components/Profile/AvatarPicker";
import { Loader } from "semantic-ui-react";
import { useHistory } from "helpers/navigation";
import { connect } from "react-redux";
import { put, patch } from "helpers/api";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { ReactComponent as Chevron } from "assets/ChevronRight.svg";
import * as Yup from "yup";
import phoneRegexp from "helpers/phoneRegexp";
import { loadTutor, loadUser, makeTutor } from "redux/user";
import log from "helpers/tracking";

const sectionHeaderStyle = {
  fontSize: 20,
};

const sectionStyle = {
  marginTop: 40,
  ...sectionHeaderStyle,
};

const HeadingWithPopup = ({ heading, popupText, popupContent }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      ...sectionStyle,
    }}
  >
    <h3 style={sectionHeaderStyle}>{heading}</h3>
    <ExplainPopup popupContent={popupContent} popupText={popupText} />
  </div>
);

const BecomeTutor = ({
  user,
  loadTutor,
  loadUser,
  makeTutor,
  tutorFetching,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (!user.uuid) {
      history.push("tutorwerden");
    }
  }, [user, history]);

  useEffect(() => {
    loadTutor();
  }, [loadTutor, user.auth]);

  const [loading, setLoading] = useState(false);
  const onSubmit = (fields, actions) => {
    const {
      description,
      howYouWork,
      price,
      groups,
      location,
      groupSize,
      trialLesson,
      groupPrice,
      groupPriceType,
      phone,
      email,
      active,
    } = fields;
    if (description && howYouWork && price && location && phone && email) {
      setLoading(true);
      const isTutor = user.isTutor;
      put("users/tutors/$1", [user.uuid])
        .auth(user.auth)
        .data({
          lessonLocations: location, // StudentHome, TutorHome, Online, TBD
          description,
          shortDescription: "",
          methodDescription: howYouWork,
          price,
          active,
          trialLesson,
          phone,
          contactEmail: email,
          groupLessons: groups,
          groupMaxStudents: groupSize,
          groupPrice: groups ? groupPrice : 0,
          groupPriceType,
        })
        .then(({ success, error }) => {
          log(user.uuid, "becametutor", { mid: sessionStorage.getItem("mid") });
          setShowSaved(true);
          makeTutor();
          loadTutor();
          setLoading(false);
          if (!success) {
            alert(error);
          } else {
            if (!isTutor) {
              history.push("subjects", [], { signup: true });
            }
          }
          actions.setSubmitting(false);
          actions.resetForm({ values: fields });
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  const [picFile, setPicture] = useState(null);
  const [uploadingPicture, setPictureLoading] = useState(false);

  const uploadFoto = (file) => {
    setPicture(file);
  };

  const saveFoto = (data) => {
    setPictureLoading(true);
    const [format, picture] = data.split(",");
    patch("users/$1/profile-picture", [user.uuid])
      .data({ picture, format })
      .then(() => {
        loadUser();
        setPicture(null);
        setPictureLoading(false);
      });
  };

  const [showSaved, setShowSaved] = useDataSaved();

  const { firstName = "", lastName = "", profilePicture } = user;
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const isMedium = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      <Wrapper
        becomingTutor
        sidebar={user.isTutor && <ProfileSidebar current="tutorprofile" />}
      >
        {!user.tutor || (tutorFetching && user.isTutor) ? (
          <Loader active />
        ) : (
          <Formik
            initialValues={{
              description: user.description || "",
              howYouWork: user.tutor.methodDescription || "",
              price: "",
              groups:
                user.tutor.groupLessons === undefined
                  ? true
                  : user.tutor.groupLessons,
              location: user.tutor.lessonLocations || [],
              groupSize: user.tutor.groupMaxStudents || 6,
              trialLesson: true,
              groupPrice: "",
              groupPriceType: "Flat",
              phone: user.phone || "",
              email: user.tutor.contactEmail,
              active: true,
              ...user.tutor,
            }}
            validationSchema={Yup.object({
              active: Yup.boolean(),
              description: Yup.string()
                .min(
                  250,
                  "Bitte schreibe mehr über dich (zwischen 250 und 10000 Zeichen)."
                )
                .max(
                  10000,
                  "Bitte schreibe weniger über dich (zwischen 250 und 10000 Zeichen)."
                )
                .required(
                  "Bitte schreibe etwas über dich und dein Erfahrungen."
                ),
              howYouWork: Yup.string()
                .min(
                  250,
                  "Bitte schreibe mehr über deine Arbeitsweise (zwischen 250 und 10000 Zeichen)."
                )
                .max(
                  10000,
                  "Bitte schreibe weniger über deine Arbeitsweise (zwischen 250 und 10000 Zeichen)."
                )
                .required(
                  "Bitte schreibe etwas darüber, wie du deinen Studenten den Lehrstoff vermitteln möchtest."
                ),
              price: Yup.number("Bitte gib einen gültigen Preis ein")
                .typeError("Bitte gib eine gültigen Preis ein")
                .positive("Bitte gib einen gültigen Preis ein")
                .integer("Bitte gib einen ganzzahligen Preis ein")
                .required("Bitte gib einen Preis ein."),
              groups: Yup.boolean(),
              location: Yup.array().min(
                1,
                "Bitte gib an, wo du Unterricht geben möchtest. Beispiel: Beim Tutor, Online"
              ),
              groupSize: Yup.mixed().when("groups", {
                is: true,
                then: Yup.number("Bitte gib eine gültige Gruppengröße ein")
                  .typeError("Bitte gib eine gültige Gruppengröße ein")
                  .positive("Bitte gib eine gültige Gruppengröße ein")
                  .integer("Bitte gib eine gültige Gruppengröße ein"),
                otherwise: Yup.mixed().notRequired(),
              }),
              trialLesson: Yup.boolean(),
              groupPrice: Yup.mixed().when("groups", {
                is: true,
                then: Yup.number()
                  .typeError("Bitte gib eine gültigen Preis ein")
                  .positive("Bitte gib eine gültigen Preis ein")
                  .integer("Bitte gib eine ganzzahligen Preis ein")
                  .required("Bitte gib eine gültigen Preis ein"),
                otherwise: Yup.mixed().notRequired(),
              }),
              groupPriceType: Yup.string(),
              phone: Yup.string()
                .matches(
                  phoneRegexp,
                  "Bitte gib eine gültige Telefonnummer ein. (z.B. +4917...)"
                )
                .required("Bitte gib eine gültige Telefonnummer ein."),

              email: Yup.string()
                .email("Bitte gib eine gültige Email-Addresse ein.")
                .required("Bitte gib eine Email-Addresse ein."),
            })}
            onSubmit={onSubmit}
          >
            <Form style={{ width: "100%" }}>
              <DataSaved show={showSaved} />
              <PromptIfDirty />
              {!user.isTutor && (
                <>
                  <h1
                    style={{
                      fontSize: 34,
                      textAlign: "center",
                      marginTop: "30px",
                    }}
                  >
                    Tutor werden
                  </h1>
                  <div
                    style={{
                      marginLeft: "5px",
                      color: "rgba(49, 49, 63, 0.4)",
                      fontWeight: 600,
                      fontSize: "15px",
                      textAlign: "center",
                      marginTop: 40,
                      marginBottom: "5px",
                    }}
                  >
                    Schritt 2 von 3
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "Center",
                      marginBottom: 15,
                      width: "100%",
                      paddingLeft: is500 ? 50 : 0,
                      paddingRight: is500 ? 50 : 0,
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: 100,
                        backgroundColor: "#387bf5",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "white",
                        textAlign: "center",
                        lineHeight: "16px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      1
                    </div>
                    <div
                      style={{
                        flexGrow: 2,
                        height: "3px",
                        backgroundColor: "#387bf5",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: 100,
                        backgroundColor: "#387bf5",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "white",
                        textAlign: "center",
                        lineHeight: "16px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      2
                    </div>
                    <div
                      style={{
                        flexGrow: 2,
                        height: "3px",
                        backgroundColor: "#edf0f6",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: 100,
                        backgroundColor: "#edf0f6",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#595963",
                        textAlign: "center",
                        lineHeight: "16px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      3
                    </div>
                  </div>
                </>
              )}
              {user.isTutor && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: isMedium ? "flex-end" : "center",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      style={{ color: "#387bf5" }}
                      to={"profile/" + user.uuid}
                    >
                      So sieht dein Profil für andere aus
                      <Chevron
                        style={{
                          width: "12px",
                          height: 12,
                          fill: "#387bf5",
                          marginLeft: "4px",
                        }}
                      />
                    </Link>
                  </div>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "40px 0px 20px",
                  flexDirection: is500 ? "row" : "column",
                }}
              >
                <AvatarUpload
                  setPicture={uploadFoto}
                  firstName={firstName}
                  lastName={lastName}
                  profilePicture={profilePicture}
                />
                <h2
                  style={{
                    marginLeft: is500 ? 60 : 0,
                    marginTop: is500 ? "" : 25,
                    flex: 1,
                    fontSize: "26px",
                    fontWeight: "normal",
                  }}
                >
                  {firstName} {lastName}
                </h2>
                <ToggleSwitch
                  name="active"
                  text="Verfügbar"
                  style={{ color: "#387bf5" }}
                />
              </div>
              <HeadingWithPopup
                heading="Allgemeines"
                popupText="Was ist ein angemessener Stundenlohn?"
                popupContent={
                  <>
                    <p>
                      Das kommt auf deine Erfahrung und Qualifikationen an.
                      Viele Nachhilfe-Tutoren nehmen am Anfang zwischen 12€ und
                      20€ pro Stunde.
                    </p>
                  </>
                }
              />
              <FieldRow
                fields={[
                  <Field
                    placeholder={"Preis"}
                    name="price"
                    textRight="€ / 60 min"
                  />,
                  <Field
                    placeholder={"Erste Stunde gratis"}
                    name="trialLesson"
                    as="select"
                    textRight="▾"
                  >
                    <option value={true}>1. Stunde Gratis</option>
                    <option value={false}>Keine Gratisstunde</option>
                  </Field>,
                ]}
              />
              <MultiSelectField
                id="multiselect_ort"
                icon={null}
                name="location"
                placeholder="Ort"
                options={[
                  {
                    key: "StudentHome",
                    text: "Beim Studenten",
                    value: "StudentHome",
                  },
                  {
                    key: "TutorHome",
                    text: "Beim mir",
                    value: "TutorHome",
                  },
                  { key: "Online", text: "Online", value: "Online" },
                  { key: "TBD", text: "Nach Absprache", value: "TBD" },
                ]}
              />

              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  ...sectionStyle,
                }}
              >
                <h3 style={sectionHeaderStyle}>Gruppenunterricht</h3>
                <ToggleSwitch name="groups" />
              </div>
              <IfElse
                condition={({ groups }) => groups}
                then={
                  <>
                    <Field
                      placeholder={"Gruppengröße"}
                      name="groupSize"
                      as="select"
                      textRight="▾"
                    >
                      <option value={2}>bis zu 2 Studenten</option>
                      <option value={3}>bis zu 3 Studenten</option>
                      <option value={4}>bis zu 4 Studenten</option>
                      <option value={5}>bis zu 5 Studenten</option>
                      <option value={6}>bis zu 6 Studenten</option>
                      <option value={7}>bis zu 7 Studenten</option>
                      <option value={8}>bis zu 8 Studenten</option>
                      <option value={9}>bis zu 9 Studenten</option>
                      <option value={10}>bis zu 10 Studenten</option>
                    </Field>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 10,
                        marginBottom: "-12px",
                      }}
                    >
                      <ExplainPopup
                        popupText="Preismöglichkeiten für Gruppenunterricht"
                        popupContent={
                          <>
                            Für den Gruppenunterricht kannst du aus zwei
                            Preismodellen wählen:
                            <br />
                            <br />
                            <ol>
                              <li>
                                <b>"Pauschalpreis"</b>: Egal wie viele Studenten
                                bei dir Gruppenunterricht buchen, der Preis
                                bleibt derselbe.
                              </li>
                              <li>
                                <b>"Pro Person"</b>: Alle Gruppenteilnehmer
                                teilen den Grundpreis unter sich auf. Jeder
                                Student bezahlt außerdem X €. Den (Grund-) Preis
                                hast du weiter oben (unter "Allgemeines")
                                festlegen.
                              </li>
                            </ol>
                          </>
                        }
                      />
                    </div>
                    <FieldRow
                      fields={[
                        <Field
                          placeholder={"Preismodell"}
                          name="groupPriceType"
                          as="select"
                          textRight="▾"
                        >
                          <option value={"Flat"}>Pauschalpreis</option>
                          <option value={"PerPerson"}>Pro Person</option>
                        </Field>,
                        <IfElse
                          condition={({ groupPriceType }) =>
                            groupPriceType === "PerPerson"
                          }
                          then={
                            <Field
                              placeholder={"Gruppenpreis"}
                              name="groupPrice"
                              as="select"
                              textRight="▾"
                            >
                              <option value={0}>
                                Grundpreis + 0 € pro Person
                              </option>
                              <option value={1}>
                                Grundpreis + 1 € pro Person
                              </option>
                              <option value={2}>
                                Grundpreis + 2 € pro Person
                              </option>
                              <option value={3}>
                                Grundpreis + 3 € pro Person
                              </option>
                              <option value={4}>
                                Grundpreis + 4 € pro Person
                              </option>
                              <option value={5}>
                                Grundpreis + 5 € pro Person
                              </option>
                              <option value={6}>
                                Grundpreis + 6 € pro Person
                              </option>
                              <option value={7}>
                                Grundpreis + 7 € pro Person
                              </option>
                              <option value={8}>
                                Grundpreis + 8 € pro Person
                              </option>
                              <option value={9}>
                                Grundpreis + 9 € pro Person
                              </option>
                              <option value={10}>
                                Grundpreis + 10 € pro Person
                              </option>
                              <option value={11}>
                                Grundpreis + 11 € pro Person
                              </option>
                              <option value={12}>
                                Grundpreis + 12 € pro Person
                              </option>
                              <option value={13}>
                                Grundpreis + 13 € pro Person
                              </option>
                              <option value={14}>
                                Grundpreis + 14 € pro Person
                              </option>
                              <option value={15}>
                                Grundpreis + 15 € pro Person
                              </option>
                            </Field>
                          }
                          elze={
                            <Field
                              placeholder={"Pauschalpreis"}
                              name="groupPrice"
                              textRight="€"
                            />
                          }
                        />,
                      ]}
                    />
                  </>
                }
                elze={<div>Du bietest keinen Gruppenunterricht an.</div>}
              />
              <div style={{ height: 40 }}></div>
              <HeadingWithPopup
                heading="Kontakt"
                popupText="Warum muss ich diese Informationen angeben?"
                popupContent={
                  <>
                    <p>
                      Über deine E-Mail-Adresse und Telefonnummer können dich
                      deine Studenten erreichen. Nur wenn du eine Kontaktanfrage
                      annimmst, geben wir deine Daten weiter. Außerdem schicken
                      wir dir bei neuen Anfragen eine kurze Info.
                    </p>
                    <p>Wir schicken dir keine Werbung!</p>
                  </>
                }
              />
              <FieldRow
                fields={[
                  <Field placeholder={"Telefon"} name="phone" />,
                  <Field placeholder={"Kontakt-E-Mail"} name="email" />,
                ]}
              />
              <div style={{ height: 40 }}></div>
              <HeadingWithPopup
                heading="Über mich"
                popupText="Was kann ich hier schreiben?"
                popupContent={
                  <>
                    <p>
                      Dieser Teil deines Profils ist besonders wichtig. Die
                      Studenten kennen dich nicht und möchten dich durch dein
                      Profil kennenlernen.
                    </p>
                    <p>
                      Schreibe etwas über dich, deine Erfahrungen und deine
                      Kompetenzen. Interessant könnte außerdem sein, welche
                      Noten du in deinen angebotenen Fächern hast.
                    </p>
                    <p>
                      Dieser Text ist das erste, was Studenten von dir lesen.
                      Der erste Eindruck zählt!
                    </p>
                  </>
                }
              />
              <Field
                style={{
                  height: isMedium ? "200px" : "400px",
                }}
                as="textarea"
                placeholder={
                  "Schreibe etwas über dich, deine Kompetenzen und Erfahrungen."
                }
                name="description"
              />
              <HeadingWithPopup
                heading="Wie ich arbeite"
                popupText="Was kann ich hier schreiben?"
                popupContent={
                  <>
                    <p>
                      Schreibe etwas über deine Lernstrategie. Wie möchtest du
                      mit deinen Studenten lernen und ihnen die Lerninhalte
                      beibringen?
                    </p>
                    <p>
                      Außerdem kannst du hier bei Bedarf abweichende Tarife
                      angeben: Rabatt bei mehreren Stunden, Anfahrtskosten,
                      bestimmte Preise für bestimmte Fächer, ...
                    </p>
                  </>
                }
              />
              <Field
                style={{
                  height: isMedium ? "200px" : "400px",
                }}
                as="textarea"
                placeholder={
                  "Schreibe etwas darüber, wie du mit deinen Studenten lernst."
                }
                name="howYouWork"
              />
              <br />
              <SubmitButton loading={loading}>Daten speichern</SubmitButton>
            </Form>
          </Formik>
        )}
      </Wrapper>
      {picFile && (
        <AvatarPicker
          loading={uploadingPicture}
          file={picFile}
          onSave={saveFoto}
          onClose={() => setPicture(null)}
        />
      )}
    </>
  );
};
BecomeTutor.propTypes = {};

export default connect(
  ({ user, fetching: { tutor } }) => ({ user, tutorFetching: tutor }),
  {
    loadTutor,
    loadUser,
    makeTutor,
  }
)(BecomeTutor);
