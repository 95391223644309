import React from "react";
import "./carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Image } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

const BewertungNew = ({
  bewertungsHeadline,
  bewertungsText,
  bild,
  formLegend,
  sliderTextFontSize,
  sliderTextLineHeight,
  sliderHeadlineFontSize,
  sliderFormLegendFontSize,
}) => {
  const isMedium = useMediaQuery({ query: "(min-width: 700px)" });

  return (
    <form>
      <fieldset
        style={{
          width: "100%",
          padding: "10px 35px",
        }}
      >
        <legend
          className="w-auto text-left"
          style={{
            fontSize: sliderFormLegendFontSize,
          }}
        >
          {formLegend}
        </legend>
        <div
          style={{
            display: "flex",
            flexDirection: isMedium ? "row" : "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: 20,
              flexShrink: 0,
              width: "90px !important",
            }}
          >
            <Image
              src={bild}
              style={{
                borderRadius: "50% 0% 50% 50%",
                width: "80px",
                height: "80px",
              }}
            />
          </div>

          <div>
            <div
              style={{
                fontWeight: "bold",
                textAlign: isMedium ? "left" : "center",
                marginLeft: isMedium ? 30 : 0,
                marginRight: 10,
                marginTop: 20,
                fontSize: sliderHeadlineFontSize,
              }}
            >
              {bewertungsHeadline}
            </div>
            <div
              style={{
                textAlign: isMedium ? "left" : "center",
                marginTop: 8,
                marginLeft: isMedium ? 30 : 0,
                marginRight: 22,
                marginBottom: 22,
                fontSize: sliderTextFontSize,
                lineHeight: sliderTextLineHeight,
              }}
            >
              {bewertungsText}
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

const CustomSlider = ({
  sliderFormLegendFontSize,
  sliderHeadlineFontSize,
  sliderTextFontSize,
  sliderTextLineHeight,
  sliderHeadline,
  sliderText,
  sliderImage,
  sliderLegend,
}) => {
  return (
    <div style={{ maxWidth: "650px", height: "100%" }}>
      <Carousel
        infiniteLoop
        useKeyboardArrows
        showStatus={false}
        showThumbs={false}
        showArrows={false}
      >
        {sliderHeadline.map((_, i) => (
          <div key={i} style={{ width: "100%" }}>
            <BewertungNew
              bewertungsHeadline={sliderHeadline[i]}
              bewertungsText={sliderText[i]}
              bild={sliderImage[i]}
              formLegend={sliderLegend}
              sliderTextFontSize={sliderTextFontSize}
              sliderTextLineHeight={sliderTextLineHeight}
              sliderHeadlineFontSize={sliderHeadlineFontSize}
              sliderFormLegendFontSize={sliderFormLegendFontSize}
            ></BewertungNew>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CustomSlider;
