import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Footer, SearchHeader, Label } from "components";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { get, post, patch } from "helpers/api";
import { loadUser } from "redux/user";
import { Button, Loader } from "semantic-ui-react";
import {
  buttonStyle,
  buttonStyleDoubleShadow,
  buttonStyleAngefragt,
  buttonStyleAbgelehnt,
} from "helpers/theme";
import { useMediaQuery } from "react-responsive";
import { Active, Price, Star } from "components/Profile";
import * as R from "ramda";
import { connect } from "react-redux";
import { useHistory, useRedirector, useSearch } from "helpers/navigation";
import useFavorites from "helpers/useFavorites";
import { ReactComponent as ProfilBearbeiten } from "assets/icons8-eigenschaft-bearbeiten-100.svg";
import { ReactComponent as PhoneIcon } from "assets/icons8-telefon-100.svg";
import { ReactComponent as EmailIcon } from "assets/icons8-nachricht-gefüllt-100.svg";

import RequestFormModal from "./RequestModal";
import PhoneModal from "./PhoneModal";

const MoneyInfo = ({ active, price, trialLesson, loadUser }) => {
  const is850 = useMediaQuery({ query: "(min-width: 850px)" });
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  return (
    <>
      <Active
        active={active}
        style={{
          fontSize: "1em",
          fontWeight: "400",
          marginRight: is500 ? "40px" : 0,
          lineHeight: "16px",
        }}
      />
      <Price
        price={price}
        trialLesson={trialLesson}
        style={{
          fontSize: "1.1em",
          marginLeft: is850 ? 50 : 0,
          lineHeight: "28px",
        }}
      />
    </>
  );
};

const Pill = ({ children, style, ...rest }) => (
  <Label
    style={{
      margin: "10px 20px 10px 0px",
      maxWidth: 250,
      padding: "3px 20px",
      fontWeight: "600",
      ...style,
    }}
    pill
    {...rest}
  >
    {children}
  </Label>
);

const GPill = ({ children }) => (
  <Pill style={{ color: "#387bf5", background: "#dfeafc" }}>{children}</Pill>
);

const LOCATION_NAMES = {
  StudentHome: "Beim Studenten",
  TutorHome: "Beim Tutor",
  Online: "Online",
  TBD: "Nach Absprache",
};

const Profile = ({ user: me, loadUser }) => {
  const { id } = useParams();
  const { fromPayment } = useSearch();
  const [user, setUser] = useState(null);
  const isSelf = me.uuid === id;

  useEffect(() => {
    get("users/tutors/$1", [id])
      .auth(me.auth)
      .then((user) => {
        setUser(user);
      });
  }, [id, me.auth]);

  const isMedium = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const is850 = useMediaQuery({ query: "(min-width: 850px)" });
  const is950 = useMediaQuery({ query: "(min-width: 950px)" });
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });

  const modules = useMemo(() => {
    const { modules = [] } = user || {};

    const unis = R.uniqWith(
      ({ universityId: a }, { universityId: b }) => a === b,
      modules.map(({ universityId, university }) => ({
        universityId,
        university,
      }))
    );
    const courses = R.uniqWith(
      ({ courseId: a }, { courseId: b }) => a === b,
      modules
    );

    return unis.map((uni) => ({
      ...uni,
      courses: courses
        .filter(({ universityId }) => universityId === uni.universityId)
        .map((course) => ({
          ...course,
          modules: modules.filter(
            ({ universityId, courseId }) =>
              universityId === uni.universityId && courseId === course.courseId
          ),
        })),
    }));
  }, [user]);

  const history = useHistory();

  const [withSteps, setWithSteps] = useState(false);
  const [requestModalShown, setRequestModalShown] = useState(false);
  const [phoneModalShown, setPhoneModalShown] = useState(false);
  const redirectQuery = useRedirector();
  const sendRequest = useCallback(
    (params) => {
      if (!me.uuid) {
        history.push("signup", [], redirectQuery.obj);
      } else if (!me.phone && !isSelf && !params.hasPhone) {
        setPhoneModalShown(true);
        setWithSteps(true);
      } else if (!me.subscribed && !isSelf) {
        history.push("payment", [], {
          ...redirectQuery.obj,
          tId: id,
          fromPayment: true,
        });
      } else {
        setRequestModalShown(true);
      }
    },
    [history, id, isSelf, me.phone, me.subscribed, me.uuid, redirectQuery]
  );

  const sendPhoneNumber = ({ phone }) => {
    if (phone) {
      setRequestLoading(true);
      patch("users/$1", [me.uuid])
        .auth(me.auth)
        .data({ phone })
        .then(async ({ success, error }) => {
          await loadUser();
          setRequestLoading(false);
          setPhoneModalShown(false);
          sendRequest({ hasPhone: true });
        });
    }
  };

  const [requestLoading, setRequestLoading] = useState(false);
  const sendApiRequest = ({ message }) => {
    if (message) {
      setRequestLoading(true);
      post("users/tutors/$1/requests", [id])
        .auth(me.auth)
        .data({ message })
        .on(
          {
            status: 409,
            error: "the user already made a contact request to " + id,
          },
          () => {}
        )
        .then(() => {
          get("users/tutors/$1", [id])
            .auth(me.auth)
            .then((user) => {
              setUser(user);
              setRequestLoading(false);
              setRequestModalShown(false);
            });
        })

        .catch((e) => {
          setRequestLoading(false);
          setRequestModalShown(false);
          if (e) {
            alert(e);
          }
        });
    }
  };

  const setFavorite = useFavorites(me);

  const [didSendRequest, setDidSendRequest] = useState(false);
  useEffect(() => {
    if (fromPayment && !didSendRequest && id) {
      sendRequest();
      setDidSendRequest(true);
      history.push("profile/$1", [id]);
    }
  }, [fromPayment, sendRequest, didSendRequest, id, history]);

  if (!user) {
    return <Loader active />;
  }

  const {
    firstName,
    profilePicture,
    description,
    starred,
    methodDescription,
    lessonLocations,
    contactRequest,
    phone,
    contactEmail,
  } = user;
  const showButton = contactRequest === "None" || !contactRequest;

  return (
    <>
      <SearchHeader />
      <div>
        <div
          style={{
            borderBottom: "1px solid",
            borderColor: "#edf0f6",
          }}
        >
          <div
            style={{
              paddingLeft: isDesktop ? 50 : 20,
              paddingRight: isDesktop ? 50 : 20,
              maxWidth: "1200px",
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
              paddingTop: is500 ? 150 : 120,
              paddingBottom: 50,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems:
                  showButton || isSelf
                    ? isMedium
                      ? "center"
                      : ""
                    : is950
                    ? "center"
                    : "",
                flexDirection:
                  showButton || isSelf
                    ? isMedium
                      ? ""
                      : "column"
                    : is950
                    ? ""
                    : "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: is500 ? "" : "column",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <img
                  alt={`Bild von ${firstName}`}
                  src={profilePicture}
                  style={{
                    borderRadius: 1000,
                    width: is500 ? 150 : 200,
                    height: is500 ? 150 : 200,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: is500 ? "" : "center",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: is850 ? 50 : 30,
                      }}
                    >
                      <h1
                        style={{
                          fontSize: "34px",
                        }}
                      >
                        {firstName}
                      </h1>
                    </div>
                    <Star
                      id={id}
                      starred={starred}
                      setFavorite={setFavorite}
                      style={{ margin: is500 ? 30 : "30px 0 30px 30px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: is500 ? "" : "center",
                      flexDirection: is500 ? "" : "column",
                      flexWrap: "wrap",
                      marginLeft: is850 ? "50px" : is500 ? 30 : 0,
                    }}
                  >
                    <MoneyInfo {...user} />
                  </div>
                </div>
              </div>
              {showButton && (
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: isMedium ? "" : "100%",
                  }}
                >
                  {isMedium ? (
                    isSelf ? (
                      <>
                        <Link to="/tutorprofile">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ProfilBearbeiten
                              style={{
                                height: 24,
                                width: "auto",
                                marginRight: 10,
                                fill: "#bdc1c9",
                              }}
                            />
                            <div style={{ color: "#bdc1c9" }}>
                              Profil bearbeiten
                            </div>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <Button
                        className="button_double_shadow_blue"
                        onClick={sendRequest}
                        style={{
                          ...buttonStyleDoubleShadow,
                          width: "auto",
                          marginLeft: "auto",
                          marginTop: 0,
                        }}
                      >
                        Anfrage senden
                      </Button>
                    )
                  ) : isSelf ? (
                    <>
                      <Link>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 40,
                          }}
                        >
                          <ProfilBearbeiten
                            style={{
                              height: 24,
                              width: "auto",
                              marginRight: 10,
                              fill: "#bdc1c9",
                            }}
                          />
                          <div style={{ color: "#bdc1c9" }}>
                            Profil bearbeiten
                          </div>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <Button
                      className="button_full_width"
                      onClick={sendRequest}
                      style={{
                        ...buttonStyle,
                        marginLeft: "auto",
                        marginTop: 40,
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      Anfrage senden
                    </Button>
                  )}
                </div>
              )}
              {isSelf && !showButton && (
                <>
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: isMedium ? "" : "100%",
                    }}
                  >
                    {isMedium ? (
                      <>
                        <Link to="/tutorprofile">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ProfilBearbeiten
                              style={{
                                height: 24,
                                width: "auto",
                                marginRight: 10,
                                fill: "#bdc1c9",
                              }}
                            />
                            <div style={{ color: "#bdc1c9" }}>
                              Profil bearbeiten
                            </div>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 40,
                            }}
                          >
                            <ProfilBearbeiten
                              style={{
                                height: 24,
                                width: "auto",
                                marginRight: 10,
                                fill: "#bdc1c9",
                              }}
                            />
                            <div style={{ color: "#bdc1c9" }}>
                              Profil bearbeiten
                            </div>
                          </div>
                        </Link>
                      </>
                    )}
                  </div>
                </>
              )}

              {!isSelf && is950 ? (
                <>
                  {contactRequest === "Pending" && (
                    <Link to="/mytutors">
                      <Button
                        style={{
                          ...buttonStyleAngefragt,
                          marginTop: 0,
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        Kontaktdaten angefragt
                      </Button>
                    </Link>
                  )}
                  {contactRequest === "Declined" && (
                    <Link to="/mytutors">
                      <Button
                        style={{
                          ...buttonStyleAbgelehnt,
                          marginTop: 0,
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        Anfrage abgelehnt
                      </Button>
                    </Link>
                  )}
                  {contactRequest === "Accepted" && (
                    <>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 35,
                          }}
                        >
                          <PhoneIcon
                            style={{
                              height: 22,
                              width: 22,
                              marginRight: 15,
                              fill: "#393e46",
                            }}
                          />
                          <div>
                            <a
                              href={"tel:" + phone}
                              style={{ color: "#387bf5" }}
                            >
                              {phone}
                            </a>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 25,
                          }}
                        >
                          <EmailIcon
                            style={{
                              height: 22,
                              width: 22,
                              marginRight: 15,
                              fill: "#393e46",
                            }}
                          />

                          <div>
                            <a
                              href={"mailto:" + contactEmail}
                              style={{ color: "#387bf5" }}
                            >
                              {contactEmail}
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                !isSelf && (
                  <>
                    {contactRequest === "Pending" && (
                      <Link to="/mytutors">
                        <Button
                          style={{
                            ...buttonStyleAngefragt,
                            marginTop: 45,
                            width: "100%",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          Kontaktdaten angefragt
                        </Button>
                      </Link>
                    )}
                    {contactRequest === "Declined" && (
                      <Link to="/mytutors">
                        <Button
                          style={{
                            ...buttonStyleAbgelehnt,
                            marginTop: 45,
                            width: "100%",
                            paddingTop: "15px",
                            paddingBottom: "15px",
                          }}
                        >
                          Anfrage abgelehnt
                        </Button>
                      </Link>
                    )}
                    {contactRequest === "Accepted" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: is500 ? "" : "column",
                            justifyContent: is500 ? "left" : "center",
                            flexWrap: "wrap",
                            marginTop: 40,
                            marginLeft: is500 ? 5 : 0,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: is500 ? 40 : 0,
                            }}
                          >
                            <PhoneIcon
                              style={{
                                height: 22,
                                width: 22,
                                marginRight: 15,
                                fill: "#393e46",
                              }}
                            />
                            <div>
                              <a
                                href={"tel:" + phone}
                                style={{ color: "#387bf5" }}
                              >
                                {phone}
                              </a>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <EmailIcon
                              style={{
                                height: 22,
                                width: 22,
                                marginRight: 15,
                                fill: "#393e46",
                              }}
                            />

                            <div>
                              <a
                                href={"mailto:" + contactEmail}
                                style={{ color: "#387bf5" }}
                              >
                                {contactEmail}
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            paddingLeft: isDesktop ? 50 : isMedium ? 30 : 0,
            paddingRight: isDesktop ? 50 : isMedium ? 30 : 0,
            maxWidth: "1200px",
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            flexDirection: isMedium ? "row" : "column",
          }}
        >
          <div
            style={{
              width: isMedium ? "430px" : "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                marginRight: is850 ? 80 : isMedium ? 30 : 0,
                paddingRight: isMedium ? 20 : "",
                paddingTop: 30,
                paddingBottom: isMedium ? 50 : 0,
                borderRight: isMedium ? "1px solid rgb(227, 229, 233)" : "",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingLeft: isMedium ? 0 : 20,
                  paddingRight: isMedium ? 0 : 20,
                  borderBottom: isMedium ? "" : "1px solid",
                  borderColor: isMedium ? "" : "#edf0f6",
                  paddingBottom: isMedium ? 0 : 30,
                }}
              >
                <h3>Allgemein</h3>
                <div
                  style={{
                    marginTop: 25,
                    marginLeft: 8,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <b
                    style={{
                      width: "100%",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Unterrichtsarten
                  </b>
                  <br />
                  <Pill>Einzelunterricht</Pill>
                  {user.groupLessons && (
                    <>
                      <Pill>Gruppenunterricht</Pill>
                      <GPill>bis zu {user.groupMaxStudents} Personen</GPill>
                      {user.groupPriceType === "Flat" ? (
                        <GPill>Gruppenpreis: {user.groupPrice} € / Std.</GPill>
                      ) : (
                        <GPill>
                          Gruppenpreis: {user.price} € + {user.groupPrice} €
                          p.P.
                        </GPill>
                      )}
                    </>
                  )}
                  <b
                    style={{
                      width: "100%",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginTop: 20,
                    }}
                  >
                    Ort des Unterrichts
                  </b>
                  {lessonLocations.map((l) => (
                    <Pill
                      key={l}
                      style={{
                        border: "1px solid",
                        borderColor: "#edf0f6",
                        color: "#31313f",
                        background: "white",
                        fontWeight: "500",
                      }}
                    >
                      {LOCATION_NAMES[l]}
                    </Pill>
                  ))}
                </div>
              </div>
              <div
                style={{
                  marginTop: isMedium ? 50 : 30,
                  borderBottom: isMedium ? "" : "1px solid",
                  borderColor: isMedium ? "" : "#edf0f6",
                  paddingLeft: isMedium ? 0 : 20,
                  paddingRight: isMedium ? 0 : 20,
                  paddingBottom: isMedium ? 0 : 30,
                }}
              >
                <h3>Kontaktdaten</h3>
                <div style={{ marginTop: 20, marginLeft: 8 }}>
                  {showButton && (
                    <Button
                      className="button_double_shadow_blue"
                      onClick={sendRequest}
                      style={{
                        ...buttonStyleDoubleShadow,
                        marginTop: 15,
                      }}
                    >
                      Anfrage senden
                    </Button>
                  )}
                  {contactRequest === "Pending" && (
                    <Link to="/mytutors">
                      <Button
                        style={{
                          ...buttonStyleAngefragt,
                          marginTop: 15,
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        Kontaktdaten angefragt
                      </Button>
                    </Link>
                  )}
                  {contactRequest === "Declined" && (
                    <Link to="/mytutors">
                      <Button
                        style={{
                          ...buttonStyleAbgelehnt,
                          marginTop: 15,
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        Anfrage abgelehnt
                      </Button>
                    </Link>
                  )}
                  {contactRequest === "Accepted" && (
                    <>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 35,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#dfeafc",
                              borderRadius: "0 50% 45% 45%",
                              height: 34,
                              width: 34,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: 15,
                              paddingRight: 2,
                            }}
                          >
                            <PhoneIcon
                              style={{ height: 16, width: 16, fill: "#387bf5" }}
                            />
                          </div>
                          <div>
                            <a
                              href={"tel:" + phone}
                              style={{ color: "#387bf5" }}
                            >
                              {phone}
                            </a>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 25,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#dfeafc",
                              borderRadius: "0 50% 45% 45%",
                              height: 34,
                              width: 34,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: 15,
                              paddingRight: 2,
                            }}
                          >
                            <EmailIcon
                              style={{ height: 16, width: 16, fill: "#387bf5" }}
                            />
                          </div>
                          <div>
                            <a
                              href={"mailto:" + contactEmail}
                              style={{ color: "#387bf5" }}
                            >
                              {contactEmail}
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: isMedium ? 50 : 30,
                  borderBottom: isMedium ? "" : "1px solid",
                  borderColor: isMedium ? "" : "#edf0f6",
                  paddingLeft: isMedium ? 0 : 20,
                  paddingRight: isMedium ? 0 : 20,
                }}
              >
                <h3>Angebot</h3>
                <div
                  style={{
                    marginTop: 30,
                    marginLeft: 8,
                  }}
                >
                  {modules.map(({ university, universityId, courses }) => (
                    <div style={{ marginBottom: 55 }} key={universityId}>
                      <h2
                        style={{
                          fontWeight: "500",
                          fontSize: "17px",
                          marginBottom: 15,
                        }}
                      >
                        {university}
                      </h2>
                      {courses.map(({ course, courseId, modules }) => (
                        <div
                          className="profile_pill_hover_wrap"
                          key={courseId}
                          style={{ marginBottom: 15 }}
                        >
                          <Pill
                            style={{
                              borderBottomLeftRadius: "0px",
                              borderTopLeftRadius: "120px",
                              marginBottom: "6px",
                              whiteSpace: "nowrap",
                              paddingLeft: "26px",
                            }}
                          >
                            {course}
                          </Pill>
                          {modules.map(({ module, moduleId }) => (
                            <div key={moduleId}>
                              <Pill
                                className="profile_pill_hover_wrap"
                                style={{
                                  borderBottomLeftRadius: "0px",
                                  borderTopLeftRadius: "120px",
                                  paddingLeft: "41px",
                                  color: "#387bf5",
                                  whiteSpace: "nowrap",
                                  background: "#dfeafc",
                                  marginTop: "6px",
                                }}
                              >
                                {module}
                              </Pill>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div style={{ overflow: "hidden", flex: 1 }}>
            <div
              style={{
                wordWrap: "break-word",
                paddingTop: isMedium ? 60 : 30,
                paddingLeft: isMedium ? 0 : 20,
                paddingRight: isMedium ? 0 : 20,
                paddingBottom: isMedium ? 50 : 100,
              }}
            >
              <div>
                <h3>Über mich</h3>
                <div
                  style={{
                    marginLeft: 2,
                    marginTop: 25,
                  }}
                >
                  {description.split("\n").map((t) => (
                    <p
                      style={{
                        color: "#595963",
                        fontSize: "16px",
                        lineHeight: "24pt",
                      }}
                    >
                      {t}
                    </p>
                  ))}
                </div>
              </div>
              <div style={{ marginTop: 70 }}>
                <h3>Wie ich mit dir lerne</h3>
                <div
                  style={{
                    marginLeft: 2,
                    marginTop: 25,
                  }}
                >
                  {methodDescription.split("\n").map((t) => (
                    <p
                      style={{
                        color: "#595963",
                        fontSize: "16px",
                        lineHeight: "24pt",
                      }}
                    >
                      {t}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {requestModalShown && (
        <RequestFormModal
          withSteps={withSteps}
          loading={requestLoading}
          send={sendApiRequest}
          onClose={() => {
            setRequestModalShown(false);
          }}
        />
      )}
      {phoneModalShown && (
        <PhoneModal
          loading={requestLoading}
          send={sendPhoneNumber}
          onClose={() => {
            setPhoneModalShown(false);
          }}
        />
      )}
      <Footer />
    </>
  );
};
Profile.propTypes = {};

export default connect(({ user }) => ({ user }), { loadUser })(Profile);
