import React from "react";
import { SubmitButton, Field, StepDisplay } from "components";
import { Modal } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import phoneRegexp from "helpers/phoneRegexp";

const PhoneModal = ({ loading, onClose, send }) => {
  const isMedium = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <Modal open closeIcon onClose={onClose}>
      <Modal.Header>Kontaktdaten aktualisieren</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ phone: "" }}
          validationSchema={Yup.object({
            phone: Yup.string().matches(
              phoneRegexp,
              "Bitte geben Sie eine gültige Telefonnummer ein. (z.B. +4917...)"
            ),
          })}
          onSubmit={send}
        >
          <Form>
            <StepDisplay count={2} current={1} />
            <div style={{ maxWidth: 500, margin: "auto" }}>
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  marginTop: 10,
                }}
              >
                Damit dich dein Tutor erreichen kann
              </h6>
              <p style={{ fontSize: "15px" }}>
                Bitte gib deine Telefonnummer, unter der dich dein Tutor
                erreichen kann, ein.
              </p>
              <Field placeholder={"Telefon"} name="phone" />
              <SubmitButton fullWidth={!isMedium} loading={loading}>
                Weiter zu Schritt 2
              </SubmitButton>
            </div>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default PhoneModal;
