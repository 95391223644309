import React, { useEffect } from "react";

import "assets/App.css";

import { Route, Switch, BrowserRouter, withRouter } from "react-router-dom";

import store, { persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Elements as StripeProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import VerificationCheck from "components/VerificationCheck";
import OfflineBanner from "components/OfflineBanner";

import LandingPage from "screens/LandingPage";
import LoginScreen from "screens/LoginScreen";
import ResetPWScreen from "screens/ResetPw";
import NewPWScreen from "screens/NewPW";
import VerifyScreen from "screens/VerifyScreen";
import SignupScreen from "screens/SignupScreen";
import SearchResultsScreen from "screens/SearchResultsScreen";
import BecomeTutor from "screens/BecomeTutor";
import SelectSubjects from "screens/SelectSubjects";
import Profile from "screens/Profile";
import MyProfile from "screens/MyProfile";
import MyTutors from "screens/MyPeople/MyTutors";
import MyStudents from "screens/MyPeople/MyStudents";
import TutorLanding from "screens/TutorLanding";
import NotificationsScreen from "screens/NotificationsScreen";
import FAQScreen from "screens/FAQScreen";
import ContactScreen from "screens/ContactScreen";
import PricingScreen from "screens/Pricing";
import PaymentPage from "screens/PaymentPage";
import PaymentDone from "screens/PaymentDone";
import Datenschutz from "screens/Datenschutz";
import Imprint from "screens/Imprint";
import AGB from "screens/AGB";
import RedirectorPage from "screens/RedirectorPage";
import Course from "screens/Course";
import CoursePaymentPage from "screens/PaymentPage/Course";
import CoursePaymentDone from "screens/CoursePaymentDone";

import { Tracker } from "helpers/tracking";

import payment from "config/payment";
const stripePromise = loadStripe(payment.stripe.publishableKey);

const ScrollToTop = withRouter(({ children, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
});

const TitleRoute = ({ children, title, ...rest }) => {
  useEffect(() => {
    document.title = title || "";
  }, [title]);
  return <Route {...rest}>{children}</Route>;
};

const App = () => {
  return (
    <StripeProvider stripe={stripePromise}>
      <Provider store={store}>
        <BrowserRouter>
          <PersistGate persistor={persistor}>
            <div>
              <Tracker>
                <ScrollToTop>
                  <Switch>
                    <TitleRoute
                      path="/signup"
                      component={SignupScreen}
                      title="Registrieren | Unitutors"
                    />
                    <TitleRoute
                      path="/login"
                      component={LoginScreen}
                      title="Einloggen | Unitutors"
                    />
                    <TitleRoute
                      path="/resetPW"
                      component={ResetPWScreen}
                      title="Setze dein Passwort zurück | Unitutors"
                    />
                    <TitleRoute
                      path="/newpw"
                      component={NewPWScreen}
                      title="Setze dein Passwort zurück | Unitutors"
                    />
                    <TitleRoute
                      path="/verify"
                      component={VerifyScreen}
                      title="Verifiziere deine Telefonnummer und Email"
                    />
                    <TitleRoute
                      path="/profile/:id"
                      component={Profile}
                      title="Profil | Unitutors"
                    />
                    <TitleRoute
                      path="/profile"
                      component={MyProfile}
                      title="Mein Profil | Unitutors"
                    />
                    <TitleRoute
                      path="/mytutors"
                      component={MyTutors}
                      title="Meine Tutoren | Unitutors"
                    />
                    <TitleRoute
                      path="/mystudents"
                      component={MyStudents}
                      title="Meine Studenten | Unitutors"
                    />
                    <TitleRoute
                      path="/tutorwerden"
                      component={TutorLanding}
                      title="Tutor werden - Neben dem Studium Geld verdienen"
                    />
                    <TitleRoute
                      path="/werdetutor"
                      component={TutorLanding}
                      title="Tutor werden - Neben dem Studium Geld verdienen"
                    />
                    <TitleRoute
                      path="/tutorprofile"
                      component={BecomeTutor}
                      title="Mein Tutorenprofil | Unitutors"
                    />
                    <TitleRoute
                      path="/subjects"
                      component={SelectSubjects}
                      title="Meine Fächer | Unitutors"
                    />
                    <TitleRoute
                      path="/nachhilfe-tutoren/:uni/:course"
                      component={SearchResultsScreen}
                      title="Nachhilfe-Tutoren | Unitutors"
                    />
                    <TitleRoute
                      path="/notifications"
                      component={NotificationsScreen}
                      title="Benachrichtigungen | Unitutors"
                    />
                    <TitleRoute
                      path="/faq"
                      component={FAQScreen}
                      title="Häufige Fragen"
                    />
                    <TitleRoute
                      path="/contact"
                      component={ContactScreen}
                      title="Unitutors Kontakt"
                    />
                    <TitleRoute
                      path="/pricing"
                      component={PricingScreen}
                      title="Student Pass | Unitutors"
                    />
                    <TitleRoute
                      path="/payment"
                      component={PaymentPage}
                      title="Student Pass | Bezahlmethode | Unitutors"
                    />
                    <TitleRoute
                      path="/paymentDone"
                      component={PaymentDone}
                      title="Student Pass erfolgreich abgeschlossen | Unitutors"
                    />
                    <TitleRoute path="/agb" component={AGB} title="AGB" />
                    <TitleRoute
                      path="/datenschutz"
                      component={Datenschutz}
                      title="Unitutors Datenschutz"
                    />
                    <TitleRoute
                      path="/impressum"
                      component={Imprint}
                      title="Unitutors Impressum"
                    />
                    <TitleRoute
                      path="/"
                      exact
                      component={LandingPage}
                      title="Unitutors: Finde einen Nachhilfe-Tutor aus deinem Studiengang"
                    />
                    <TitleRoute
                      path="/course/:id"
                      component={Course}
                      title="Crashkurs | Unitutors"
                    />
                    <TitleRoute
                      path="/coursepayment"
                      component={CoursePaymentPage}
                      title="Crashkurs | Bezahlmethode | Unitutors"
                    />
                    <TitleRoute
                      path="/coursePaymentDone"
                      component={CoursePaymentDone}
                      title="Kauf erfolgreich abgeschlossen | Unitutors"
                    />
                    <TitleRoute
                      path="/:rest"
                      exact
                      component={RedirectorPage}
                      title="Du wirst weitergeleitet..."
                    />
                  </Switch>
                </ScrollToTop>
              </Tracker>
              <VerificationCheck />
              <OfflineBanner />
            </div>
          </PersistGate>
        </BrowserRouter>
      </Provider>
    </StripeProvider>
  );
};

export default App;
