import React from "react";

const Section = ({ heading, children }) => (
  <>
    <h3 style={{ marginTop: 60, fontSize: 20, marginBottom: 30 }}>{heading}</h3>
    {children}
  </>
);

export default Section;
