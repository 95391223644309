import React, { useState, useEffect, useCallback } from "react";
import {
  ProfileSidebar,
  Wrapper,
  Section,
  CardList as ACardList,
} from "components";
import { colors } from "helpers/theme";
import { connect } from "react-redux";
import { get } from "helpers/api";
import { Phone, Email, Star } from "components/Profile";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import useFavorites from "helpers/useFavorites";
import useIsLoggedIn from "helpers/useIsLoggedIn";

const CardList = (props) => (
  <ACardList {...props} card={({ ...ps }) => <Request {...ps} />} />
);

const Request = ({
  firstName,
  profilePicture,
  phone,
  starred,
  contactEmail,
  price,
  contactRequest: { response, status },
  trialLesson,
  id,
  setFavorite,
}) => {
  const is1000 = useMediaQuery({ query: "(min-width: 1000px)" });
  const medium = useMediaQuery({ query: "(min-width: 900px)" });
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const is600 = useMediaQuery({ query: "(min-width: 600px)" });

  const padding = {
    margin: "10px 14px 10px 0",
  };
  const content = (
    <>
      {status === "Pending" ? (
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            width: "100%",
            color: colors.primary,
            textAlign: "center",
          }}
        >
          Anfrage gesendet
        </div>
      ) : status === "Declined" ? (
        <div style={{ flex: 1 }}>
          <div
            style={{
              ...padding,
              color: colors.red,
              width: "100%",
              textAlign: "center",
            }}
          >
            Anfrage abgelehnt
          </div>
          <div
            style={{
              lineHeight: "24px",
              width: "100%",
              borderTop: "1px solid",
              marginTop: 15,
              paddingTop: 15,
              paddingLeft: 4,
              borderColor: "#edf0f6",
            }}
          >
            <b>Nachricht vom Tutor</b>
            <br />
            {response}
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              textAlign: "left",
              lineHeight: "24px",
              display: is1000 ? "flex" : medium ? "" : is600 ? "flex" : "",
              justifyContent: "center",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Email style={padding} email={contactEmail} />
            <Phone style={padding} phone={phone} />
          </div>
        </>
      )}
    </>
  );

  return (
    <div
      style={{
        margin: "30px 0px",
        padding: 20,
        boxShadow: "0px 0px 15px rgba(36, 52, 78, 0.2)",
        borderRadius: 10,
      }}
      className={status === "Declined" ? "fadedOut" : ""}
    >
      <div
        className="button buttoncard"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Link to={"/profile/" + id}>
          <img
            alt={`Bild von ${firstName}`}
            src={profilePicture}
            style={{ borderRadius: 1000, width: 70, height: 70 }}
          />
        </Link>
        <Link to={"/profile/" + id} style={{ flex: 1 }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: 600,
              paddingLeft: 20,
              paddingRight: 10,
              color: "rgb(33, 37, 41)",
              display: is500 ? "flex" : "",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {firstName}

            <div
              style={{
                textAlign: is500 ? "right" : "left",
                color: "#387bf5",
                lineHeight: "22px",
              }}
            >
              {price}€ <span style={{ color: "#d4d6db" }}>/ Std.</span>
            </div>
          </div>
        </Link>

        <Star
          id={id}
          starred={starred}
          setFavorite={setFavorite}
          style={{ marginLeft: is500 ? 30 : 0 }}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: is500 ? "center" : "flex-start",
          marginTop: phone && contactEmail ? 15 : 0,
          borderTop: phone && contactEmail ? "1px solid" : "",
          borderColor: "#edf0f6",
          paddingTop: phone && contactEmail ? 15 : 0,
        }}
      >
        {content}
      </div>
    </div>
  );
};

const MyTutors = ({ user, loadUser }) => {
  const [requests, setRequests] = useState({
    accepted: false,
    declined: [],
    pending: false,
    favorites: false,
  });

  useIsLoggedIn(user);

  useEffect(() => {
    get("users/tutors")
      .v(2)
      .query({ requestStatus: "Any" })
      .auth(user.auth)
      .then(({ tutors }) => {
        setRequests((rs) => ({
          ...rs,
          accepted: tutors
            .map((t) => ({ ...t, status: t.contactRequest }))
            .filter((r) => r.contactRequest.status === "Accepted"),
          declined: tutors
            .map((t) => ({ ...t, status: t.contactRequest }))
            .filter((r) => r.contactRequest.status === "Declined"),
          pending: tutors
            .map((t) => ({ ...t, status: t.contactRequest }))
            .filter((r) => r.contactRequest.status === "Pending"),
        }));
      });
  }, [user.auth]);

  const loadFavorites = useCallback(() => {
    get("users/tutors")
      .query({ onlyFavorites: 1 })
      .auth(user.auth)
      .then(({ tutors }) => {
        setRequests((requests) => ({
          ...requests,
          favorites: tutors,
        }));
      });
  }, [user.auth]);
  useEffect(() => {
    loadFavorites();
  }, [user.auth, loadFavorites]);

  const _setFavorite = useFavorites(user),
    setFavorite = async (...ps) => {
      const res = await _setFavorite(...ps);
      loadFavorites();
      return res;
    };

  return (
    <>
      <Wrapper sidebar={<ProfileSidebar current="mytutors" />}>
        <div style={{ width: "100%" }}>
          <Section heading="Meine Favoriten">
            <CardList
              setFavorite={setFavorite}
              cards={requests.favorites}
              none="Du hast noch keine Tutoren favorisiert."
            />
          </Section>
          <div style={{ height: 10 }}></div>
          <Section heading="Meine Tutoren">
            <CardList
              setFavorite={setFavorite}
              cards={requests.accepted}
              none="Du hast noch keine Kontaktdaten von Tutoren."
            />
          </Section>
          <div style={{ height: 10 }}></div>
          <Section heading="Meine Anfragen">
            <CardList
              setFavorite={setFavorite}
              cards={requests.pending}
              none="Du hast keine ausstehenden Anfragen."
            />
          </Section>
          {requests.declined.length > 0 && (
            <>
              <div style={{ height: 10 }}></div>
              <Section heading="Abgelehnte Anfragen">
                <CardList
                  setFavorite={setFavorite}
                  cards={requests.declined}
                  none=""
                />
              </Section>
            </>
          )}
        </div>
      </Wrapper>
    </>
  );
};
MyTutors.propTypes = {};

export default connect(({ user }) => ({ user }), {})(MyTutors);
