import React, { useEffect, useState } from "react";
import { Footer, SearchHeader } from "components";
import { useMediaQuery } from "react-responsive";
import { Button, Loader } from "semantic-ui-react";
import { useHistory, useSearch } from "helpers/navigation";
import background from "assets/Background_Payment_Page.svg";
import { get } from "helpers/api";

import { connect } from "react-redux";
import { buttonStyleDoubleShadow } from "helpers/theme";

const PaymentDone = ({ user }) => {
  const { courseId } = useSearch();

  const [availableCourses, setCourses] = useState([]);
  useEffect(() => {
    get("crashcourse").then((crashcourses) => {
      setCourses(crashcourses);
    });
  }, []);
  const course = availableCourses.filter(({ id: cId }) => cId == courseId)[0];

  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const is1000 = useMediaQuery({ query: "(min-width: 1000px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });

  const history = useHistory();
  const onOk = () => {
    history.push("");
  };

  if (!course) {
    return <Loader active />;
  }
  const coursemoduleshortcut = course.courseModuleShortcut;

  return (
    <>
      <SearchHeader />
      <div
        style={{
          width: "100%",
          paddingLeft: isTablet ? 30 : 10,
          paddingRight: isTablet ? 30 : 10,
          paddingTop: 180,
          minHeight: "70vh",
          paddingBottom: 150,
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 900,
            width: "100%",
            display: "flex",
            flexDirection: is1000 ? "" : "column",
          }}
        >
          <div
            style={{
              flex: 2,
              marginLeft: is1000 ? 100 : 0,
              marginRight: is1000 ? 100 : 0,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h1
                  style={{
                    fontWeight: 700,
                    fontSize: is500 ? "40px" : "28px",
                    marginBottom: 6,
                  }}
                >
                  Erfolgreich eingeschrieben
                </h1>
                <h2 style={{ fontSize: is500 ? "22px" : "18px" }}>
                  Online Crashkurs für {coursemoduleshortcut}
                </h2>
              </div>
            </div>

            <p style={{ fontSize: "16px", marginTop: 50 }}>
              In Kürze erhälst du eine Bestätigungsmail.
            </p>
            <p style={{ fontSize: "16px" }}>
              Außerdem bekommst du spätestens einen Tag vor Beginn des Kurses
              eine weitere Mail mit dem Einladungslink zu Zoom.
            </p>

            <div
              style={{
                marginTop: is1000 ? 50 : 70,
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onOk}
                style={{
                  ...buttonStyleDoubleShadow,
                  width: "auto",
                  marginTop: 50,
                }}
              >
                Fertig
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default connect(({ user }) => ({ user }), {})(PaymentDone);
