import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { Label } from "components";

import ReactMarkdown from "react-markdown";

const CourseCard = ({
  id,
  courseTitleSearch,
  courseShortDescription,
  courseModuleShortcut,
  courseStudyShortcut,
  courseAvailable,
  courseImg,
}) => {
  const isMedium = useMediaQuery({ query: "(min-width: 700px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isWide = useMediaQuery({ query: "(min-width: 1250px)" });
  const is1600 = useMediaQuery({ query: "(min-width: 1600px)" });

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 5,
        padding: isMedium ? 25 : 10,
        width: is1600 ? "100%" : isTablet ? "100%" : "94%",
        margin: isTablet ? (is1600 ? "2.166%" : "2.5%") : "3%",
        marginBottom: isWide ? 20 : 30,
        marginTop: isMedium ? 20 : 8,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "270px",
      }}
    >
      <Link to={"/course/" + id}>
        <div
          className="button buttoncard"
          style={{
            display: isMedium ? "flex" : "",
            marginBottom: 14,
          }}
        >
          <div>
            <img
              alt={`Logo`}
              src={courseImg}
              style={{ borderRadius: 1000, width: 70, height: 70 }}
            />
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: 600,
              paddingLeft: isMedium ? 20 : 0,
              flex: 1,
              alignSelf: "flex-end",
              color: "rgb(33, 37, 41)",
            }}
          >
            {courseTitleSearch}
            <br />
            <div
              style={{
                fontSize: "10px !important",
                fontWeight: 600,
              }}
            >
              <Label
                style={{ fontWeight: 600, fontSize: "11px" }}
                text={courseStudyShortcut}
              />

              <Label
                style={{ fontWeight: 600 }}
                text={courseModuleShortcut}
                secondary
              />
            </div>
          </div>
        </div>
      </Link>

      <div
        style={{
          flex: 1,
          paddingTop: 10,
        }}
      >
        <ReactMarkdown>{courseShortDescription}</ReactMarkdown>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "6px",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {courseAvailable ? (
          <div
            style={{
              color: "rgb(53, 170, 160)",
              fontWeight: 500,
              fontSize: "12px",
              marginTop: "0px",
            }}
          >
            Freie Plätze verfügbar
          </div>
        ) : (
          <div
            style={{
              color: "rgb(136, 69, 69)",
              fontWeight: 500,
              fontSize: "12px",
              marginTop: "0px",
            }}
          >
            Keine Plätze mehr frei
          </div>
        )}

        <div
          style={{
            textAlign: "right",
            color: "rgb(56, 123, 245)",
            lineHeight: "10px",
          }}
        >
          <span
            style={{
              color: "rgb(145, 56, 245)",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "15px",
              paddingLeft: "0px",
            }}
          >
            KOSTENLOS
          </span>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
