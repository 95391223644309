import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  SubmitButton,
  Field,
  FieldRow,
  ProfileSidebar,
  Wrapper,
  PromptIfDirty,
} from "components";
import { DataSaved, useDataSaved } from "components/DataSavedPopup";
import AvatarPicker, { AvatarUpload } from "components/Profile/AvatarPicker";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { patch } from "helpers/api";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import phoneRegexp from "helpers/phoneRegexp";
import { loadUser } from "redux/user";

const MyProfile = ({ user, loadUser, fetchingUser }) => {
  useEffect(() => {
    loadUser();
  }, [loadUser, user.auth]);

  const [showSaved, setShowSaved] = useDataSaved();
  const [loading, setLoading] = useState(0);
  const onSubmit = (fields, actions) => {
    const { /*email,*/ phone, firstName, lastName } = fields;
    /* if (email) {
      setLoading(loading + 1);
      patch("login/$1", [user.uuid])
        .auth(user.auth)
        .data({ email })
        .then(({ success, error }) => {
          setShowSaved(loading === 0);
          setLoading(Math.max(0, loading - 1));
        });
    }*/
    if (firstName && lastName) {
      setLoading(loading + 1);
      patch("users/$1", [user.uuid])
        .auth(user.auth)
        .data({ firstName, lastName, phone })
        .then(({ success, error }) => {
          setShowSaved(loading === 0);
          setLoading(Math.max(0, loading - 1));
          loadUser();
        });
    }
  };

  const [picFile, setPicture] = useState(null);
  const [uploadingPicture, setPictureLoading] = useState(false);

  const uploadFoto = (file) => {
    setPicture(file);
  };

  const saveFoto = (data) => {
    setPictureLoading(true);
    const [format, picture] = data.split(",");
    patch("users/$1/profile-picture", [user.uuid])
      .data({ picture, format })
      .then(() => {
        loadUser();
        setPicture(null);
        setPictureLoading(false);
      });
  };

  const is500 = useMediaQuery({ query: "(min-width: 500px)" });

  const { firstName = "", lastName = "", profilePicture, email, phone } = user;

  return (
    <>
      <Wrapper sidebar={<ProfileSidebar current="profile" />}>
        {fetchingUser ? (
          <Loader inline active />
        ) : (
          <Formik
            initialValues={{
              firstName,
              lastName,
              email,
              phone,
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required("Bitte gib einen Vornamen ein."),
              lastName: Yup.string().required("Bitte gib einen Nachnamen ein."),
              phone: phone
                ? Yup.string()
                    .matches(
                      phoneRegexp,
                      "Bitte geben Sie eine gültige Telefonnummer ein. (z.B. +4917...)"
                    )
                    .required("Bitte gib eine gültige Telefonnummer ein.")
                : Yup.string().matches(
                    phoneRegexp,
                    "Bitte geben Sie eine gültige Telefonnummer ein. (z.B. +4917...)"
                  ),
              /*email: Yup.string()
                .email("Bitte gib eine gültige Email-Addresse ein.")
                .required("Bitte gib eine Email-Addresse ein."),*/
            })}
            onSubmit={onSubmit}
          >
            <Form
              style={{
                width: "100%",
              }}
            >
              <DataSaved show={showSaved} />
              <PromptIfDirty />
              <div
                style={{
                  paddingTop: 40,
                  paddingBottom: 40,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: is500 ? "row" : "column",
                }}
              >
                <AvatarUpload
                  setPicture={uploadFoto}
                  firstName={firstName}
                  lastName={lastName}
                  profilePicture={profilePicture}
                />
                <h2
                  style={{
                    marginLeft: is500 ? 60 : 0,
                    marginTop: is500 ? "" : 40,
                    flex: 1,
                    fontSize: "26px",
                    fontWeight: "normal",
                  }}
                >
                  {firstName} {lastName}
                </h2>
              </div>
              <FieldRow
                fields={[
                  <Field placeholder={"Vorname"} name="firstName" />,
                  <Field placeholder={"Nachname"} name="lastName" />,
                ]}
              />
              <FieldRow
                fields={[
                  <Field disabled placeholder={"E-Mail"} name="email" />,
                  <Field placeholder={"Telefon"} name="phone" />,
                ]}
              />

              <br />
              <SubmitButton loading={loading}>Daten speichern</SubmitButton>
            </Form>
          </Formik>
        )}
      </Wrapper>
      {picFile && (
        <AvatarPicker
          loading={uploadingPicture}
          file={picFile}
          onSave={saveFoto}
          onClose={() => setPicture(null)}
        />
      )}
    </>
  );
};
MyProfile.propTypes = {};

export default connect(
  ({ user, fetching: { user: fetchingUser } }) => ({ user, fetchingUser }),
  {
    loadUser,
  }
)(MyProfile);
