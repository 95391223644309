import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Loader } from "semantic-ui-react";

const MyDropdown = ({
  options,
  loading,
  setValue,
  setSearch = () => {},
  ...rest
}) => {
  return (
    <Dropdown
      searchInput={{
        autoComplete: "off",
        spellCheck: "false",
        autoCorrect: "off",
      }}
      noResultsMessage="Kein Ergebnis gefunden"
      icon={null}
      options={
        loading ? null : options.map(({ text, value }) => ({ text, value }))
      }
      fluid
      search
      onSearchChange={(_, { searchQuery: text }) => setSearch(text)}
      onChange={(_, { value }) => setValue(value)}
      {...rest}
    >
      {loading > 0 ? (
        <Dropdown.Menu>
          <Dropdown.Header
            icon={
              <div style={{ width: 100, height: 30 }}>
                <Loader active />
              </div>
            }
          />
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
  );
};
MyDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  setSearch: PropTypes.func,
};

export default MyDropdown;
