import React from "react";
import { Footer, SearchHeader } from "components";
import { useMediaQuery } from "react-responsive";
import { Button } from "semantic-ui-react";
import { useHistory, useSearch } from "helpers/navigation";
import { ReactComponent as IconGarantie } from "assets/Icon_Geld_Zurück.svg";
import { ReactComponent as IconTutorenKontakt } from "assets/Icon_Tutoren_Suchen.svg";

import { connect } from "react-redux";
import { buttonStyleDoubleShadow } from "helpers/theme";

const PaymentDone = ({ user }) => {
  const { redirect, ...searchParams } = useSearch();

  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const is1000 = useMediaQuery({ query: "(min-width: 1000px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });

  const history = useHistory();
  const onOk = () => {
    if (redirect) {
      history.push(redirect, [], searchParams);
    } else {
      history.push("");
    }
  };

  return (
    <>
      <SearchHeader />
      <div
        style={{
          width: "100%",
          paddingLeft: isTablet ? 30 : 10,
          paddingRight: isTablet ? 30 : 10,
          paddingTop: 180,
          minHeight: "70vh",
          paddingBottom: 150,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 900,
            width: "100%",
            display: "flex",
            flexDirection: is1000 ? "" : "column",
          }}
        >
          <div
            style={{
              flex: 2,
              marginLeft: is1000 ? 100 : 0,
              marginRight: is1000 ? 100 : 0,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h1
                  style={{
                    fontWeight: 700,
                    fontSize: is500 ? "40px" : "28px",
                    marginBottom: 6,
                  }}
                >
                  Dein Student Pass ist aktiv
                </h1>
                <h2 style={{ fontSize: is500 ? "22px" : "18px" }}>
                  Semester Abonnement
                </h2>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: isTablet ? "center" : "flex-start",
                marginTop: is1000 ? 60 : 80,
              }}
            >
              <div>
                <IconTutorenKontakt style={{ width: 66, height: "auto" }} />
              </div>
              <div style={{ marginLeft: isTablet ? 40 : 20, fontSize: "16px" }}>
                <p style={{ lineHeight: "25px", fontWeight: 700 }}>
                  Unbegrenzt Tutoren kontaktieren
                </p>
                <p>
                  Mit dem Student Pass von Unitutors kannst du ein Semester (6
                  Monate) lang so viele Tutoren kontaktieren wie du möchtest.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: isTablet ? "center" : "flex-start",
                marginTop: is1000 ? 40 : 60,
              }}
            >
              <div>
                <IconGarantie style={{ width: 66, height: "auto" }} />
              </div>
              <div style={{ marginLeft: isTablet ? 40 : 20, fontSize: "16px" }}>
                <p style={{ lineHeight: "25px", fontWeight: 700 }}>
                  Geld-zurück-Garantie
                </p>
                <p>
                  Wenn deine angefragten Tutoren keine Zeit hatten oder du mit
                  dem Unterricht nicht zufrieden warst, bekommst du dein Geld
                  für den Student Pass zurück.
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: is1000 ? 50 : 70,
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onOk}
                style={{
                  ...buttonStyleDoubleShadow,
                  width: "auto",
                  marginTop: 50,
                }}
              >
                Fertig
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default connect(({ user }) => ({ user }), {})(PaymentDone);
