import React from "react";

const Active = ({ active, style }) => (
  <div
    style={{
      color: active ? "#35aaa0" : "#884545",
      fontWeight: "bold",
      fontSize: "0.9em",
      ...style,
    }}
  >
    {active ? "VERFÜGBAR" : "NICHT VERFÜGBAR"}
  </div>
);

export default Active;
