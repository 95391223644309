import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  NotificationBubble,
  ProfileSidebar,
  Wrapper,
  Section,
  CardList as ACardList,
} from "components";
import { Button } from "semantic-ui-react";
import { loadNotifications, setNotiRead } from "redux/user";
import useIsLoggedIn from "helpers/useIsLoggedIn";
import {
  ContactReqNoti,
  DeclinedReqNoti,
  AcceptedReqNoti,
} from "./notifications";
import { buttonTextOnlyStyle } from "helpers/theme";

const Noti = ({ id, setRead, read, type, data }) => {
  return (
    <div
      className="buttoncard"
      style={{
        margin: "15px 0px",
        padding: 20,
        boxShadow: "0px 0px 24px rgba(36, 52, 78, 0.2)",
        borderRadius: 10,
        opacity: read ? 0.5 : 1,
      }}
      onClick={() => setRead(id)}
    >
      {!read && (
        <NotificationBubble count={" "} style={{ top: -26, left: -26 }} />
      )}
      {
        {
          ReceivedContactRequest: <ContactReqNoti {...data} />,
          DeclinedContactRequest: <DeclinedReqNoti {...data} />,
          AcceptedContactRequest: <AcceptedReqNoti {...data} />,
        }[type]
      }
    </div>
  );
};

const CardList = (props) => (
  <ACardList {...props} card={({ ...ps }) => <Noti {...ps} />} />
);

const NotificationsScreen = ({ user, loadNotifications, setNotiRead }) => {
  useIsLoggedIn(user);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadNotifications()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [loadNotifications]);

  const setRead = (id) => {
    setNotiRead(id);
  };

  const setAllRead = () => {
    user.notifications
      .filter(({ read }) => !read)
      .forEach(({ id }) => setNotiRead(id));
  };

  return (
    <>
      <Wrapper sidebar={<ProfileSidebar current="notifications" />}>
        <div style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Section heading="Benachrichtigungen"></Section>
            <Button
              style={{ ...buttonTextOnlyStyle, marginRight: 0 }}
              onClick={setAllRead}
            >
              Alle gelesen
            </Button>
          </div>

          <CardList
            setRead={setRead}
            cards={user.notifications.sort(
              ({ timestamp: a }, { timestamp: b }) => b - a
            )}
            loading={loading}
            none="Du hast keine Benachrichtigungen."
          />
        </div>
      </Wrapper>
    </>
  );
};
NotificationsScreen.propTypes = {};

export default connect(({ user }) => ({ user }), {
  loadNotifications,
  setNotiRead,
})(NotificationsScreen);
