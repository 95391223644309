import React from "react";
import { Link } from "react-router-dom";

const SpecialButton = ({
  text,
  sColor = "#dae6fc",
  bColor = "#387bf5",
  href,
}) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Link to={href}>
        <div
          style={{
            marginTop: 2,
            backgroundColor: sColor,
            height: 63,
            borderRadius: 10,
            display: "inline-block",
            paddingRight: 20,
          }}
        >
          <button
            type="button"
            className= "btn btn-info"
            style={{
              backgroundColor: bColor,
              borderColor: bColor,
              postion: "relative",
              borderRadius: "10px 10px 10px 10px",
              fontSize: 16,
              fontWeight: "600",
              padding: "18px 30px 18px 30px",
              boxShadow: "rgba(56, 123, 245, 0.1) 0px 0px 46px 5px",
              marginTop: 20,
              marginLeft: -20,
            }}
          >
            {text}
          </button>
        </div>
      </Link>
    </div>
  );
};

export default SpecialButton;
