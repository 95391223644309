import React from "react";
import { Footer, NoSearchHeader } from "components";
import { useMediaQuery } from "react-responsive";
import JeanNicoBild from "assets/JeanNicoStudybear.jpg";
import GianniBild from "assets/GianniStudybear.jpg";
import PhilippBild from "assets/PhilippStudybear.jpg";
import { ReactComponent as NiceMail } from "assets/KontaktseiteMail.svg";
import { ReactComponent as NiceInsta } from "assets/KontaktseiteInsta.svg";

import urls from "config/urls";

const ContactScreen = ({ user, login }) => {
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          width: "100%",
          paddingLeft: 60,
          paddingRight: 60,
          marginTop: 150,
        }}
      >
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: isDesktop ? "" : "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="contact_möglichkeiten_cards"
            style={{
              width: is500 ? "400px" : 300,
              height: is500 ? 400 : 300,
              borderRadius: "0 45% 48% 45%",
              boxShadow: " 0px 0px 15px 8px rgba(36, 52, 78, 0.0470588)",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <NiceInsta
                style={{ width: 100, height: "auto", marginBottom: 10 }}
              />
              <h5 style={{ fontWeight: 500 }}>Schreib uns auf Instagram</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urls.instagram}
              >
                @unitutors.de
              </a>
            </div>
          </div>
          <div
            className="contact_möglichkeiten_cards"
            style={{
              width: is500 ? 400 : 300,
              height: is500 ? 400 : 300,
              borderRadius: "48% 45% 0 45%",
              boxShadow: " 0px 0px 15px 8px rgba(36, 52, 78, 0.0470588)",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginLeft: isDesktop ? 80 : 0,
              marginTop: isDesktop ? 0 : 60,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <NiceMail
                style={{ width: 100, height: "auto", marginBottom: 10 }}
              />
              <h5 style={{ fontWeight: 500 }}>Schreib uns eine Mail</h5>
              <a href={"mailto:" + urls.supportmail}>{urls.supportmail}</a>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          paddingLeft: isTablet ? 60 : 20,
          paddingRight: isTablet ? 60 : 20,
          marginTop: 120,
          marginBottom: 150,
          textAlign: "center",
        }}
      >
        <h2 style={{ fontWeight: 600 }}>Das Team hinter Unitutors</h2>

        <div
          style={{
            width: "100%",
            maxWidth: isDesktop ? "1380px" : 500,
            display: "flex",
            flexDirection: isDesktop ? "" : "column",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 80,
          }}
        >
          <div
            className="about_us_cards"
            style={{
              width: isDesktop ? "30%" : "100%",
              marginRight: isDesktop ? "5%" : 0,
              marginBottom: isDesktop ? "" : 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              boxShadow: " 0px 0px 15px 8px rgba(36, 52, 78, 0.0470588)",
              borderRadius: "8px",
              padding: "30px 40px",
            }}
          >
            <div
              style={{
                marginBottom: 30,
              }}
            >
              <img
                src={JeanNicoBild}
                style={{
                  width: "150px",
                  borderRadius: 1000,
                  height: "auto",
                }}
                alt="Jean-Nico Gründer Unitutors"
              />
            </div>
            <div>
              <h3
                style={{
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Jean-Nico
              </h3>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                "Hast du ein persönliches Anliegen, bin vermutlich ich der
                richtige Ansprechpartner für dich."
              </p>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "-8px",
                  marginBottom: 4,
                }}
              >
                <a
                  href={"mailto:jean-nico@unitutors.de"}
                  style={{
                    color: "#387bf5",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Mail
                </a>
              </div>
            </div>
          </div>

          <div
            className="about_us_cards"
            style={{
              width: isDesktop ? "30%" : "100%",
              marginRight: isDesktop ? "5%" : 0,
              marginBottom: isDesktop ? "" : 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              boxShadow: " 0px 0px 15px 8px rgba(36, 52, 78, 0.0470588)",
              borderRadius: "8px",
              padding: "30px 40px",
            }}
          >
            <div
              style={{
                marginBottom: 30,
              }}
            >
              <img
                src={PhilippBild}
                style={{
                  width: "150px",
                  borderRadius: 1000,
                  height: "auto",
                }}
                alt="Philipp Gründer Unitutors"
              />
            </div>
            <div>
              <h3
                style={{
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Philipp
              </h3>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                "Schreib mir gerne für Fragen rund um die Website oder wenn du
                einen Fehler gefunden hast."
              </p>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "-8px",
                  marginBottom: 4,
                }}
              >
                <a
                  href={"mailto:philipp@unitutors.de"}
                  style={{
                    color: "#387bf5",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Mail
                </a>
              </div>
            </div>
          </div>

          <div
            className="about_us_cards"
            style={{
              width: isDesktop ? "30%" : "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              boxShadow: " 0px 0px 15px 8px rgba(36, 52, 78, 0.0470588)",
              borderRadius: "8px",
              padding: "30px 40px",
            }}
          >
            <div
              style={{
                marginBottom: 30,
              }}
            >
              <img
                src={GianniBild}
                style={{
                  width: "150px",
                  borderRadius: 1000,
                  height: "auto",
                }}
                alt="Gianni Gründer Unitutors"
              />
            </div>
            <div>
              <h3
                style={{
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Gianni
              </h3>
              <p style={{ textAlign: "center", fontSize: "16px" }}>
                "Wenn du ein Problem mit der Website hast, bin ich für dich da."
              </p>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "-8px",
                  marginBottom: 4,
                }}
              >
                <a
                  href={"mailto:gianni@unitutors.de"}
                  style={{
                    color: "#387bf5",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Mail
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactScreen;
