import React, { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import { Button, Icon, Modal, Loader } from "semantic-ui-react";
import { buttonIconStyle, buttonStyleDoubleShadow } from "helpers/theme";
import { ReactComponent as Haekchen } from "assets/icons8-häkchen-100-2.svg";

export const AvatarUpload = ({
  setPicture,
  firstName,
  lastName,
  profilePicture,
}) => {
  const uploadFoto = (event) => setPicture(event.target.files[0]);

  return profilePicture ? (
    <label className="uploadButton">
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={uploadFoto}
      ></input>
      <img
        alt={`Bild von ${firstName} ${lastName}`}
        src={profilePicture}
        style={{ borderRadius: 1000, width: 100, height: 100 }}
      />
    </label>
  ) : (
    <div
      style={{
        width: 100,
        height: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader active inline />
    </div>
  );
};

const AvatarPicker = ({
  style,
  file,
  size = 400,
  borderRadius = 10000,
  onSave,
  onClose,
  loading,
}) => {
  const editor = useRef();
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);

  const save = () => {
    onSave(editor.current.getImageScaledToCanvas().toDataURL());
  };

  return (
    <Modal open onClose={onClose}>
      <Modal.Content />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#fff",
          ...style,
        }}
      >
        <AvatarEditor
          ref={editor}
          image={file}
          width={size}
          height={size}
          border={50}
          borderRadius={borderRadius}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={scale}
          rotate={rotation}
        />
        <div>
          <Button
            style={{ ...buttonIconStyle, width: 44 }}
            onClick={() => setRotation((rotation + 90) % 360)}
          >
            <Icon name="redo" />
          </Button>
          <Button
            style={{ ...buttonIconStyle, width: 44 }}
            onClick={() => setRotation((rotation - 90) % 360)}
          >
            <Icon name="undo" />
          </Button>
        </div>
        <input
          style={{ maxWidth: 400, margin: 20 }}
          type="range"
          min="100"
          max="1000"
          value={scale * 100}
          className="slider"
          onChange={(e) => {
            setScale(e.target.value / 100);
          }}
        />
      </div>
      <Modal.Content />
      <Modal.Actions>
        <Button
          className="button_double_shadow_blue"
          loading={loading}
          onClick={save}
          style={{
            ...buttonStyleDoubleShadow,
            padding: "20px 40px 20px 30px",
            marginTop: 10,
          }}
        >
          <Haekchen
            style={{
              marginTop: "-3px",
              marginRight: 30,
              fill: "#ffffff",
              height: 18,
              width: "auto",
            }}
          />
          Speichern
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AvatarPicker;
