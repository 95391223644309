import React from "react";

import { ReactComponent as PhoneIcon } from "assets/icons8-telefon-100.svg";

const Phone = ({ phone, style }) => {
  if (phone) {
    return (
      <div style={style}>
        <PhoneIcon
          style={{ margin: 10, fill: "#393e46" }}
          height={22}
          width={22}
        />
        <a href={"tel:" + phone}>{phone}</a>
      </div>
    );
  } else {
    return null;
  }
};

export default Phone;
