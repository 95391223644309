import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { buttonIconStyle } from "helpers/theme";
import { useToggleFavorite } from "helpers/useFavorites";

const Star = ({ id, starred, setFavorite, style }) => {
  const { toggleFavorite, isFavorite, favoriteLoading } = useToggleFavorite(
    id,
    starred,
    setFavorite
  );

  return (
    <Button
      icon
      style={{
        ...buttonIconStyle,
        width: "44px",
        marginLeft: 30,
        ...style,
      }}
      loading={favoriteLoading}
      onClick={() => toggleFavorite()}
    >
      <Icon
        style={{ color: isFavorite ? "#387bf5" : "#9fa5b0" }}
        name={isFavorite ? "star" : "star outline"}
      />
    </Button>
  );
};

export default Star;
