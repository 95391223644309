import { useHistory as _useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";

const buildQueryString = (query) => {
  const values = Object.keys(query).map(
    (key) => `${key}=${encodeURIComponent(JSON.stringify(query[key]))}`
  );
  return "?" + values.join("&");
};

const useHistory = () => {
  const history = _useHistory();
  return {
    ...history,
    push: (uri, params = [], query) => {
      let queryparams = "";
      if (query) {
        queryparams = buildQueryString(query);
      }
      params.forEach((param, i) => {
        if (!uri.match(new RegExp("\\$" + (i + 1)))) {
          console.log(
            "Too many parameters for prepared statement",
            uri,
            params
          );
          throw new Error("Too many parameters for prepared statement");
        }
        uri = uri.replace(
          new RegExp("\\$" + (i + 1), "g"),
          encodeURIComponent(param)
        );
      });
      if (uri.match(new RegExp("\\$"))) {
        console.log("Too few parameters for prepared statement", uri, params);
        throw new Error("Too few parameters for prepared statement");
      }
      history.push("/" + uri + queryparams);
    },
  };
};

const useSearch = () => {
  const { search } = window.location; //useLocation();
  if (search) {
    const res = {};
    search
      .slice(1)
      .split("&")
      .forEach((part) => {
        const [key] = part.split("=", 1);
        res[key] = part.slice(key.length + 1);
        try {
          res[key] = JSON.parse(decodeURIComponent(res[key]));
        } catch (e) {}
      });
    return res;
  }
  return {};
};

const useHash = () => {
  const { hash } = window.location;
  if (hash) {
    return hash.slice(1).replace("#", "");
  }
  return "";
};

const useScrollToHash = () => {
  const hash = useHash();
  useEffect(() => {
    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  }, [hash]);
};

const useRedirector = () => {
  const queryParams = useSearch();
  const redirect = window.location.pathname.slice(1);
  let queryObj = {
    redirect,
    ...queryParams,
  };
  if (
    (redirect === "login" || redirect === "signup") &&
    !queryParams.redirect
  ) {
    queryObj = {};
  }
  return { str: buildQueryString(queryObj), obj: queryObj };
};

export {
  useHistory,
  useLocation,
  useSearch,
  useHash,
  useScrollToHash,
  useRedirector,
  buildQueryString,
};
