import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { colors, buttonTextOnlyStyle } from "helpers/theme";
import { Button } from "semantic-ui-react";
import { ReactComponent as Close } from "assets/close.svg";

const OfflineBanner = connect(
  ({ network }) => ({ network }),
  {}
)(({ network }) => {
  const [done, setDone] = useState(false);

  useEffect(() => {
    setDone(false);
  }, [network]);

  const shown = !(network || done);

  return (
    <div
      className="offline"
      style={{
        opacity: shown ? 1 : 0,
        pointerEvents: shown ? "" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        minHeight: 10,
        zIndex: 100000001,
        backgroundColor: colors.stateYellow[3],
        textAlign: "center",
        padding: "0px 5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: 50 }} />
      <span
        style={{
          lineHeight: "10px",
          color: colors.stateYellow[0],
          fontSize: 12,
        }}
      >
        Du scheinst offline zu sein.
      </span>
      <Button
        icon
        style={{
          ...buttonTextOnlyStyle,
          background: "none",
          margin: 0,
          marginRight: 20,
          padding: "3px 10px",
          width: 30,
          lineHeight: "15px",
          height: 27,
        }}
        onClick={setDone}
      >
        <Close
          style={{ width: "100%", height: "auto", fill: colors.stateYellow[0] }}
        />
      </Button>
    </div>
  );
});

export default OfflineBanner;
