import React from "react";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

import logo from "../assets/Logo.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons8-facebook-f-100-2.svg";
import { ReactComponent as InstagramIcon } from "assets/icons8-instagram-100-2.svg";

import urls from "config/urls";

const Footer = () => {
  const isMedium = useMediaQuery({ query: "(min-width: 700px)" });
  const is850 = useMediaQuery({ query: "(min-width: 850px)" });
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });

  return (
    <>
      <div
        className="footer_alles"
        style={{
          width: "100%",
        }}
      >
        <div style={{ backgroundColor: "#f4f6fa" }}>
          <div
            className="footer_obere_zeile"
            style={{
              minHeight: "150px",
              maxWidth: "1380px",
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
              paddingLeft: "30px",
              paddingRight: "30px",
              display: "flex",
              flexDirection: is850 ? "" : "column",
              textAlign: is850 ? "" : "center",
              paddingBottom: "70px",
              paddingTop: "90px",
              fontSize: "16px",
            }}
          >
            <div
              style={{
                minWidth: "200px",
                display: "flex",
                flexDirection: "column",
                marginRight: is850 ? "20%" : "",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Link to="/">
                  <Image
                    src={logo}
                    alt="Unitutors Logo"
                    style={{
                      width: "150px",
                      marginLeft: is850 ? "" : "auto",
                      marginRight: is850 ? "" : "auto",
                    }}
                  />
                </Link>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: is850 ? "" : "center",
                  marginTop: is850 ? "" : "40px",
                }}
              >
                <a
                  href={urls.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="footer_link_social_media"
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#387bf5",
                      borderRadius: "50% 45% 0 45%",
                      display: "flex",
                      boxShadow: "rgba(56, 123, 245, 0.1) 0px 0px 30px 0px",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <FacebookIcon
                      style={{
                        height: "20px",
                        width: "auto",
                        marginRight: "auto",
                        fill: "#ffffff",
                        marginLeft: "auto",
                      }}
                    />
                  </div>
                </a>
                <a
                  href={urls.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "25px" }}
                >
                  <div
                    className="footer_link_social_media"
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#fec143",
                      borderRadius: "45% 50% 45% 0",
                      boxShadow: "rgba(56, 123, 245, 0.1) 0px 0px 30px 0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <InstagramIcon
                      style={{
                        height: "20px",
                        width: "auto",
                        fill: "#ffffff",
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    />
                  </div>
                </a>
              </div>
            </div>
            <div
              style={{
                width: is850 ? "42%" : "100%",
                marginTop: is850 ? "" : "50px",
              }}
            >
              <Link to="/faq" style={{ marginTop: "8px" }}>
                Häufige Fragen
              </Link>
              <br />
              <a href={urls.contact} style={{ marginTop: "8px" }}>
                Kontakt
              </a>
              <br />
              <Link to="/pricing" style={{ marginTop: "8px" }}>
                Preise
              </Link>
            </div>
            <div
              style={{
                width: is850 ? "25%" : "100%",
                display: "flex",
                marginTop: is850 ? "" : "30px",
                justifyContent: "center",
              }}
            >
              <div>
                <Link to="/signup">Registrieren</Link>
                <br />
                <Link to="/login" style={{ marginTop: "8px" }}>
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#edf0f6" }}>
          <div
            style={{
              borderTop: "1px solid #f4f6fa",
              paddingTop: "40px",
              width: "100%",
              fontSize: "14px",
              fontWeight: "300",
              paddingBottom: is850 ? "75px" : "50px",
            }}
          >
            <div
              style={{
                maxWidth: "1380px",
                paddingRight: "30px",
                paddingLeft: "30px",
                marginRight: "auto",
                marginLeft: "auto",
                display: "flex",
                flexDirection: isMedium ? "" : "column",
                justifyContent: "space-between",
                textAlign: isMedium ? "" : "center",
              }}
            >
              <div>© 2020 Unitutors</div>
              <div
                className="footer_menu"
                style={{
                  display: "flex",
                  justifyContent: isMedium ? "" : "center",
                  marginTop: isMedium ? "" : "20px",
                  flexDirection: is500 ? "" : "column",
                }}
              >
                <Link to="/impressum">Impressum</Link>
                <Link to="/datenschutz">Datenschutz</Link>
                <Link to="/agb" style={{ marginRight: is500 ? 0 : "" }}>
                  AGB
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
