import React from "react";
import PropTypes from "prop-types";
import { inputStyle } from "helpers/theme";
import { Prompt } from "react-router-dom";
import {
  useFormikContext,
  Field as FField,
  ErrorMessage as FErrorMessage,
  useField,
  FormikConsumer,
} from "formik";
import { Dropdown, Button } from "semantic-ui-react";
import { buttonStyleDoubleShadow, buttonStyle } from "helpers/theme";

const Error = ({ message }) =>
  message && (
    <div
      style={{
        margin: 5,
        marginBottom: 15,
        color: "#8f2e23",
        lineHeight: "22px",
      }}
    >
      {message}
    </div>
  );

const ErrorMessage = ({ name }) => (
  <FErrorMessage name={name} render={(msg) => <Error message={msg} />} />
);

const Field = ({ name, style, textRight, ...rest }) => (
  <>
    <FField {...rest} name={name} style={{ ...inputStyle, ...style }} />
    <div
      style={{
        textAlign: "right",
        position: "relative",
        right: 25,
        marginTop: -45,
        paddingBottom: 50,
        height: 0,
        pointerEvents: "none",
      }}
    >
      {textRight}
    </div>
    <ErrorMessage name={name} />
  </>
);

const Conditional = ({ children, condition }) => {
  const { values } = useFormikContext();
  return condition(values) && children;
};

const IfElse = ({ then, elze, condition }) => {
  const { values } = useFormikContext();
  return condition(values) ? then : elze;
};

const FieldRow = ({ fields, minWidth = 200 }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        margin: -5,
      }}
    >
      {fields.map((field, i) => (
        <div key={i} style={{ margin: 5, flex: 1, minWidth }}>
          {field}
        </div>
      ))}
    </div>
  );
};
FieldRow.propTypes = {
  fields: PropTypes.array.isRequired,
  minWidth: PropTypes.number,
};

const ErrorMessageAny = ({ msg, style }) => {
  const { errors, touched } = useFormikContext();
  const keysTouched = Object.keys(touched);
  const error =
    Object.keys(errors).filter((key) => keysTouched.indexOf(key) !== -1)
      .length > 0;
  return (
    error && (
      <div
        style={{
          margin: 5,
          color: "#8f2e23",
          lineHeight: "22px",
          ...style,
        }}
      >
        {msg}
      </div>
    )
  );
};

const MultiSelectField = ({ name, style, ...rest }) => {
  const [{ value }, , { setValue, setTouched }] = useField(name);
  return (
    <>
      <Dropdown
        className="nolengthrestriction"
        id={name}
        name={name}
        style={{ ...inputStyle, ...style }}
        fluid
        multiple
        selection
        value={value}
        onChange={(_, data) => {
          setTouched(true, false);
          setValue(data.value);
        }}
        {...rest}
      />
      <ErrorMessage name={name} />
    </>
  );
};

const SubmitButton = ({
  loading,
  children,
  buttonProps,
  simple,
  fullWidth,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <Button
        className={
          simple
            ? "button_simple"
            : fullWidth
            ? "button_full_width"
            : "button_double_shadow_blue"
        }
        type="submit"
        loading={loading}
        style={{
          ...(simple
            ? buttonStyle
            : fullWidth
            ? buttonStyle
            : buttonStyleDoubleShadow),
          marginBottom: 10,
          ...(fullWidth && {
            height: "auto",
            paddingTop: 20,
            paddingBottom: 20,
          }),
        }}
        {...buttonProps}
      >
        {children}
      </Button>
      <ErrorMessageAny
        msg="Bitte überprüfe deine Eingabe!"
        style={{ textAlign: "right" }}
      />
    </div>
  );
};

const PromptIfDirty = () => (
  <FormikConsumer>
    {(formik) => (
      <Prompt
        when={Object.keys(formik.touched).length > 0}
        message="Du hast ungespeicherte Änderungen. Willst du die Seite wirklich verlassen?"
      />
    )}
  </FormikConsumer>
);

export {
  Field,
  Conditional,
  IfElse,
  FieldRow,
  Error as RawError,
  ErrorMessage,
  ErrorMessageAny,
  MultiSelectField,
  SubmitButton,
  PromptIfDirty,
};
