import { useState } from "react";
import { post } from "helpers/api";

import { buildQueryString } from "helpers/navigation";
import urls from "config/urls";

const usePaypal = (user, actionCode, redirectParams) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    setLoading(true);
    post("users/$1/subscriptions", [user.uuid])
      .data({
        promoCode: actionCode || undefined,
        cancel: "https://" + urls.domain + "/payment",
        success:
          "https://" +
          urls.domain +
          "/payment" +
          buildQueryString(redirectParams),
      })
      .auth(user.auth)
      .then(({ success, href, error }) => {
        if (!success) {
          alert(error);
        }
        setLoading(false);
        window.location.href = href;
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
      });
  };
  return { onSubmit, loading };
};

export default usePaypal;
