import React, { useState, useEffect } from "react";
import { Footer, NoSearchHeader, FieldRow, RawError } from "components";
import { Loader, Accordion, Radio, Button } from "semantic-ui-react";
import { useHistory, useSearch } from "helpers/navigation";
import { get } from "helpers/api";
import { connect } from "react-redux";
import {
  buttonStyleDoubleShadow,
  inputStyle,
  buttonStyle,
  colors,
} from "helpers/theme";
import { loadUser } from "redux/user";
import useStripe, { CardElement } from "./useStripe";
import usePaypal from "./usePaypal";
import payment from "config/payment";
import Display from "./PaymentDisplay";
import tickBlue from "assets/tick_blue.svg";

const { stripe, actionCodes } = payment;

const PaymentPage = ({ user, loadUser, fetchingUser }) => {
  const {
    tId,
    redirect,
    paymentDone: paypalDone,
    ...searchParams
  } = useSearch();

  const history = useHistory();
  const [tutor, setTutor] = useState(null);
  useEffect(() => {
    loadUser();
  }, [loadUser, user.auth]);

  useEffect(() => {
    if (user.subscribed) {
      history.push("paymentDone", [], { ...searchParams, redirect });
    }
  }, [history, user.subscribed, searchParams, redirect]);

  useEffect(() => {
    if (tId) {
      get("users/tutors/$1", [tId])
        .auth(user.auth)
        .then((tutor) => {
          setTutor(tutor);
        })
        .catch(() => {});
    }
  }, [tId, user.auth]);

  const [activeCode, setActiveCode] = useState(null);
  const [codeInput, setCodeInput] = useState("");

  const onSetCode = () => {
    if (actionCodes[codeInput]) {
      setActiveCode(actionCodes[codeInput]);
    } else {
      setActiveCode(false);
    }
  };

  const [stripeDone, setStripeDone] = useState(false);
  useEffect(() => {
    if (paypalDone === true || stripeDone === true) {
      const interval = setInterval(() => {
        get("users/$1/subscriptions", [user.uuid])
          .auth(user.auth)
          .then(({ subscribed }) => {
            if (subscribed) {
              clearInterval(interval);
              loadUser();
            }
          });
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
    return () => {};
  }, [paypalDone, stripeDone, user.auth, user.uuid, loadUser, fetchingUser]);

  const { onSubmit: onPaypal, loading } = usePaypal(
    user,
    activeCode ? codeInput : null,
    {
      ...searchParams,
      redirect,
      paymentDone: true,
    }
  );

  const { buttonDisabled, onSubmit, isSubscribing } = useStripe(
    user,
    activeCode ? codeInput : null,
    () => {
      setStripeDone(true);
    }
  );

  const [paymentMethod, setPaymentMethod] = useState(false);
  const paymentMethods = [
    {
      id: "paypal",
      title: "Paypal/Lastschrift",
      content: (
        <Button
          style={{
            ...buttonStyleDoubleShadow,
            width: "auto",
          }}
          onClick={onPaypal}
          loading={loading}
        >
          Weiter zu Paypal
        </Button>
      ),
    },
    {
      id: "stripe",
      title: "Kreditkarte",
      content: (
        <form onSubmit={onSubmit}>
          <div style={inputStyle}>
            <CardElement />
          </div>
          <Button
            style={{ ...buttonStyleDoubleShadow, width: "auto" }}
            loading={isSubscribing}
            disabled={buttonDisabled}
          >
            Buchung bestätigen
          </Button>
        </form>
      ),
    },
  ];

  if (!tutor && tId) {
    return <Loader active />;
  }

  if (paypalDone || stripeDone) {
    return (
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <p style={{ paddingTop: 100 }}>
          Danke für dein Abo. Wir verarbeiten deine Anfrage. Dies kann bis zu 2
          Minuten dauern.
        </p>
        <Loader active />
      </div>
    );
  }

  return (
    <>
      <NoSearchHeader />
      <Display
        {...tutor}
        tId={tId}
        price={activeCode ? activeCode.price : undefined}
      >
        <h3 style={{ marginTop: 80 }}>Zahlungsart</h3>
        <Accordion style={{ marginLeft: 10, marginTop: 20, marginBottom: 80 }}>
          {paymentMethods.map(({ title, content, id }) => (
            <div key={id}>
              <Accordion.Title>
                <Radio
                  className="paymentRadio"
                  checked={paymentMethod === id}
                  value={id}
                  onChange={(_, { value }) => setPaymentMethod(value)}
                  label={title}
                />
              </Accordion.Title>
              <Accordion.Content active={paymentMethod === id}>
                <div style={{ marginLeft: 20, marginBottom: 40 }}>
                  {content}
                </div>
              </Accordion.Content>
            </div>
          ))}
        </Accordion>
        <h3 style={{ marginTop: 80 }}>Gutscheincode</h3>
        {activeCode ? (
          <div style={{ display: "flex", alignItems: "center", marginTop: 25 }}>
            <img src={tickBlue} style={{ width: 30, height: 30 }} alt="" />

            <p
              style={{
                color: colors.primary,
                fontWeight: "bold",
                fontSize: 19,
                marginLeft: 15,
                marginBottom: 0,
              }}
            >
              {activeCode.text.split(":")[0]}
            </p>
            <p style={{ marginLeft: 10, color: colors.color1[3] }}>
              {" "}
              {activeCode.text.split(":")[1]}
            </p>
          </div>
        ) : (
          <>
            {" "}
            <FieldRow
              fields={[
                <input
                  style={inputStyle}
                  onChange={(e) =>
                    setCodeInput((e.target.value || "").toUpperCase().trim())
                  }
                  value={codeInput}
                />,
                <Button
                  disabled={codeInput.length === 0}
                  className="button_full_width"
                  onClick={onSetCode}
                  style={{
                    ...buttonStyle,
                    marginTop: 10,
                    marginBottom: 0,
                    fontSize: "16px",
                    minHeight: 64,
                  }}
                >
                  Einlösen
                </Button>,
              ]}
            />
            <RawError
              message={activeCode === false && "Dieser Code existiert nicht."}
            />
          </>
        )}
        <div style={{ lineHeight: "25px", marginTop: 50 }}>
          Das Abo wird automatisch verlängert, du kannst es jederzeit bis 3 Tage
          vor Verlängerung kündigen.
        </div>
      </Display>
      <Footer />
    </>
  );
};

export default connect(
  ({ user, fetching: { user: fetchingUser } }) => ({ user, fetchingUser }),
  { loadUser }
)(PaymentPage);
