import React from "react";

import {
  CustomSlider,
  SearchBar,
  SpecialButton,
  Footer,
  NoSearchHeader,
} from "components";

import background1 from "assets/Background_Startseite.svg";
import sliderIconEins from "assets/Icon_Tutoren_Suchen.svg";
import sliderIconZwei from "assets/Icon_Tutoren_kontaktieren.svg";
import sliderIconDrei from "assets/Icon_Geld_Zurück.svg";
import backgroundStudenten from "assets/Background_Startseite_Studenten.jpg";
import lilaPfeil from "assets/LilaPfeilinLilaBox.svg";
import tickBlue from "assets/tick_blue.svg";
import backgroundTutoren from "assets/BackgroundStartseiteTutorDesktop.jpg";

import { useMediaQuery } from "react-responsive";

const LandingPage = () => {
  const is850 = useMediaQuery({ query: "(min-width: 850px)" });
  const isMedium = useMediaQuery({ query: "(min-width: 700px)" });
  const is980 = useMediaQuery({ query: "(min-width: 980px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const isWide = useMediaQuery({ query: "(min-width: 1250px)" });

  return (
    <>
      <NoSearchHeader />
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/1a2ed066-4ffd-4639-9bea-67ed2d4c842c/cd.js"
        type="text/javascript"
        async
      ></script>
      <div
        id={"Paragraph1"}
        style={{
          backgroundImage: is850 ? `url(${background1})` : "",
          backgroundSize: "2600px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingTop: 120,
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              maxWidth: 1380,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  padding: isMedium ? 30 : 10,
                  maxWidth: "100vw",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    minHeight: 96,
                    height: 96,
                  }}
                ></div>
                <div
                  style={{
                    marginBottom: 50,
                    marginLeft: isMedium ? "20px" : 0,
                  }}
                >
                  <h1
                    style={{
                      fontSize: isMedium ? 45 : 30,
                      lineHeight: "50px",
                      fontWeight: "normal",
                      textAlign: isMedium ? "left" : "center",
                    }}
                  >
                    Nachhilfe{isMedium && <br />} für deinen Studiengang:
                  </h1>
                </div>
                <SearchBar />
                <div
                  style={{
                    marginTop: 120,
                  }}
                >
                  <div
                    style={{
                      fontSize: "19px",
                      textAlign: isMedium ? "left" : "center",
                    }}
                  >
                    So funktioniert
                    <span style={{ fontWeight: "bold" }}> Unitutors:</span>
                  </div>

                  <CustomSlider
                    sliderFormLegendFontSize="20px"
                    sliderHeadlineFontSize="16px"
                    sliderTextFontSize="16px"
                    sliderTextLineHeight="28px"
                    sliderHeadline={[
                      "Tutoren finden",
                      "Tutoren kontaktieren",
                      "30 Tage Geld-zurück Garantie",
                    ]}
                    sliderText={[
                      "Gib einfach deine Uni & deinen Studiengang an und schon werden dir alle passenden Tutoren angezeigt.",
                      "Kontaktiere so viele Tutoren wie du möchtest - für nur 29 € pro Semester.",
                      "Wenn du keinen passenden Tutor findest oder nicht zufrieden warst, bekommst du dein Geld zurück.",
                    ]}
                    sliderImage={[
                      sliderIconEins,
                      sliderIconZwei,
                      sliderIconDrei,
                    ]}
                  ></CustomSlider>
                </div>
                <div
                  style={{
                    minHeight: 96,
                    height: 96,
                  }}
                ></div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id={"Paragraph2jn"}
        style={{
          maxWidth: "1480px",
          width: "100%",
          marginTop: "50px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: isMedium ? "50px" : 20,
          paddingRight: isMedium ? "50px" : 20,
        }}
      >
        <div
          style={{
            width: isDesktop ? "60%" : "100%",
          }}
        >
          <div
            style={{
              color: "#387bf5",
              fontSize: isMedium ? 20 : 18,
              lineHeight: "48px",
              marginBottom: "-7px",
            }}
          >
            Unitutors für Studenten
          </div>
          <div>
            <span
              style={{
                fontSize: isMedium ? 42 : 28,
                lineHeight: "48px",
              }}
            >
              Tutoring für Studenten
            </span>
          </div>
          <div
            style={{
              marginBottom: 20,
              marginTop: 25,
            }}
          ></div>
          <span
            style={{
              fontSize: 18,
              lineHeight: "30px",
              fontWeight: "300",
            }}
          >
            Bei Unitutors findest du Studenten aus deinem Studiengang aus
            höheren Semestern, die dir weiterhelfen können - Einmalig & spontan
            oder regelmäßig.
          </span>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundImage: is980 ? `url(${backgroundStudenten})` : "",
          backgroundSize: "2600px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          id={"Paragraph2untenjn"}
          style={{
            maxWidth: "1480px",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: isMedium ? "50px" : 10,
            paddingRight: isMedium ? "50px" : 10,
            paddingBottom: "80px",
          }}
        >
          <div
            style={{
              display: "flex",
              paddingTop: is980 ? "120px" : isMedium ? "80px" : 60,
              flexDirection: is980 ? "" : "column",
            }}
          >
            <div
              style={{
                width: "50%",
                minHeight: "766px",
                position: "relative",
                display: is980 ? "" : "none",
              }}
            ></div>
            <div
              style={{
                width: is980 ? "50%" : "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  flexDirection: "column",
                  paddingLeft: isWide ? 70 : isMedium ? 20 : 0,
                }}
              >
                {[
                  {
                    title: "Einfach",
                    text:
                      "Finde einen geeigneten Tutor, auch ohne Registrierung.",
                  },
                  {
                    title: "Individuell",
                    text:
                      "Finde den perfekten Tutor aus einem höheren Semester, individuell zu deiner Uni und deinem Studiengang.",
                  },
                  {
                    title: "30 Tage Geld-zurück-Garantie",
                    text:
                      "Wenn kein Tutor deine Anfrage angenommen hat oder du mit dem Unterricht nicht zufrieden warst, bekommst du dein Geld zurück.",
                  },
                ].map(({ title, text }) => (
                  <div key={title} style={{ display: "flex" }}>
                    <div style={{ color: "#387bf5" }}>
                      <img
                        src={tickBlue}
                        style={{ width: "54px", height: "54px" }}
                        alt="Blauer Pfeil Unitutors"
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: isMedium ? 70 : 30,
                        width: "500px",
                      }}
                    >
                      <div
                        style={{
                          lineHeight: "32px",
                          marginTop: "0px",
                          marginBottom: "10px",
                          fontSize: isMedium ? 25 : 20,
                        }}
                      >
                        {title}
                      </div>
                      <div
                        style={{
                          marginBottom: "50px",
                          fontSize: 16,
                          fontWeight: "300",
                          lineHeight: "32px",
                        }}
                      >
                        {text}
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    margin: isMedium
                      ? "20px 20px 0px -20px"
                      : "20px 0px 0px 0px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: isMedium ? 38 : 30,
                      textAlign: isMedium ? "left" : "center",
                      marginLeft: isMedium ? "20px" : 0,
                      marginBottom: "50px",
                      fontWeight: 400,
                      lineHeight: isMedium ? "50px" : "45px",
                    }}
                  >
                    Finde einen Tutor aus deiner Uni:
                  </h2>
                  <SearchBar maxVw={is980 ? 50 : 100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id={"Paragraph3jn"}
        style={{
          width: "100%",
          backgroundImage: is850 ? `url(${backgroundTutoren})` : "",
          backgroundSize: "2600px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            maxWidth: "1480px",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: isMedium ? "50px" : 20,
            paddingRight: isMedium ? "50px" : 20,
            paddingTop: isMedium ? "170px" : 100,
            paddingBottom: isMedium ? "150px" : 100,
          }}
        >
          <div
            style={{
              width: is850 ? "50%" : "100%",
              minHeight: "782px",
            }}
          >
            <div
              style={{
                color: "#9138f5",
                fontSize: isMedium ? "20px" : "18px",
                lineHeight: "48px",
                marginBottom: "-7px",
              }}
            >
              Unitutors für Tutoren
            </div>
            <div
              style={{
                marginBottom: "26px",
              }}
            >
              <span
                style={{
                  fontSize: isMedium ? 42 : 32,
                  display: isMedium ? "" : "none",
                  lineHeight: "48px",
                }}
              >
                Flexibel arbeiten,
                <br />
                flexibel leben
              </span>
              <span
                style={{
                  fontSize: isMedium ? 42 : 32,
                  display: isMedium ? "none" : "",
                  lineHeight: "48px",
                }}
              >
                Flexibel arbeiten, flexibel leben
              </span>
            </div>
            <span
              style={{
                fontSize: 18,
                lineHeight: "30px",
                fontWeight: "300",
              }}
            >
              Das harte Lernen vor den Klausuren zahlt sich wortwörtlich aus!
              Als Tutor verdienst du bis zu 800€ pro Monat, studierst
              motivierter und schreibst bessere Noten.
            </span>
            <div
              style={{
                paddingBottom: 20,
                marginTop: 30,
                fontSize: 18,
                fontWeight: "600",
              }}
            >
              Entscheide selbst, wann und wie oft du arbeitest und wieviel du
              verdienst.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: isWide ? "" : "column",
                flexWrap: "wrap",
                justifyContent: "left",
                fontSize: 18,
              }}
            >
              {[
                <span style={{ marginRight: "60px" }}>
                  {" "}
                  Maximale Flexibilität{" "}
                </span>,
                "Eigener Stundenlohn",
                "Keine Provision",
              ].map((text) => (
                <div
                  key={text}
                  style={{
                    display: "flex",
                    padding: 0,
                    paddingTop: 40,
                    flexDirection: "row",
                  }}
                >
                  <img
                    src={lilaPfeil}
                    alt="lila Pfeil Unitutors"
                    style={{ height: 30 }}
                  />
                  <div
                    style={{
                      marginLeft: 30,
                    }}
                  >
                    {text}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="button_lila"
              style={
                is850
                  ? {
                      margin: "60px 20px 0px 20px",
                    }
                  : {
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: 10,
                      marginTop: 50,
                    }
              }
            >
              <SpecialButton
                text="Jetzt Tutor werden"
                bColor="#9a49f6"
                sColor="#e9d7fd"
                href="/tutorwerden"
              ></SpecialButton>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div
        id={"Paragraph4"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            id={"Paragraph5"}
            style={{
              height: "324px",
              textAlign: "center",
              justifyContent: "center",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              marginTop: 80,
              marginBottom: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "inline-block",
                maxWidth: 1380,
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      margin: isMedium ? 25 : 20,
                      fontSize: isMedium ? 38 : 28,
                    }}
                  >
                    Finde jetzt den passenden Tutor:
                  </div>
                  <div style={{ margin: isMedium ? 25 : 10 }}>
                    <SearchBar rahmenFarbe="#f4f6fa" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
