import React, { useState, useCallback, useEffect } from "react";
import { Footer, SearchHeader } from "components";
import { useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { buttonStyleDoubleShadow } from "helpers/theme";
import { useMediaQuery } from "react-responsive";
import ReactMarkdown from "react-markdown";
import { loadUser } from "redux/user";
import { connect } from "react-redux";
import { useHistory, useRedirector } from "helpers/navigation";
import { get, post } from "helpers/api";

const Course = ({ user: me, loadUser }) => {
  const { id } = useParams();

  const [availableCourses, setCourses] = useState([]);
  useEffect(() => {
    get("crashcourse").then((crashcourses) => {
      setCourses(crashcourses);
    });
  }, []);

  const course = availableCourses.filter(({ id: cId }) => cId == id)[0] || {};

  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const is850 = useMediaQuery({ query: "(min-width: 850px)" });
  const is950 = useMediaQuery({ query: "(min-width: 950px)" });
  const is500 = useMediaQuery({ query: "(min-width: 500px)" });

  const history = useHistory();
  const redirectQuery = useRedirector();
  const bookFreeCourse = useCallback(
    (params) => {
      if (!me.uuid) {
        history.push("signup", [], redirectQuery.obj);
      } else {
        post("crashcourse/members")
          .data({
            userId: me.uuid,
            firstName: me.firstName,
            lastName: me.lastName,
            emailAddress: me.emailAddress,
            courseName: course.courseTitleSite,
            courseLink: "https://unitutors.de/course/" + id,
            testPeriod: true,
          })
          .then(() => {
            alert(
              "Du bist erfolgreich eingeschrieben! Wir haben dir eine Email mit den Details geschickt."
            );
          })
          .catch(() => {
            alert(
              "Es ist etwas schiefgegangen. Bitte versuche es später nochmal"
            );
          });
      }
    },
    [history, id, me, redirectQuery, course]
  );

  const sendRequest = useCallback(
    (params) => {
      if (!me.uuid) {
        history.push("signup", [], redirectQuery.obj);
      } else {
        history.push("coursePayment", [], {
          courseId: id,
        });
      }
    },
    [history, id, me.uuid, redirectQuery.obj]
  );

  const showButton = course.courseAvailable;

  return (
    <>
      <SearchHeader />
      <div>
        <div
          style={{
            borderBottom: "1px solid",
            borderColor: "#edf0f6",
          }}
        >
          <div
            style={{
              paddingLeft: isDesktop ? 50 : 20,
              paddingRight: isDesktop ? 50 : 20,
              maxWidth: "1200px",
              width: "100%",
              marginRight: "auto",
              marginLeft: "auto",
              paddingTop: is500 ? 150 : 120,
              paddingBottom: 50,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: showButton || is950 ? "center" : "",
                flexDirection: showButton || is950 ? "" : "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: is500 ? "" : "column",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <img
                  alt={`Logo`}
                  src={course.courseImg}
                  style={{
                    borderRadius: 1000,
                    width: is500 ? 150 : 200,
                    height: is500 ? 150 : 200,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 30,
                      marginBottom: 30,
                      justifyContent: is500 ? "" : "center",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: is850 ? 50 : is500 ? 30 : 0,
                      }}
                    >
                      <h1
                        style={{
                          fontSize: "34px",
                        }}
                      >
                        {course.courseTitleSite}
                      </h1>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: is500 ? "" : "center",
                      flexDirection: is500 ? "" : "column",
                      flexWrap: "wrap",
                      marginLeft: is850 ? "50px" : is500 ? 30 : 0,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgb(56, 123, 245)",
                        color: "white",
                        borderTopLeftRadius: "120px",
                        borderTopRightRadius: "100px",
                        borderBottomRightRadius: "100px",
                        borderBottomLeftRadius: "0px",
                        margin: "10px 20px 6px 0px",
                        padding: "3px 20px",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "24px",
                        fontWeight: 600,
                        marginRight: 40,
                      }}
                    >
                      {course.courseStudy}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingLeft: isDesktop ? 50 : 30,
            paddingRight: isDesktop ? 50 : 30,
            maxWidth: "1200px",
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            marginBottom: 100,
          }}
        >
          <h3
            style={{
              marginTop: 40,
              marginBottom: 20,
              fontWeight: 600,
              fontSize: "22px",
            }}
          >
            Was erwartet dich in diesem Kurs?
          </h3>
          <p style={{ fontSize: "16px" }}>
            {course.courseExamdate && (
              <>
                Unser Online Crashkurs bereitet dich auf die Klausur am{" "}
                {course.courseExamdate} vor.
              </>
            )}
            <ul style={{ marginTop: 10 }}>
              <li>
                Die unterschiedlichen <b>Aufgabentypen</b> werden erläutert und{" "}
                <b>Rezepte/Vorgehensweisen</b> für diese erstellt.
              </li>
              <li>
                <b>Wertvolle Tipps und Tricks</b> für die Klausur
              </li>
              <li>
                Nach dem Kurs hast du{" "}
                <b>15 Minuten freie Zeit mit dem Tutor </b>
                alleine. In dieser kannst du deine Fragen in Ruhe klären.
              </li>
              <li>
                <b>Ziel:</b> Mit wenig Aufwand möglichst alle wichtigen und
                klausurrelevanten Themen verstehen. Es werden die wichtigsten
                Dinge, die auf jeden Fall benötigt werden, verständlich erklärt.
              </li>
            </ul>
          </p>
          <h3
            style={{
              marginTop: 40,
              marginBottom: 20,
              fontWeight: 600,
              fontSize: "22px",
            }}
          >
            Für wen ist dieser Kurs geeignet?
          </h3>
          <p style={{ fontSize: "16px" }}>
            Du hast ein Grundverständnis und dich bereits mit den einzelnen
            Themen und Aufgaben auseinander gesetzt. Möchtest du alles von Grund
            auf erklärt bekommen, bist du bei diesem Kurs falsch.
          </p>

          {course.courseAboutTutor && (
            <>
              <h3
                style={{
                  marginTop: 40,
                  marginBottom: 20,
                  fontWeight: 600,
                  fontSize: "22px",
                }}
              >
                Über den Tutor:
              </h3>
              <div style={{ fontSize: "16px" }}>
                <ReactMarkdown>{course.courseAboutTutor}</ReactMarkdown>
              </div>
            </>
          )}
          <div>
            <h3
              style={{
                marginTop: 60,
                marginBottom: 30,
                fontWeight: 600,
                fontSize: "26px",
              }}
            >
              Kostenloser Kurs
            </h3>
            <p style={{ fontSize: "16px" }}>
              In unserem kostenlosen Kurs wird eines der klausurrelevanten
              Themen behandelt. Schreibe dich einfach ein und nimm daran teil.
            </p>
            {course.testCourseDate && (
              <p style={{ fontSize: "16px" }}>
                <b>Termin: </b>
                {course.testCourseDate}
              </p>
            )}
            {course.testCourseDuration && (
              <p style={{ fontSize: "16px" }}>
                <b>Dauer: </b>
                {course.testCourseDuration}
              </p>
            )}
            <p style={{ fontSize: "16px" }}>
              <b>Ort: </b>Online per Zoom
            </p>
            {course.courseAvailable ? (
              <>
                <div
                  style={{
                    color: "rgb(53, 170, 160)",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginTop: "0px",
                    marginBottom: "10px",
                  }}
                >
                  Freie Plätze verfügbar
                </div>
                <Button
                  className="button_double_shadow_blue"
                  onClick={bookFreeCourse}
                  style={{
                    ...buttonStyleDoubleShadow,
                    marginTop: 25,
                  }}
                >
                  Kostenlos einschreiben
                </Button>
              </>
            ) : (
              <div
                style={{
                  color: "rgb(136, 69, 69)",
                  fontWeight: 500,
                  marginBottom: "10px",
                  fontSize: "18px",
                  marginTop: "0px",
                }}
              >
                Keine Plätze mehr frei
              </div>
            )}
          </div>
          <div>
            <h3
              style={{
                marginTop: 60,
                marginBottom: 30,
                fontWeight: 600,
                fontSize: "26px",
              }}
            >
              Weiterführung des kostenlosen Kurses
            </h3>
            <p style={{ fontSize: "16px" }}>
              In der Weiterführung werden alle anderen klausurrelevanten Themen
              behandelt.
            </p>
            {course.courseDates && (
              <>
                <p style={{ fontSize: "16px" }}>
                  <b>Termine: </b>
                </p>
                <div style={{ fontSize: "16px", marginTop: "-10px" }}>
                  <ReactMarkdown>{course.courseDates}</ReactMarkdown>
                </div>
              </>
            )}
            {course.courseDuration && (
              <p style={{ fontSize: "16px" }}>
                <b>Dauer: </b>
                {course.courseDuration}
              </p>
            )}
            <p style={{ fontSize: "16px" }}>
              <b>Ort: </b>Online per Zoom
            </p>
            <p style={{ fontSize: "16px" }}>
              <b>Bonus: </b>Du erhälst unseren Student Pass im Wert von 29 Euro
              geschenkt
            </p>
            <p style={{ fontSize: "16px" }}>
              <b>{course.coursePriceHour} € pro Stunde </b>
              <span style={{ fontSize: "15px" }}>
                / {course.coursePriceTotal} € Gesamt
              </span>
            </p>
            {course.courseAvailable ? (
              <>
                <div
                  style={{
                    color: "rgb(53, 170, 160)",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginTop: "0px",
                    marginBottom: "10px",
                  }}
                >
                  Freie Plätze verfügbar
                </div>{" "}
                <Button
                  className="button_double_shadow_blue"
                  onClick={sendRequest}
                  style={{
                    ...buttonStyleDoubleShadow,
                    marginTop: 25,
                  }}
                >
                  Teilnehmen
                </Button>
              </>
            ) : (
              <div
                style={{
                  color: "rgb(136, 69, 69)",
                  fontWeight: 500,
                  fontSize: "18px",
                  marginTop: "0px",
                  marginBottom: "10px",
                }}
              >
                Keine Plätze mehr frei
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
Course.propTypes = {};

export default connect(({ user }) => ({ user }), { loadUser })(Course);
