import { useState, useEffect, useCallback } from "react";
import { useEffectWhen } from "helpers/util";
import { get, useDebounce } from "helpers/api";
import * as R from "ramda";

const getAdditionals = (settings, searchDebounced) => {
  const { canAddNew, canClear, clearLabel } = settings;
  const additionalBefore = [],
    additionalAfter = [];
  if (canAddNew) {
    additionalAfter.push({
      text: `"${searchDebounced}" hinzufügen`,
      value: "new",
    });
  }
  if (canClear) {
    additionalBefore.push({
      text: clearLabel,
      value: "all",
    });
  }
  return { additionalBefore, additionalAfter };
};

const usePreloadSearch = (
  request,
  setOptions,
  responseTransformer,
  when = [],
  settings = {}
) => {
  useEffect(() => {
    request().then((result) => {
      const { additionalBefore, additionalAfter } = getAdditionals(
        settings,
        ""
      );
      setOptions([
        ...additionalBefore,
        ...responseTransformer(result),
        ...additionalAfter,
      ]);
    });
    // eslint-disable-next-line
  }, when);
};

const useSearch = (
  request,
  responseTransformer,
  dependencies = [],
  settings = {}
) => {
  const { canClear } = settings;
  const [options, setOptions] = useState(
    settings.defaultSelection
      ? [{ text: settings.defaultSearch, value: settings.defaultSelection }]
      : []
  );
  const [search, setSearch] = useState(settings.defaultSearch || "");
  const searchDebounced = useDebounce(search, 150);
  const [loading, setLoading] = useState(0);
  const [selection, setSelection] = useState(settings.defaultSelection || null);
  const _setSelection = useCallback(
    (value) => {
      if (canClear && value === "all") {
        setSelection(null);
      } else {
        setSelection(value);
      }
    },
    [canClear]
  );

  const searchFun = (options, query) => {
    return options;
  };

  useEffectWhen(
    () => {
      if (searchDebounced && request) {
        setLoading(loading + 1);
        request(searchDebounced)
          .then(({ matches }) => {
            const { additionalBefore, additionalAfter } = getAdditionals(
              settings,
              searchDebounced
            );
            setOptions([
              ...additionalBefore,
              ...matches
                .sort(({ matchingScore: a }, { matchingScore: b }) => b - a)
                .map(responseTransformer),
              ...additionalAfter,
            ]);
            setLoading(Math.max(0, loading - 1));
          })
          .catch((e) => {
            console.log("Error in Uni-Course-Search", e);
            setLoading(Math.max(0, loading - 1));
          });
      }
    },
    [searchDebounced, ...dependencies, loading],
    [searchDebounced]
  );
  return {
    searchFun,
    options,
    setOptions,
    search,
    setSearch,
    selection,
    setSelection: _setSelection,
    loading,
  };
};

const useSearchUni = ({ defaultSearch = "", defaultSelection = null } = {}) => {
  const transformer = ({ match: { id, shortName } }) => ({
    text: shortName,
    value: id,
  });

  const {
    setOptions: setUniversities,
    options: universities,
    setSearch: setUniSearch,
    selection: uni,
    setSelection: setUni,
    loading: loadingUnis,
    searchFun: searchFunUnis,
  } = useSearch((search) => get("universities/$1", [search]), transformer, [], {
    defaultSearch,
    defaultSelection,
  });

  usePreloadSearch(
    () => get("universities").query({ limit: 5 }),
    (newUnis) =>
      setUniversities(R.uniqBy(JSON.stringify, [...universities, ...newUnis])),
    ({ universities }) =>
      universities.map(({ id, fullName }) => ({
        text: fullName,
        value: id,
      }))
  );

  return {
    searchFunUnis,
    universities,
    setUniSearch,
    uni,
    setUni,
    loadingUnis,
  };
};

const useSearchCourse = (uni) => {
  const {
    options: courses,
    setOptions: setCourses,
    setSearch: setCourseSearch,
    selection: course,
    setSelection: setCourse,
    loading: loadingCourses,
    searchFun: searchFunCourses,
  } = useSearch(
    (search) =>
      uni
        ? get("universities/$1/courses/$2", [uni, search])
        : Promise.resolve({ match: [] }),
    ({ match: { id, name } }) => ({
      text: name,
      value: id,
    }),
    [uni]
  );

  usePreloadSearch(
    () =>
      uni
        ? get("universities/$1/courses", [uni]).query({ limit: 5 })
        : Promise.resolve({ courses: [] }),
    (newCourses) =>
      setCourses(
        R.uniqBy(JSON.stringify, [...courses, ...newCourses]).filter(
          ({ universityId }) => universityId === uni
        )
      ),
    ({ courses }) =>
      courses.map(({ id, name }) => ({
        text: name,
        universityId: uni,
        value: id,
      })),
    [uni]
  );

  useEffect(() => {
    setCourseSearch("");
    setCourse(null);
  }, [uni, setCourseSearch, setCourse]);

  return {
    searchFunCourses,
    courses,
    setCourseSearch,
    course,
    setCourse,
    loadingCourses,
  };
};

const useSearchModule = (uni, course, searchOptions) => {
  const {
    options: modules,
    setOptions: setModules,
    setSearch: setModuleSearch,
    search: moduleSearch,
    selection: module,
    setSelection: setModule,
    loading: loadingModules,
    searchFun: searchFunModules,
  } = useSearch(
    (search) =>
      uni && course
        ? get("universities/$1/courses/$2/modules/$3", [uni, course, search])
        : Promise.resolve({ match: [] }),
    ({ match: { id, name } }) => ({
      text: name,
      value: id,
    }),
    [uni, course],
    searchOptions
  );

  usePreloadSearch(
    () =>
      uni && course
        ? get("universities/$1/courses/$2/modules", [uni, course]).query({
            limit: 5,
          })
        : Promise.resolve({ modules: [] }),
    (newModules) =>
      setModules(
        R.uniqBy(JSON.stringify, [...modules, ...newModules]).filter(
          ({ courseId, value }) => courseId === course || value === "all"
        )
      ),
    ({ modules }) =>
      modules.map(({ id, name }) => ({
        text: name,
        value: id,
        courseId: course,
      })),
    [course],
    searchOptions
  );

  useEffect(() => {
    setModuleSearch("");
    setModule(null);
  }, [course, setModuleSearch, setModule]);

  return {
    searchFunModules,
    modules,
    setModuleSearch,
    moduleSearch,
    module,
    setModule,
    loadingModules,
  };
};

export { useSearchUni, useSearchCourse, useSearchModule, useSearch };
