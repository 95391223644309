import React from "react";

const Label = ({ style, text, children, secondary, pill = false }) => {
  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        marginRight: -20,
        paddingLeft: secondary ? 27 : 12,
        paddingRight: 12,
        zIndex: secondary ? 1 : 2,
        color: secondary ? "#387bf5" : "white",
        background: secondary ? "#dfeafc" : "#387bf5",
        borderRadius: pill ? 100 : "45px 30px 30px 0",
        maxWidth: 120,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: 12,
        lineHeight: "24px",
        ...style,
      }}
    >
      {text ? text.toUpperCase() : children}
    </div>
  );
};

export default Label;
