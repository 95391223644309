import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Wrapper, FieldRow, ProfileSidebar, ExplainPopup } from "components";
import { Loader, Button } from "semantic-ui-react";
import { useHistory } from "helpers/navigation";
import { connect } from "react-redux";
import { loadTutor, setTutorFetching } from "redux/user";
import { del, post } from "helpers/api";
import {
  useSearchUni,
  useSearchCourse,
  useSearchModule,
} from "components/UniSearch/searchEffects";
import Dropdown from "components/UniSearch/SearchDropdown";
import {
  inputStyle,
  buttonStyleDoubleShadow,
  buttonStyle,
} from "helpers/theme";
import { ReactComponent as Mülleimer } from "assets/icons8-löschen-100-3.svg";
import { ReactComponent as Haekchen } from "assets/icons8-häkchen-100-2.svg";
import backgroundFaecher from "assets/Background_Meine_Fächer.svg";

const SelectSubjects = ({
  user,
  loadTutor,
  tutorFetching,
  setTutorFetching,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!user.isTutor) {
      history.push("tutorwerden");
    }
  }, [user, history]);

  useEffect(() => {
    loadTutor();
  }, [loadTutor]);

  const isBecomingTutor = /signup/.test(window.location.search);

  const {
    universities,
    setUniSearch,
    uni,
    setUni,
    loadingUnis,
    searchFunUnis,
  } = useSearchUni();

  const {
    courses,
    setCourseSearch,
    course,
    setCourse,
    loadingCourses,
    searchFunCourses,
  } = useSearchCourse(uni);

  const {
    modules,
    setModuleSearch,
    moduleSearch,
    module,
    setModule,
    loadingModules,
    searchFunModules,
  } = useSearchModule(uni, course, { canAddNew: true });

  const deleteModule = (lessonId) => {
    setTutorFetching();
    del("users/tutors/$1/lessons/$2", [user.uuid, lessonId])
      .auth(user.auth)
      .then(() => {
        loadTutor();
      });
  };

  const myModules = user.tutor.modules || [];
  const [loading, setLoading] = useState(false);
  const putModule = (module) =>
    post("users/tutors/$1/lessons", [user.uuid])
      .data({ universityId: uni, courseId: course, moduleId: module })
      .auth(user.auth)
      .then(() => {
        setModule(null);
        setModuleSearch(null);
        setLoading(false);
        loadTutor();
      });

  const saveModule = () => {
    if (module) {
      setLoading(true);
      if (module === "new") {
        post("universities/$1/courses/$2/modules", [uni, course])
          .auth(user.auth)
          .data({ name: moduleSearch })
          .then(({ id }) => {
            putModule(id);
          });
      } else {
        putModule(module);
      }
    }
  };

  const is500 = useMediaQuery({ query: "(min-width: 500px)" });

  return (
    <Wrapper
      sidebar={!isBecomingTutor && <ProfileSidebar current="subjects" />}
      wrapperStyle={
        isBecomingTutor && {
          backgroundImage: `url(${backgroundFaecher})`,
          backgroundSize: "2600px",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }
      }
    >
      <div style={{ width: "100%" }}>
        {isBecomingTutor && (
          <>
            <h1
              style={{
                fontSize: 34,
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              Tutor werden
            </h1>
            <div
              style={{
                marginLeft: "5px",
                color: "rgba(49, 49, 63, 0.4)",
                fontWeight: 600,
                fontSize: "15px",
                textAlign: "center",
                marginTop: 40,
                marginBottom: "5px",
              }}
            >
              Schritt 3 von 3
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "Center",
                marginBottom: 50,
                width: "100%",
                paddingLeft: is500 ? 50 : 0,
                paddingRight: is500 ? 50 : 0,
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: 100,
                  backgroundColor: "#387bf5",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "white",
                  textAlign: "center",
                  lineHeight: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                1
              </div>
              <div
                style={{
                  flexGrow: 2,
                  height: "3px",
                  backgroundColor: "#387bf5",
                }}
              ></div>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: 100,
                  backgroundColor: "#387bf5",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "white",
                  textAlign: "center",
                  lineHeight: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                2
              </div>
              <div
                style={{
                  flexGrow: 2,
                  height: "3px",
                  backgroundColor: "#387bf5",
                }}
              ></div>
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: 100,
                  backgroundColor: "#387bf5",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "white",
                  textAlign: "center",
                  lineHeight: "16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                3
              </div>
            </div>
            <h4
              style={{
                fontSize: "16px",
                marginBottom: 50,
                textAlign: "center",
              }}
            >
              Füge Fächer, in denen du Private-Tutoring anbieten möchtest hinzu.
            </h4>
          </>
        )}
        <div
          style={{
            width: "100%",
            marginTop: isBecomingTutor ? "" : 60,
          }}
        >
          <h2>Was ich anbiete</h2>
          <p style={{ marginTop: 30, marginBottom: 30 }}>
            Bitte füge jedes Fach einzeln hinzu. Hast du das{" "}
            <b>selbe Fach mit anderen Studiengängen zusammen?</b> Dann füge es
            bitte <b>für jeden Studiengang seperat</b> hinzu. Nur so wirst du in
            allen <b>relevanten Suchergebnissen</b> vorkommen.
          </p>

          <ul
            style={{
              marginTop: 20,
              marginBottom: 20,
              paddingLeft: 0,
            }}
          >
            {tutorFetching ? (
              <Loader active inline />
            ) : (
              myModules.map(({ module, id, university, course }) => (
                <li
                  style={{
                    margin: 3,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    icon
                    style={{
                      width: is500 ? "45px" : "55px",
                      backgroundColor: "white",
                      margin: 0,
                      marginRight: 20,
                    }}
                    onClick={() => deleteModule(id)}
                  >
                    <Mülleimer
                      style={{ width: "100%", height: "auto", fill: "#387bf5" }}
                    />
                  </Button>
                  {university} {">"} {course} {">"} {module}
                </li>
              ))
            )}
          </ul>
          <FieldRow
            minWidth={210}
            fields={[
              <Dropdown
                search={searchFunUnis}
                placeholder="Uni auswählen"
                setSearch={setUniSearch}
                setValue={setUni}
                value={uni}
                options={universities}
                loading={loadingUnis > 0}
                style={inputStyle}
              />,
              <Dropdown
                search={searchFunCourses}
                placeholder="Studiengang auswählen"
                setSearch={setCourseSearch}
                setValue={setCourse}
                value={course}
                options={courses}
                loading={loadingCourses > 0}
                style={inputStyle}
              />,
              <Dropdown
                search={searchFunModules}
                placeholder="Fach auswählen"
                setSearch={setModuleSearch}
                setValue={setModule}
                value={module}
                options={modules}
                loading={loadingModules > 0}
                style={inputStyle}
              />,
            ]}
          />
          <br />
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              className="button_double_shadow_blue"
              loading={loading}
              style={{
                ...buttonStyleDoubleShadow,
                margin: 0,
                padding: "20px 40px 20px 30px",
              }}
              onClick={saveModule}
            >
              <Haekchen
                style={{
                  marginTop: "-3px",
                  marginRight: 30,
                  fill: "#ffffff",
                  height: 18,
                  width: "auto",
                }}
              />
              Fach speichern
            </Button>
          </div>
        </div>
        {isBecomingTutor && (
          <Button
            disabled={myModules.length === 0}
            className="button_full_width"
            onClick={() => history.push("profile/" + user.uuid)}
            style={{
              ...buttonStyle,
              marginLeft: "auto",
              marginTop: 60,
              fontSize: "16px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            Profilerstellung abschließen
          </Button>
        )}

        <h4 style={{ marginTop: 30, fontSize: "20px", fontWeight: 600 }}>
          Fach nicht gefunden?
        </h4>
        <p style={{ fontSize: "16px" }}>
          Erstelle einfach deine eigenen Einträge. Nutze dafür bitte allgemein
          bekannte Namen und keine Abkürzungen.
        </p>
        <h4 style={{ marginTop: 40, fontSize: "20px", fontWeight: 600 }}>
          Uni oder Studiengang nicht gefunden?
        </h4>
        <p style={{ fontSize: "16px" }}>
          Dann schreib uns bitte eine Mail an{" "}
          <a href="mailto:hello@unitutors.de ?subject=Uni/Studiengang nicht gefunden&body=<BITTE NENNE HIER DEINE UNI/S UND STUDIENGÄNGE>">
            hello@unitutors.de
          </a>
          .
        </p>
      </div>
    </Wrapper>
  );
};
SelectSubjects.propTypes = {};

export default connect(
  ({ user, fetching: { tutor } }) => ({ user, tutorFetching: tutor }),
  { loadTutor, setTutorFetching }
)(SelectSubjects);
