import React from "react";
import { SubmitButton, Field, StepDisplay } from "components";
import { Modal } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const RequestFormModal = ({ loading, send, onClose, withSteps }) => {
  const isMedium = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <Modal open closeIcon onClose={onClose}>
      <Modal.Header>Anfrage senden</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ message: "" }}
          validationSchema={Yup.object({
            message: Yup.string().required(
              "Bitte gib eine Nachricht an den Tutor ein."
            ),
          })}
          onSubmit={send}
        >
          <Form>
            <div style={{ margin: "auto", maxWidth: 600, fontSize: "15px" }}>
              {withSteps && <StepDisplay count={2} current={2} />}
              <h6
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  marginTop: 10,
                }}
              >
                Schreibe etwas über dich und dein Anliegen
              </h6>

              <h6
                style={{
                  marginTop: 20,
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Was sollte alles in der Nachricht stehen?
              </h6>
              <p>
                Schildere dein Anliegen: Wann soll der Unterricht stattfinden,
                wann hast du Zeit, gibt es eine Deadline, handelt es sich um
                Gruppen- oder Einzelunterricht. Erkläre außerdem, in welchen
                Themen du Hilfe benötigst. Mit diesen Informationen kann sich
                der Tutor ein besseres Bild deines Anliegens verschaffen und so
                stellt ihr sicher, dass ihr zusammenpasst und am Ende zufrieden
                seid.
              </p>
              <p>
                Bitte <b>gib keine Kontaktdaten</b> in der Nachricht an den
                Tutor weiter. Nur dann gilt unsere <b>Geld-zurück-Garantie</b>.
              </p>

              <Field
                style={{
                  height: isMedium ? "120px" : "300px",
                }}
                as="textarea"
                placeholder={"Nachricht an den Tutor"}
                name="message"
              />
              {isMedium ? (
                <SubmitButton loading={loading}>
                  Anfrage abschließen
                </SubmitButton>
              ) : (
                <SubmitButton fullWidth loading={loading}>
                  Anfrage abschließen
                </SubmitButton>
              )}
            </div>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default RequestFormModal;
