import React from "react";
import { SearchHeader, Footer } from "components";
import { useMediaQuery } from "react-responsive";

const Wrapper = ({ children, sidebar, wrapperStyle, ...rest }) => {
  const isWide = useMediaQuery({ query: "(min-width: 900px)" });
  return (
    <>
      <SearchHeader {...rest} />
      <div
        style={{
          borderBottom: "1px solid rgb(227, 229, 233)",
          ...wrapperStyle,
        }}
      >
        <div
          style={{
            display: "flex",
            marginRight: "auto",
            marginLeft: "auto",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
            maxWidth: sidebar ? "1200px" : 900,
            width: "100%",
            paddingLeft: isWide ? 50 : 20,
            paddingRight: isWide ? 50 : 20,
          }}
        >
          {sidebar}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingLeft: isWide && sidebar ? 50 : 0,
              paddingTop: 120,
              paddingBottom: 200,
            }}
          >
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Wrapper;
