import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconAnfrage } from "assets/Icon_Gefunden_Werden.svg";
import { ReactComponent as IconAnnahme } from "assets/Icon_Tutoren_kontaktieren.svg";
import { ReactComponent as IconAblehnung } from "assets/Icon_Abgelehnt.svg";

const ContactReqNoti = ({
  concerning: { id, firstName, lastName, message },
}) => (
  <Link to="/mystudents">
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <IconAnfrage style={{ width: 60, height: "auto" }} />
      </div>
      <div
        style={{ color: "rgb(33, 37, 41)", marginLeft: 25, lineHeight: "22px" }}
      >
        Du hast eine Anfrage von{" "}
        <span
          style={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {firstName}
        </span>{" "}
        erhalten.
      </div>
    </div>
  </Link>
);

const DeclinedReqNoti = ({
  concerning: { id, firstName, lastName, message },
}) => (
  <Link to="/mytutors">
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <IconAblehnung style={{ width: 60, height: "auto" }} />
      </div>
      <div
        style={{ color: "rgb(33, 37, 41)", marginLeft: 25, lineHeight: "22px" }}
      >
        <span
          style={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {firstName}
        </span>{" "}
        hat deine Anfrage abgelehnt.
      </div>
    </div>
  </Link>
);

const AcceptedReqNoti = ({
  concerning: { id, firstName, lastName, message },
}) => (
  <Link to="/mytutors">
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <IconAnnahme style={{ width: 60, height: "auto" }} />
      </div>
      <div
        style={{ color: "rgb(33, 37, 41)", marginLeft: 25, lineHeight: "22px" }}
      >
        <span
          style={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {firstName}
        </span>{" "}
        hat deine Anfrage angenommen.
      </div>
    </div>
  </Link>
);

export { ContactReqNoti, DeclinedReqNoti, AcceptedReqNoti };
