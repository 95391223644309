const urls = {
  domain: "unitutors.de",
  supportmail: "hello@unitutors.de",
  instagram: "https://www.instagram.com/unitutors.de/",
  facebook: "https://www.facebook.com/unitutors.de",
  linkedin: "https://www.linkedin.com/company/unitutors-site",
  youtube: "https://www.youtube.com/channel/UCYl1cmHQl1_nql4J8-7sp7g",
  blog: "/more",
  contact: "/contact",
};

export default urls;
