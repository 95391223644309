import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SubmitButton, Footer, NoSearchHeader, Field } from "components";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { post } from "helpers/api";
import { login, logout } from "redux/user";
import { useHistory, useSearch } from "helpers/navigation";
import { styleWrong, inputStyle } from "helpers/theme";
import background from "assets/Background_Login_Screen.svg";
import log from "helpers/tracking";

const LoginScreen = ({ user, login, logout }) => {
  const [loading, setLoading] = useState(false);
  const [wrong, setWrong] = useState(false);
  const history = useHistory();

  const { force, redirect, ...searchParams } = useSearch();
  const onSubmit = ({ email, password }, actions) => {
    if (email && password) {
      setWrong(false);
      setLoading(true);
      post("login")
        .v(2)
        .data({ emailAddress: email, password })
        .auth(false.auth)
        .on(401, () => {
          setWrong(true);
          setLoading(false);
        })
        .on(404, () => {
          setWrong(true);
          setLoading(false);
        })
        .then((me) => {
          log(me.uuid, "login");
          setLoading(false);
          login({ email, ...me });
          if (redirect) {
            history.push(redirect, [], searchParams);
          } else {
            history.push("");
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (force) {
      logout();
    }
  }, [logout, force]);

  useEffect(() => {
    if (user.uuid && redirect && !force) {
      history.push(redirect, [], searchParams);
    } else if (user.uuid && !force) {
      history.push("");
    }
  }, [user, history, redirect, force, searchParams]);

  return (
    <>
      <NoSearchHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(
                "Unglültige Email-Addresse. Bitte überprüfe deine Eingabe."
              )
              .required("Bitte gib deine Email-Addresse ein."),
            password: Yup.string().required("Bitte gib dein Passwort ein."),
          })}
          onSubmit={onSubmit}
        >
          <Form
            style={{
              width: "min(500px, calc(100% - 20px))",
              padding: 40,
              paddingTop: 120,
            }}
          >
            <h2 style={{ marginBottom: 10 }}>Login</h2>
            <Field
              style={inputStyle}
              placeholder={"Email"}
              name="email"
              type="email"
            />
            <Field
              style={inputStyle}
              placeholder={"Passwort"}
              name="password"
              type="password"
            />
            <SubmitButton loading={loading}>Jetzt einloggen</SubmitButton>
            {wrong && (
              <div style={styleWrong}>
                Nutzername oder Passwort sind falsch. Bitte überprüfe deine
                Eingabe.
              </div>
            )}
            <div style={{ textAlign: "right", margin: 8 }}>
              <Link
                to="/resetPW"
                style={{ textDecoration: "underline", color: "#387bf5" }}
              >
                Passwort vergessen
              </Link>
            </div>
          </Form>
        </Formik>
      </div>
      <Footer />
    </>
  );
};
LoginScreen.propTypes = {
  user: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};

export default connect(({ user }) => ({ user }), { login, logout })(
  LoginScreen
);
