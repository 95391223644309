import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Footer, NoSearchHeader, Signup, FAQ } from "components";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Navbar } from "react-bootstrap";

import { useHistory, useScrollToHash } from "helpers/navigation";
import TutorenProfil from "assets/Icon_Profil_Erstellen.svg";
import StatusIcon from "assets/Icon_Status_Einstellen.svg";
import StudentenFinden from "assets/Icon_Gefunden_Werden.svg";
import logo from "assets/Logo.svg";
import tickBlue from "assets/tick_blue.svg";
import background1 from "assets/Background_Werde_Tutoren.svg";
import background2 from "assets/Background_Werde_Tutor_iPad.svg";
import background3 from "assets/Background_Werde_Tutor_FAQ_Section.svg";

import { useMediaQuery } from "react-responsive";
import { buttonStyle } from "helpers/theme";
import { tutorQs } from "config/faq";

const headerLinkStyle = {
  paddingTop: 8,
  color: "#31313f",
  cursor: "pointer",
};

const TextElement = ({ n }) => {
  const is1000 = useMediaQuery({ query: "(min-width: 900px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <div
      style={{
        alignSelf: isTablet ? (n === 2 ? "flex-end" : "flex-start") : "center",
        padding: 0,
        marginBottom: is1000 ? "" : "50px",
        width: is1000 ? "60%" : isTablet ? "80%" : "100%",
        display: "flex",
        flexWrap: isTablet ? "" : "wrap",
        flexDirection: n === 2 ? "row-reverse" : "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: n === 2 && isTablet ? "row-reverse" : "row",
          margin: isTablet ? "" : "auto",
        }}
      >
        <img
          alt=""
          src={[TutorenProfil, StatusIcon, StudentenFinden][n - 1]}
          style={{ height: "80px" }}
        />
        <p
          style={{
            fontSize: "125px",
            color: "#595963",
            fontWeight: "900",
            marginLeft: n === 2 && isTablet ? 0 : -20,
            marginRight: n === 2 && isTablet ? -20 : 0,
          }}
        >
          {n}
        </p>
      </div>
      <div
        style={{
          marginLeft: isTablet ? (n === 2 ? 0 : "25px") : 0,
          marginRight: isTablet ? (n === 2 ? "25px" : 0) : 0,
          textAlign: isTablet ? "" : "center",
          marginBottom: 50,
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            {
              ["Profil erstellen", "Status einstellen", "Anfragen erhalten"][
                n - 1
              ]
            }
          </span>
        </div>
        <span style={{ fontSize: "16px" }}>
          {
            [
              "Schreibe etwas über dich und gib deine Uni, deinen Studiengang sowie Stundenlohn an.",
              "Mit deinem Status teilst du den Suchenden mit, ob du momentan Zeit hast oder nicht.",
              "Ab sofort können dir Studenten eine Anfrage senden. Du entscheidest selbst, welche du annimmst und welche nicht.",
            ][n - 1]
          }
        </span>
      </div>
    </div>
  );
};
const TutorLanding = ({ user }) => {
  const history = useHistory();
  const isSmall = useMediaQuery({ query: "(min-width: 600px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isMedium = useMediaQuery({ query: "(min-width: 700px)" });
  const is900 = useMediaQuery({ query: "(min-width: 900px)" });
  const isWide = useMediaQuery({ query: "(min-width: 1250px)" });

  useEffect(() => {
    if (user.isTutor) {
      history.push("tutorprofile");
    }
  }, [user, history]);

  useScrollToHash();

  return (
    <>
      {user.uuid ? (
        <NoSearchHeader becomingTutor />
      ) : (
        <Navbar
          fixed="top"
          style={{
            zIndex: "1000",
            height: 90,
            backgroundColor: "white",
            padding: isMedium ? 30 : 10,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: 1380,
              width: "100%",
              justifyContent: "space-between",
              paddingRight: "160px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Link to="/">
              <img
                src={logo}
                alt={"Logo von Unitutors"}
                style={{ height: 20, width: "auto" }}
              />
            </Link>
          </div>
        </Navbar>
      )}
      <div
        style={{
          backgroundImage: is900
            ? `url(${background1})`
            : `url(${background2})`,
          backgroundPosition: is900 ? "center" : "",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: is900 ? "" : "column",
            justifyContent: "space-between",
            marginRight: "auto",
            marginLeft: "auto",
            maxWidth: user.uuid ? "1000px" : "1410px",
            padding: is900 ? "0 0px 100px 30px" : "0 0 0 0",
          }}
        >
          <div
            style={{
              width: is900 ? (user.uuid ? 700 : "50%") : "100%",
              paddingTop: isSmall ? 220 : 180,
              paddingRight: is900 ? 50 : isSmall ? 60 : 20,
              paddingLeft: is900 ? 30 : isSmall ? 60 : 20,
              paddingBottom: is900 ? "" : 40,
            }}
          >
            <div
              style={{
                marginBottom: "26px",
              }}
            >
              <span
                style={{
                  fontSize: 48,
                  lineHeight: "48px",
                }}
              >
                Flexibel arbeiten,
                <br />
                flexibel leben.
              </span>
            </div>
            <div style={{ maxWidth: "420px", marginTop: "-10px" }}>
              <span
                style={{
                  fontSize: 18,
                  lineHeight: "30px",
                  fontWeight: "300",
                }}
              >
                Entscheide selbst, wann und wie oft du arbeitest und wieviel du
                verdienst.
              </span>
            </div>
            <div></div>
            <div
              style={{
                display: "flex",
                marginTop: "45px",
                flexDirection: isWide ? "" : "column",
                flexWrap: "wrap",
                justifyContent: "left",
                fontSize: 18,
              }}
            >
              {[
                <span style={{ marginRight: "30px" }}>
                  {" "}
                  Maximale Flexibilität{" "}
                </span>,
                "Eigener Stundenlohn",
                "Keine Provision",
              ].map((text) => (
                <div
                  key={text}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    paddingTop: 20,
                    flexDirection: "row",
                  }}
                >
                  <img
                    src={tickBlue}
                    alt="Unitutors Tick"
                    style={{ height: 28 }}
                  />
                  <div
                    style={{
                      marginLeft: 30,
                    }}
                  >
                    {text}
                  </div>
                </div>
              ))}
            </div>
            {user.uuid ? (
              <div
                style={{
                  marginTop: "40px",
                  marginBottom: "50px",
                }}
              >
                <Link
                  to="/tutorprofile"
                  style={{ width: "100%", height: "64px" }}
                >
                  <Button
                    className="button_full_width"
                    style={{
                      ...buttonStyle,
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      fontSize: "16px",
                    }}
                  >
                    Jetzt Tutor werden
                  </Button>
                </Link>
              </div>
            ) : (
              !is900 && (
                <div
                  style={{
                    marginTop: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <Link
                    to="tutorwerden#signup"
                    style={{ width: "100%", height: "64px" }}
                  >
                    <Button
                      className="button_full_width"
                      style={{
                        ...buttonStyle,
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        fontSize: "16px",
                      }}
                    >
                      Jetzt Tutor werden
                    </Button>
                  </Link>
                </div>
              )
            )}
          </div>
          {!user.uuid && is900 && (
            <div
              style={{
                zIndex: is900 ? "2000" : "0",
                backgroundColor: is900 ? "" : "#dfeafc",
                borderRadius: is900 ? "" : "8px",
                display: "flex",
                flexDirection: is900 ? "column" : "column-reverse",
              }}
            >
              <div
                style={{
                  paddingTop: is900 ? "30px" : "",
                  marginTop: is900 ? "" : "-20px",
                  paddingBottom: "50px",
                  zIndex: "4",
                  textAlign: "center",
                  width: is900 ? "" : "100%",
                }}
              >
                <Link to="/login" style={headerLinkStyle}>
                  Du hast bereits einen Account?
                </Link>
              </div>
              <div
                style={{
                  backgroundColor: "#dfeafc",
                  paddingTop: is900 ? "25px" : "40px",
                  display: is900 ? "" : "flex",
                  justifyContent: "center",
                  borderRadius: "8px",
                }}
              >
                <Signup tutor />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginRight: "auto",
          marginLeft: "auto",
          maxWidth: "1410px",
          padding: 10,
        }}
      >
        <div
          style={{
            fontSize: "28px",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          So funktioniert
          <span style={{ fontWeight: "bold" }}> Unitutors:</span>
        </div>
      </div>
      <div
        style={{
          /*          backgroundImage: is900
            ? `url(${background1})`
            : `url(${background2})`,
          backgroundPosition: is900 ? "center" : "",
          backgroundRepeat: "no-repeat",*/
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "1000px",
            margin: "auto",
            padding: isSmall ? 50 : 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextElement n={1} />
          <TextElement n={2} />
          <TextElement n={3} />
        </div>{" "}
      </div>
      {user.uuid ? (
        <div
          style={{
            width: "100%",
            maxWidth: "1000px",
            paddingLeft: isSmall ? "50px" : 20,
            margin: "auto",
            marginTop: isTablet ? "" : "-30px",
            paddingRight: isSmall ? "50px" : 20,
            marginBottom: "180px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Link to="/tutorprofile" style={{ width: "100%" }}>
            <Button
              className="button_full_width"
              style={{
                ...buttonStyle,
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              Jetzt Nachhilfe-Tutor werden
            </Button>
          </Link>
        </div>
      ) : (
        <div
          style={{
            width: "auto",
            margin: "auto",
            backgroundColor: "#dfeafc",
            paddingTop: is900 ? "25px" : "60px",
            display: "flex",
            marginTop: "30px",
            alignItems: "center",
            flexDirection: "column",
          }}
          id="signup"
        >
          <h2 style={{ margin: 40, marginBottom: 20 }}>
            Jetzt Nachhilfe-Tutor werden
          </h2>
          <Signup tutor />
        </div>
      )}
      <h2
        id="faq"
        style={{
          textAlign: "center",
          margin: 30,
          marginTop: 100,
          marginBottom: 0,
        }}
      >
        Häufig gestellte Fragen
      </h2>
      <div
        style={{
          backgroundImage: `url(${background3})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "auto",
            marginBottom: 80,
            maxWidth: 1000,
            padding: is900 ? 60 : 20,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <FAQ content={tutorQs} />
        </div>
      </div>
      <Footer />
    </>
  );
};
TutorLanding.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(({ user }) => ({ user }), {})(TutorLanding);
